import React, { type ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { SecondaryButton } from '../../common/elements/CommonElements'
import { ReactComponent as PlusImage } from '../../assets/images/plus.svg'
import { ReactComponent as MinusImage } from '../../assets/images/minus.svg'
import { ReactComponent as DownloadImage } from '../../assets/images/download.svg'
import { ReactComponent as CircleQRImage } from '../../assets/images/qr/sampleQRCircle.svg'
import { ReactComponent as SquareQRImage } from '../../assets/images/qr/sampleQRSquare.svg'
import { ReactComponent as DotOptionClassy } from '../../assets/images/qr/dot-option-classy.svg'
import { ReactComponent as DotOptionClassyRounded } from '../../assets/images/qr/dot-option-classy-rounded.svg'
import { ReactComponent as DotOptionDots } from '../../assets/images/qr/dot-option-dots.svg'
import { ReactComponent as DotOptionExtraRounded } from '../../assets/images/qr/dot-option-extra-rounded.svg'
import { ReactComponent as DotOptionRounded } from '../../assets/images/qr/dot-option-rounded.svg'
import { ReactComponent as DotOptionSquare } from '../../assets/images/qr/dot-option-square.svg'
import CornerDotCircle from '../../assets/images/qr/corner-dot-circle.png'
import CornerDotSquare from '../../assets/images/qr/corner-dot-square.png'
import CornerSquareDot from '../../assets/images/qr/corner-square-dot.png'
import CornerSquareExtraRounded from '../../assets/images/qr/corner-square-extra-rounded.png'
import ErQRBig from '../../assets/images/qr/er-qr-big.png'
import Input from '../../common/elements/Input'
import {
  RadioButton,
  Slider,
  SwitchButton
} from '../../common/elements/InputControls'
import { ColorSelectButton, IconButton } from '../../common/elements/Buttons'
import { api } from '../../utils/AxiosConfig'
import Loader from '../../common/misc/loader/Loader'
import InputFieldRenderer from './common/QR.InputFieldRenderer'
import { type QROptions } from '../../common/types/QR.Types'
import Snackbar, { type SnackbarBaseProps } from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import usePageTitle from '../../hooks/UsePageTitle'

const RightSection = styled.div`
  width: 40%;
  min-width: 450px;
  display: inline-block;
  background: var(--er-primary);
  border-radius: 12px;
  margin-left: 20px;
  vertical-align: top;
`

const LeftSection = styled.div`
  width: calc(60% - 20px);
  display: inline-block;
  vertical-align: top;
`

const ImageDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Card = styled.div`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  margin-left: 0px;
  margin-bottom: 0px;
  border: 1px solid var(--er-primary);
`

const CardImage = styled.img`
  width: 150px;
  height: 150px;
  margin: 10px;
`

const DownloadDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
`

const DotTypes = [
  { label: 'Dots', id: 'dots', img: DotOptionDots },
  { label: 'Rounded', id: 'rounded', img: DotOptionRounded },
  { label: 'Classy', id: 'classy', img: DotOptionClassy },
  {
    label: 'Classy rounded',
    id: 'classy-rounded',
    img: DotOptionClassyRounded
  },
  { label: 'Square', id: 'square', img: DotOptionSquare },
  { label: 'Extra rounded', id: 'extra-rounded', img: DotOptionExtraRounded }
]

const CornerSquareTypes = [
  { label: 'Dot', id: 'dot', img: CornerDotSquare }, // CornerDotSquare == CornerSquareDot
  { label: 'Square', id: 'square', img: CornerSquareDot },
  { label: 'Extra rounded', id: 'extra-rounded', img: CornerSquareExtraRounded }
]

const CornerDotTypes = [
  { label: 'Dot', id: 'dot', img: CornerDotCircle },
  { label: 'Square', id: 'square', img: CornerDotSquare }
]

const Generate = (): JSX.Element => {
  usePageTitle('Generate QR')
  const [qrOptions, setQROptions] = useState<QROptions>({
    data: '',
    dataType: 'text',
    returnType: 'png',
    shape: 'square',
    width: 512,
    height: 512,
    image: '',
    margin: undefined,
    imageOptions: {
      crossOrigin: 'anonymous',
      imageSize: undefined,
      hideBackgroundDots: true,
      margin: undefined
    },
    dotsOptions: {
      type: 'dots',
      color: '',
      colorStr: '#000',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: '#fff'
          },
          {
            offset: 100,
            color: '#000'
          }
        ]
      }
    },
    cornersSquareOptions: {
      type: 'square',
      color: '',
      colorStr: '#000',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: '#fff'
          },
          {
            offset: 100,
            color: '#000'
          }
        ]
      }
    },
    cornersDotOptions: {
      type: 'square',
      color: '',
      colorStr: '#000',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: '#fff'
          },
          {
            offset: 100,
            color: '#000'
          }
        ]
      }
    },
    backgroundOptions: {
      round: undefined,
      color: '',
      colorStr: '#000',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: '#fff'
          },
          {
            offset: 100,
            color: '#000'
          }
        ]
      }
    },
    qrType: 'static' // Can't change this. Fixed for this page.
  })

  const [showImage, setShowImage] = useState(false)
  const [showDotOptions, setShowDotOptions] = useState(false)
  const [showCornersSquareOptions, setShowCornersSquareOptions] =
    useState(false)
  const [showCornersDotOptions, setShowCornersDotOptions] = useState(false)
  const [showBackgroundOptions, setShowBackgroundOptions] = useState(false)
  const [selectedColorPicker, setSelectedColorPicker] = useState('')
  const [generatedImage, setGeneratedImage] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isValidated, setIsValidated] = useState(false)

  const [errors, setErrors] = useState<Record<string, any>>({})

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const handleOptionChange = (key: string, value: any): void => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete errors[key] // Clear any error
    if (key === 'margin') {
      if (parseInt(value) < 0) {
        value = '0'
      }
    }
    setQROptions((prevData) => {
      const keys: string[] = key.split('.')
      const newData: any = { ...prevData }

      // Use reduce to traverse the nested structure and update the target property
      keys.reduce((acc, currentKey, index) => {
        if (index === keys.length - 1) {
          // If it's the last key, update the value
          acc[currentKey as keyof QROptions] = value
        } else {
          // If it's not the last key, create nested objects if necessary
          acc[currentKey] = acc[currentKey as keyof QROptions] || {}
        }

        return acc[currentKey]
      }, newData)

      return newData
    })
  }

  const handleColorButtonClick = (id: string): void => {
    if (selectedColorPicker === id) return setSelectedColorPicker('')
    setSelectedColorPicker(id)
  }

  const handleColorChange = (key: string, color: string): void => {
    const bracketSplitted = color.split(/\((.*)/)
    const colorType = bracketSplitted[0] // rgba or linear-gradient or radial-gradient
    const colorObject: Record<string, any> = {
      colorStr: color
    }
    const cleanValuesString = bracketSplitted[1].slice(0, -1)
    // const values = cleanValuesString.split(/,(?![^(]*\))/)
    const values = cleanValuesString.split(/, rgba|, RGBA/i)
    if (colorType === 'rgba' || colorType === 'RGBA') {
      colorObject.color = color
    } else {
      colorObject.gradient = {
        type: 'radial',
        rotation: 0
      }
      if (colorType === 'linear-gradient') {
        colorObject.gradient.type = 'linear'
        colorObject.gradient.rotation = values[0].replace('deg', '')
      }
      const gradientStops = []
      for (let i = 1; i < values.length; i++) {
        const valueSplit = values[i].trim().split(') ')
        const gradientStop = {
          offset: valueSplit[1]?.replace('%', ''),
          color: 'rgba' + valueSplit[0] + ')'
        }
        gradientStops.push(gradientStop)
      }
      colorObject.gradient.colorStops = gradientStops
    }
    handleOptionChange(key, colorObject)
  }

  const generate = async (svg?: boolean): Promise<void> => {
    setIsLoading(true)
    try {
      if (validate()) {
        const clearQrOptions = sanitizeData()
        if (svg) clearQrOptions.returnType = 'svg'
        const response = await api.post(
          '/api/qr/public/generate',
          clearQrOptions
        )
        if (svg) {
          downloadImage(response.data, 'svg')
        } else setGeneratedImage(response.data)
      }
    } catch (err) {
      console.error('err', err)
      setSnackbarData({ type: 'error', message: 'Error occurred while generating QR code' })
    } finally {
      setIsLoading(false)
    }
  }

  const validate = (): boolean => {
    setIsValidated(true)
    const allErrors: Record<string, any> = {}
    if (!qrOptions.data) {
      allErrors.data = 'Please enter some data'
    }
    if (Object.keys(allErrors).length) {
      setErrors(allErrors)
      return false
    }
    return true
  }

  const sanitizeData = (): Record<string, any> => {
    const rawQROptions = { ...qrOptions }

    for (const key in rawQROptions) {
      if (Object.prototype.hasOwnProperty.call(rawQROptions, key)) {
        if (
          rawQROptions[key as keyof QROptions] === null ||
          rawQROptions[key as keyof QROptions] === undefined ||
          rawQROptions[key as keyof QROptions] === ''
        ) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete rawQROptions[key as keyof QROptions]
        }
      }
    }

    if (!showImage) {
      delete rawQROptions.image
      delete rawQROptions.imageOptions
    }
    if (!showDotOptions) delete rawQROptions.dotsOptions
    else delete rawQROptions.dotsOptions?.colorStr
    if (!showCornersSquareOptions) delete rawQROptions.cornersSquareOptions
    else delete rawQROptions.cornersSquareOptions?.colorStr
    if (!showCornersDotOptions) delete rawQROptions.cornersDotOptions
    else delete rawQROptions.cornersDotOptions?.colorStr
    if (!showBackgroundOptions) delete rawQROptions.backgroundOptions
    else delete rawQROptions.backgroundOptions?.colorStr

    return rawQROptions
  }

  const downloadImage = (
    data: string,
    dataType: 'png' | 'svg' = 'png'
  ): void => {
    let blob
    if (dataType === 'png') {
      const decodedString = atob(data.split(',')[1])

      const arrayBuffer = new Uint8Array(decodedString.length)
      for (let i = 0; i < decodedString.length; i++) {
        arrayBuffer[i] = decodedString.charCodeAt(i)
      }
      blob = new Blob([arrayBuffer], {
        type: 'image/png'
      })
    } else {
      blob = new Blob([data], {
        type: 'image/svg+xml'
      })
    }

    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = 'exaroutes-qr-image.' + dataType
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    URL.revokeObjectURL(url)
  }

  return (
    <div style={{ marginBottom: '60px' }}>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <h1
        style={{ textAlign: 'center', marginTop: '30px', marginBottom: '60px' }}
      >
        Generate QR codes for FREE!
      </h1>
      <LeftSection>
        <InputFieldRenderer
          qrOptions={qrOptions}
          isValidated={isValidated}
          handleOptionChange={handleOptionChange}
          errors={errors}
        />
        <label style={{ margin: '12px 20px' }}>Shape:</label>
        <RadioButton
          name="shape"
          value="square"
          label="Square"
          checked={qrOptions.shape === 'square'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleOptionChange('shape', e.target.value)
          }}
          style={{ margin: '12px 20px' }}
        />
        <img
          src={SquareQRImage}
          style={{ height: '48px', width: '48px', verticalAlign: 'middle' }}
          alt="square-qr-image"
        />
        <RadioButton
          name="shape"
          value="circle"
          label="Circle"
          checked={qrOptions.shape === 'circle'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleOptionChange('shape', e.target.value)
          }}
          style={{ margin: '12px 20px' }}
        />
        <img
          src={CircleQRImage}
          style={{ height: '48px', width: '48px', verticalAlign: 'middle' }}
          alt="circle-qr-image"
        />
        <Input
          style={{
            width: 'calc(100% - 40px)',
            margin: '20px'
          }}
          type="number"
          label="Margin (optional)"
          value={qrOptions.margin}
          onChange={(e) => handleOptionChange('margin', e.target.value)}
        />
        <IconButton
          icon={showImage ? <MinusImage /> : <PlusImage />}
          onClick={() => setShowImage(!showImage)}
          style={{ margin: '20px' }}
        >
          {showImage ? 'Remove' : 'Add'} image
        </IconButton>
        {showImage ? (
          <div>
            <Input
              style={{
                width: 'calc(100% - 40px)',
                margin: '20px'
              }}
              label="Image URL/URI"
              value={qrOptions.image}
              onChange={(e) => handleOptionChange('image', e.target.value)}
            />
            <ImageDetailContainer>
              <Input
                style={{
                  width: '225px',
                  margin: '20px',
                  display: 'inline-block'
                }}
                type="number"
                label="Image size (optional)"
                value={qrOptions.imageOptions?.imageSize}
                onChange={(e) =>
                  handleOptionChange('imageOptions.imageSize', e.target.value)
                }
              />
              <SwitchButton
                style={{ marginTop: '30px' }}
                label="Hide background dots"
                checked={qrOptions.imageOptions?.hideBackgroundDots}
                onChange={(e) =>
                  handleOptionChange(
                    'imageOptions.hideBackgroundDots',
                    e.target.checked
                  )
                }
              />
              <Input
                style={{
                  width: '225px',
                  margin: '20px',
                  display: 'inline-block'
                }}
                type="number"
                label="Image margin (optional)"
                value={qrOptions.imageOptions?.margin}
                onChange={(e) =>
                  handleOptionChange('imageOptions.margin', e.target.value)
                }
              />
            </ImageDetailContainer>
          </div>
        ) : (
          <></>
        )}
        <br />
        <IconButton
          icon={showDotOptions ? <MinusImage /> : <PlusImage />}
          onClick={() => setShowDotOptions(!showDotOptions)}
          style={{ margin: '20px' }}
        >
          {showDotOptions ? 'Default' : 'Customize'} QR dots
        </IconButton>
        {showDotOptions ? (
          <div style={{ marginLeft: '20px' }}>
            <label>Dot type:</label>
            <br />
            {DotTypes.map((dt, idx) => {
              return (
                <Card
                  key={idx}
                  style={
                    qrOptions.dotsOptions?.type === dt.id
                      ? {
                          background: 'var(--er-primaryLighter)'
                        }
                      : undefined
                  }
                  onClick={() => handleOptionChange('dotsOptions.type', dt.id)}
                >
                  <CardImage src={dt.img} alt={dt.label} />
                  <br />
                  {dt.label}
                </Card>
              )
            })}
            <ColorSelectButton
              label="Dot color:"
              style={{ marginTop: '20px' }}
              selectedColor={qrOptions.dotsOptions?.colorStr}
              onClick={() => handleColorButtonClick('dotsOptions.color')}
              onColorChange={(color: string) => {
                handleColorChange('dotsOptions', color)
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <br />
        <IconButton
          icon={showCornersSquareOptions ? <MinusImage /> : <PlusImage />}
          onClick={() => setShowCornersSquareOptions(!showCornersSquareOptions)}
          style={{ margin: '20px' }}
        >
          {showCornersSquareOptions ? 'Default' : 'Customize'} QR corner squares
        </IconButton>
        {showCornersSquareOptions ? (
          <div style={{ marginLeft: '20px' }}>
            <label>Square type:</label>
            <br />
            {CornerSquareTypes.map((dt, idx) => {
              return (
                <Card
                  key={idx}
                  style={
                    qrOptions.cornersSquareOptions?.type === dt.id
                      ? {
                          background: 'var(--er-primaryLighter)'
                        }
                      : undefined
                  }
                  onClick={() =>
                    handleOptionChange('cornersSquareOptions.type', dt.id)
                  }
                >
                  <CardImage src={dt.img} alt={dt.label} />
                  <br />
                  {dt.label}
                </Card>
              )
            })}
            <ColorSelectButton
              label="Square color:"
              style={{ marginTop: '20px' }}
              selectedColor={qrOptions.cornersSquareOptions?.colorStr}
              onClick={() =>
                handleColorButtonClick('cornersSquareOptions.color')
              }
              onColorChange={(color: string) => {
                handleColorChange('cornersSquareOptions', color)
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <br />
        <IconButton
          icon={showCornersDotOptions ? <MinusImage /> : <PlusImage />}
          onClick={() => setShowCornersDotOptions(!showCornersDotOptions)}
          style={{ margin: '20px' }}
        >
          {showCornersDotOptions ? 'Default' : 'Customize'} QR corner dots
        </IconButton>
        {showCornersDotOptions ? (
          <div style={{ marginLeft: '20px' }}>
            <label>Corner dot type:</label>
            <br />
            {CornerDotTypes.map((dt, idx) => {
              return (
                <Card
                  key={idx}
                  style={
                    qrOptions.cornersDotOptions?.type === dt.id
                      ? {
                          background: 'var(--er-primaryLighter)'
                        }
                      : undefined
                  }
                  onClick={() =>
                    handleOptionChange('cornersDotOptions.type', dt.id)
                  }
                >
                  <CardImage src={dt.img} alt={dt.label} />
                  <br />
                  {dt.label}
                </Card>
              )
            })}
            <ColorSelectButton
              label="Corner dot color:"
              style={{ marginTop: '20px' }}
              selectedColor={qrOptions.cornersDotOptions?.colorStr}
              onClick={() => handleColorButtonClick('cornersDotOptions.color')}
              onColorChange={(color: string) => {
                handleColorChange('cornersDotOptions', color)
              }}
            />
          </div>
        ) : (
          <></>
        )}
        <br />
        <IconButton
          icon={showBackgroundOptions ? <MinusImage /> : <PlusImage />}
          onClick={() => setShowBackgroundOptions(!showBackgroundOptions)}
          style={{ margin: '20px' }}
        >
          {showBackgroundOptions ? 'Default' : 'Customize'} Background
        </IconButton>
        {showBackgroundOptions ? (
          <div style={{ marginLeft: '20px' }}>
            <Input
              style={{
                width: '225px',
                margin: '20px 0px',
                display: 'inline-block'
              }}
              type="number"
              label="Roundness (optional)"
              value={qrOptions.backgroundOptions?.round || 0}
              onChange={(e) =>
                handleOptionChange('backgroundOptions.round', e.target.value)
              }
            />
            <ColorSelectButton
              label="Background color:"
              style={{ marginTop: '20px' }}
              selectedColor={qrOptions.backgroundOptions?.colorStr}
              onClick={() => handleColorButtonClick('backgroundOptions.color')}
              onColorChange={(color: string) => {
                handleColorChange('backgroundOptions', color)
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </LeftSection>
      <RightSection>
        <div style={{ textAlign: 'center', padding: '60px 0' }}>
          <img
            style={{
              width: `calc((100% - 120px) * ((${
                qrOptions.width as number
              }) / 1000))`,
              background: 'var(--er-primary)'
            }}
            src={generatedImage || ErQRBig}
          />
        </div>
        <Slider
          psuedoContent="px"
          min={24}
          max={1024}
          value={qrOptions.width || 512}
          onChange={(e) => {
            handleOptionChange('width', e.target.value)
            handleOptionChange('height', e.target.value)
          }}
        />
        <SecondaryButton
          style={{
            margin: '30px',
            width: '146px',
            marginLeft: 'calc(50% - 73px)'
          }}
          onClick={async () => await generate()}
        >
          Generate QR
        </SecondaryButton>
        {generatedImage ? (
          <DownloadDiv>
            <IconButton
              buttonType="secondary"
              onClick={() => downloadImage(generatedImage)}
              icon={<DownloadImage />}
            >
              PNG
            </IconButton>
            <IconButton
              icon={<DownloadImage />}
              onClick={async () => await generate(true)}
            >
              SVG
            </IconButton>
          </DownloadDiv>
        ) : (
          <></>
        )}
      </RightSection>
    </div>
  )
}
export default Generate
