import './InputControls.css'
import React, { useEffect } from 'react'

let counter = 0

interface BaseInputButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
}

function Checkbox({
  label,
  style,
  className,
  ...props
}: BaseInputButtonProps): JSX.Element {
  const [inputId] = React.useState(() => {
    counter++
    return `inputId-${counter}`
  })
  return (
    <div style={{ display: 'inline-block', ...style }} className={className}>
      <input
        {...props}
        id={inputId}
        type="checkbox"
        className="customInputBtns"
      />
      <label htmlFor={inputId}>{label}</label>
    </div>
  )
}

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  name: string
  value: string | number
}

function RadioButton({
  label,
  name,
  value,
  style,
  ...props
}: RadioButtonProps): JSX.Element {
  const [inputId] = React.useState(() => {
    counter++
    return `inputId-${counter}`
  })
  return (
    <div style={{ ...style, display: 'inline-block' }}>
      <input
        {...props}
        id={inputId}
        type="radio"
        name={name}
        value={value}
        className="customInputBtns"
      />
      <label htmlFor={inputId}>{label}</label>
    </div>
  )
}

// ToDo - VLow - Radiogroup to show radio buttons for any field along with a heading
// function RadioGroup () {

// }

function SwitchButton({
  label,
  style,
  ...props
}: BaseInputButtonProps): JSX.Element {
  const [inputId] = React.useState(() => {
    counter++
    return `inputId-${counter}`
  })
  return (
    <div style={{ display: 'inline-block', ...style }}>
      <input
        {...props}
        id={inputId}
        type="checkbox"
        className="customInputBtns switch"
      />
      <label htmlFor={inputId}>{label}</label>
    </div>
  )
}

interface SliderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  psuedoContent?: string
  sliderColor?: 'primary' | 'red'
  valueClassName?: string
  min?: number
  max?: number
  value: number
}

let sliderCounter = 0

function Slider({
  psuedoContent,
  min,
  max,
  value,
  onChange,
  sliderColor,
  valueClassName,
  readOnly,
  style
}: SliderProps): JSX.Element {
  const [sliderCount] = React.useState(() => {
    sliderCounter++
    return sliderCounter
  })
  useEffect(() => {
    setLabelPosition()
    const rangeSlider = document.getElementById(`rs-range-line-${sliderCount}`)
    rangeSlider?.addEventListener('input', setLabelPosition, false)
    window.addEventListener('resize', setLabelPosition)
    return () => {
      rangeSlider?.removeEventListener('input', setLabelPosition, false)
      window.removeEventListener('resize', setLabelPosition)
    }
  }, [])

  const setLabelPosition = (): void => {
    const rangeBullet = document.getElementById(`rs-bullet-${sliderCount}`)
    const rangeSlider = document.getElementById(
      `rs-range-line-${sliderCount}`
    ) as HTMLInputElement
    const sliderWidth = rangeSlider.clientWidth
    if (!rangeBullet || !rangeSlider) return
    rangeBullet.innerHTML = rangeSlider.value
    const bulletPosition =
      (parseFloat(rangeSlider.value) - parseFloat(rangeSlider.min)) /
      (parseFloat(rangeSlider.max) - parseFloat(rangeSlider.min))
    rangeBullet.style.left = `${bulletPosition * (sliderWidth - 20) + 42}px`
    const psuedoSpan = document.getElementById(`psuedo-content-span-${sliderCount}`)
    if (psuedoSpan)
      psuedoSpan.style.left = `${bulletPosition * (sliderWidth - 20) + 42}px`
  }

  return (
    <div
      className="container"
      style={readOnly ? { pointerEvents: 'none', ...style } : style}
    >
      <div className="range-slider">
        <span
          id={`rs-bullet-${sliderCount}`}
          className={`rs-label ${
            sliderColor ? 'rs-label-' + sliderColor : ''
          } ${valueClassName || ''}`}
        >
          {value}
        </span>
        <span
          className={`psuedo-content ${
            sliderColor ? 'psuedo-content-' + sliderColor : ''
          }`}
        >
          <span id={`psuedo-content-span-${sliderCount}`}>{psuedoContent}</span>
        </span>
        <input
          id={`rs-range-line-${sliderCount}`}
          className={`rs-range ${sliderColor ? 'rs-range-' + sliderColor : ''}`}
          type="range"
          readOnly={readOnly}
          value={value}
          min={min || 0}
          max={max || 100}
          onChange={onChange}
        />
      </div>

      <div
        className={`box-minmax ${
          sliderColor ? 'box-minmax-' + sliderColor : ''
        }`}
      >
        <span>{min || 0}</span>
        <span>{max || 100}</span>
      </div>
    </div>
  )
}

export { Checkbox, RadioButton, SwitchButton, Slider }
