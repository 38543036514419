import * as React from 'react'
import './Homepage.css'
import { CLIENT_SITE_NAME } from '../../Config'
import HP1 from '../../assets/images/hp-1.png'
import HP2 from '../../assets/images/hp-2.png'
import styled from 'styled-components'
import {
  PrimaryButton,
  SecondaryButton
} from '../../common/elements/CommonElements'
import { useNavigate } from 'react-router-dom'

const ImageContainer = styled.div`
  display: inline-block;
  width: 700px;
  max-width: 40%;
  vertical-align: top;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  min-width: 300px;

  & img {
    width: 100%;
  }
`

const Homepage = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div>
      <section>
        <div className="sectionContainer">
          <section className="section">
            <h2>
              Unlocking Digital Potential with {CLIENT_SITE_NAME}: Pioneering QR
              Technology for Tomorrow!
            </h2>
            <p>
              Embark on a digital journey with {CLIENT_SITE_NAME}, where
              innovation harmonizes effortlessly with user-friendly design. Our
              flagship QR Code Generator is your gateway to a seamless world of
              dynamic QR solutions. From brand enhancement to customer
              engagement, experience the transformative power of QR technology
              at your fingertips.
            </p>
            <p>
              As trailblazers in the QR technology landscape, we don&apos;t stop
              at just one product. Our commitment to excellence drives us to
              continuously expand our suite of solutions. Stay tuned as we craft
              more game-changing products designed to propel your business into
              the future. Embrace the possibilities with {CLIENT_SITE_NAME} –
              where every line of code shapes a new dimension in digital
              brilliance.
            </p>
            <PrimaryButton
              style={{
                marginTop: '50px',
                marginLeft: 'calc(50% - 97.75px)',
                fontSize: '14px',
                padding: '16px 32px'
              }}
              onClick={() => navigate('/qr/generator')}
            >
              Get Started
            </PrimaryButton>
          </section>
          <ImageContainer>
            <img src={HP2} />
          </ImageContainer>
        </div>
        <div className="sectionContainer" style={{ background: 'bisque' }}>
          <ImageContainer>
            <img style={{ width: 'calc(100% - 80px)' }} src={HP1} />
          </ImageContainer>
          <section className="section">
            <h2>
              Elevate Your Engagement with {CLIENT_SITE_NAME}: Unleashing the
              Power of Smart, Secure, and Analytics-Driven QR Codes!
            </h2>
            <p>
              Discover a revolutionary QR code experience with{' '}
              {CLIENT_SITE_NAME}. Our advanced solution empowers you to create
              dynamic, encrypted, and password-protected QR codes, offering
              unparalleled versatility and data security. Elevate your campaigns
              with user analytics tracking, gaining valuable insights to refine
              and optimize for maximum impact. Experience innovation, security,
              and actionable insights — all in one smart, secure, and
              analytics-driven QR code generator.{' '}
              <a href="/qr/generator">Read more</a>
            </p>
            <SecondaryButton
              style={{
                marginTop: '200px',
                marginLeft: 'calc(50% - 97.75px)',
                fontSize: '14px',
                padding: '16px 32px'
              }}
              onClick={() => navigate('/qr/generate')}
            >
              Create QR code!
            </SecondaryButton>
          </section>
        </div>
      </section>
    </div>
  )
}

export default Homepage
