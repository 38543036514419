import React from 'react'
import Input from '../../../common/elements/Input'
import { Checkbox } from '../../../common/elements/InputControls'
import styled from 'styled-components'
import { type DynamicQR, type QROptions } from '../../../common/types/QR.Types'

const QRDataTypes = [
  { label: 'Text', id: 'text' },
  { label: 'URL', id: 'url' },
  { label: 'Email', id: 'email' },
  { label: 'Phone', id: 'phone' },
  { label: 'SMS', id: 'SMS' },
  { label: 'Location', id: 'geo' },
  { label: 'WiFi', id: 'wifi' },
  { label: 'VCard', id: 'vcard' },
  { label: 'VCalendar', id: 'vcalendar' },
  { label: 'MeCard', id: 'mecard' }
]

export const QRDataTypeList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const QRDataTypeListItem = styled.li`
  width: 100px;
  margin: 20px;
  border-radius: 5px;
  border: 1px solid var(--er-primary);
  padding: 8px 0;
  text-align: center;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
  background: white;

  &:hover {
    background: var(--er-primaryLighter);
  }
`

interface InputFieldRendererProps {
  qrOptions: QROptions | DynamicQR
  isValidated: boolean
  errors: Record<string, any>
  handleOptionChange: (k: string, v: any) => void
}

const InputFieldRenderer = ({
  qrOptions,
  isValidated,
  errors,
  handleOptionChange
}: InputFieldRendererProps): JSX.Element => {
  const elements = []
  elements.push(
    <QRDataTypeList>
      {QRDataTypes.map((dt) => (
        <QRDataTypeListItem
          key={dt.id}
          onClick={() => {
            handleOptionChange('data', '')
            handleOptionChange('dataType', dt.id)
          }}
          style={
            qrOptions.dataType === dt.id
              ? {
                  background: 'var(--er-primary)',
                  color: 'white'
                }
              : {}
          }
        >
          {dt.label}
        </QRDataTypeListItem>
      ))}
    </QRDataTypeList>
  )
  switch (qrOptions.dataType) {
    case 'email':
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 20px)',
            margin: '20px'
          }}
          label="Email"
          error={
            isValidated && errors.data?.email ? errors.data.email : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.email || ''}
          onChange={(e) => {
            handleOptionChange('data.email', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 20px)',
            margin: '20px'
          }}
          label="Subject"
          error={
            isValidated && errors.data?.subject
              ? errors.data.subject
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.subject || ''}
          onChange={(e) => {
            handleOptionChange('data.subject', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(100% - 40px)',
            margin: '20px'
          }}
          label="Data"
          error={
            isValidated && errors.data?.body ? errors.data.body : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.body || ''}
          onChange={(e) => {
            handleOptionChange('data.body', e.target.value)
          }}
        />
      )
      break
    case 'SMS':
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 20px)',
            margin: '20px'
          }}
          label="Phone"
          type="number"
          error={isValidated && errors.data?.tel ? errors.data.tel : undefined}
          value={(qrOptions.data as Record<string, any>)?.tel || ''}
          onChange={(e) => {
            handleOptionChange('data.tel', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(100% - 40px)',
            margin: '20px'
          }}
          label="Data"
          error={
            isValidated && errors.data?.body ? errors.data.body : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.body || ''}
          onChange={(e) => {
            handleOptionChange('data.body', e.target.value)
          }}
        />
      )
      break
    case 'wifi':
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Wi-Fi Type"
          error={
            isValidated && errors.data?.type ? errors.data.type : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.type || ''}
          onChange={(e) => {
            handleOptionChange('data.type', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Wi-Fi name"
          error={
            isValidated && errors.data?.name ? errors.data.name : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.name || ''}
          onChange={(e) => {
            handleOptionChange('data.name', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Wi-Fi password"
          type="password"
          error={
            isValidated && errors.data?.password
              ? errors.data.password
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.password || ''}
          onChange={(e) => {
            handleOptionChange('data.password', e.target.value)
          }}
        />
      )
      elements.push(
        <Checkbox
          style={{
            margin: '20px'
          }}
          checked={!!(qrOptions.data as Record<string, any>)?.hidden || false}
          onChange={(e) => {
            handleOptionChange('data.hidden', e.target.checked)
          }}
          label="Hidden"
        />
      )
      elements.push(<br />)
      break
    case 'vcard':
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Name"
          error={
            isValidated && errors.data?.name ? errors.data.name : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.name || ''}
          onChange={(e) => {
            handleOptionChange('data.name', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Phone"
          error={isValidated && errors.data?.tel ? errors.data.tel : undefined}
          value={(qrOptions.data as Record<string, any>)?.tel || ''}
          onChange={(e) => {
            handleOptionChange('data.tel', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Email"
          error={
            isValidated && errors.data?.email ? errors.data.email : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.email || ''}
          onChange={(e) => {
            handleOptionChange('data.email', e.target.value)
          }}
        />
      )
      elements.push(<br />)
      break

    case 'vcalendar':
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Start"
          error={
            isValidated && errors.data?.eventStart
              ? errors.data.eventStart
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.eventStart || ''}
          onChange={(e) => {
            handleOptionChange('data.eventStart', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="End"
          error={
            isValidated && errors.data?.eventEnd
              ? errors.data.eventEnd
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.eventEnd || ''}
          onChange={(e) => {
            handleOptionChange('data.eventEnd', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Summary"
          error={
            isValidated && errors.data?.eventSummary
              ? errors.data.eventSummary
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.eventSummary || ''}
          onChange={(e) => {
            handleOptionChange('data.eventSummary', e.target.value)
          }}
        />
      )
      elements.push(<br />)
      break
    case 'mecard':
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="First name"
          error={
            isValidated && errors.data?.fName ? errors.data.fName : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.fName || ''}
          onChange={(e) => {
            handleOptionChange('data.fName', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Last name"
          error={
            isValidated && errors.data?.lName ? errors.data.lName : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.lName || ''}
          onChange={(e) => {
            handleOptionChange('data.lName', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Email"
          error={isValidated && errors.data?.tel ? errors.data.tel : undefined}
          value={(qrOptions.data as Record<string, any>)?.tel || ''}
          onChange={(e) => {
            handleOptionChange('data.tel', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(100% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Address"
          error={
            isValidated && errors.data?.address
              ? errors.data.address
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.address || ''}
          onChange={(e) => {
            handleOptionChange('data.address', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Birth day"
          error={
            isValidated && errors.data?.birthDay
              ? errors.data.birthDay
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.birthDay || ''}
          onChange={(e) => {
            handleOptionChange('data.birthDay', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Nick name"
          error={
            isValidated && errors.data?.nickName
              ? errors.data.nickName
              : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.nickName || ''}
          onChange={(e) => {
            handleOptionChange('data.nickName', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="Note"
          error={
            isValidated && errors.data?.note ? errors.data.note : undefined
          }
          value={(qrOptions.data as Record<string, any>)?.note || ''}
          onChange={(e) => {
            handleOptionChange('data.note', e.target.value)
          }}
        />
      )
      elements.push(
        <Input
          style={{
            width: 'calc(50% - 40px)',
            margin: '20px',
            display: 'inline-block'
          }}
          label="URL"
          error={isValidated && errors.data?.url ? errors.data.url : undefined}
          value={(qrOptions.data as Record<string, any>)?.url || ''}
          onChange={(e) => {
            handleOptionChange('data.url', e.target.value)
          }}
        />
      )
      break
    default:
      elements.push(
        <Input
          style={{
            width: 'calc(100% - 40px)',
            margin: '20px'
          }}
          label="Data"
          error={isValidated && errors.data ? errors.data : undefined}
          value={qrOptions.data as string || ''}
          onChange={(e) => {
            handleOptionChange('data', e.target.value)
          }}
        />
      )
      break
  }

  return (
    <>
      {elements.map((element, idx) => (
        <div style={{ display: 'inline-block', width: '100%' }} key={idx}>
          {element}
        </div>
      ))}
    </>
  )
}

export default InputFieldRenderer
