import React from 'react'
import { CLIENT_SITE_NAME } from '../../Config'
import './AboutUs.css'
import overviewImg from '../../assets/images/au_overview.png'
import missionNVisionImg from '../../assets/images/au_mission_n_vision.png'
import coreValuesImg from '../../assets/images/au_core-values.png'
import UVPImg from '../../assets/images/au_UVP.png'
import communityImg from '../../assets/images/au_community.png'

const AboutUs = (): JSX.Element => {
  return (
    <div>
      <div className="auSectionContainer">
        <div className="sectionPara">
          <h4>Company Overview</h4>
          <p>
            With a visionary approach to innovation, {CLIENT_SITE_NAME} has
            established itself as a pioneering force in the microservices
            business landscape since its inception. With a passion for
            excellence and a commitment to technological advancement, we empower
            businesses of all sizes to streamline their operations and enhance
            their digital presence. Since our inception, we have remained
            dedicated to delivering cutting-edge solutions that drive tangible
            results for our clients, positioning us as a trusted partner in
            their journey towards success.
          </p>
        </div>
        <div className="sectionImageContainer">
          <img className="sectionImage" src={overviewImg} />
        </div>
      </div>
      <div className="auSectionContainerAlt">
        <div className="sectionImageContainer">
          <img className="sectionImage" src={missionNVisionImg} />
        </div>
        <div className="sectionPara">
          <h4>Mission and Vision</h4>
          <p>
            At {CLIENT_SITE_NAME}, our mission is to revolutionize the way
            businesses leverage technology to achieve their goals. We envision a
            future where every organization, regardless of size or industry, has
            access to scalable and customizable solutions that empower them to
            thrive in an ever-evolving digital landscape. Through innovation,
            integrity, and a relentless pursuit of excellence, we strive to
            empower our clients with the tools they need to succeed and grow in
            today&apos;s competitive marketplace.
          </p>
        </div>
      </div>
      <div className="auSectionContainer">
        <div className="sectionPara">
          <h4>Core Values</h4>
          <p>
            Integrity, innovation, and collaboration are the cornerstones of{' '}
            {CLIENT_SITE_NAME}&apos;s culture. We believe in upholding the
            highest ethical standards in all our endeavors, fostering a culture
            of trust and transparency with our clients and partners. Our
            commitment to innovation drives us to continually push boundaries
            and explore new possibilities, while our collaborative approach
            ensures that every member of our team plays a vital role in our
            shared success.
          </p>
        </div>
        <div className="sectionImageContainer">
          <img className="sectionImage" src={coreValuesImg} />
        </div>
      </div>
      <div className="auSectionContainerAlt">
        <div className="sectionImageContainer">
          <img className="sectionImage" src={UVPImg} />
        </div>
        <div className="sectionPara">
          <h4>Unique Value Proposition</h4>
          <p>
            At {CLIENT_SITE_NAME}, our unique value proposition lies in our
            unwavering dedication to delivering exceptional value to our
            clients. Through our innovative solutions and cutting-edge
            technology, we empower businesses to unlock new opportunities and
            achieve their goals with confidence. With a focus on quality,
            reliability, and customer satisfaction, we ensure that every
            interaction with {CLIENT_SITE_NAME} is a positive and rewarding
            experience.
          </p>
        </div>
      </div>
      <div className="auSectionContainer">
        <div className="sectionPara">
          <h4>Community Involvement</h4>
          <p>
            At {CLIENT_SITE_NAME}, we believe in giving back to the communities
            we serve. Through philanthropic initiatives, volunteer efforts, and
            community partnerships, we strive to make a positive impact beyond
            the boardroom. Our commitment to corporate social responsibility
            reflects our belief in the importance of creating a better world for
            future generations.
          </p>
        </div>
        <div className="sectionImageContainer">
          <img className="sectionImage" src={communityImg} />
        </div>
      </div>
    </div>
  )
}

export default AboutUs
