import React, { useState } from 'react'
import TabManager from '../../common/tabManager/TabManager'
import {
  Paragraph,
  SecondaryButton
} from '../../common/elements/CommonElements'
import QRReadMe from './QR.ReadMe'
import { useLocation, useNavigate } from 'react-router-dom'
import QRDocumentaion from './QR.Documentation'
import QRAPIReference from './QR.APIReference'
import './QR.css'
import SubscriptionBox from '../../common/subscriptionBox/SubscriptionBox'
import PayPalSubscription from '../../common/payment/PaypalButton'

export interface TabData {
  value: string
  label: string
  child: any
}

type QRTabs = 'readme' | 'docs' | 'apiRefernce'

const Generator = (): JSX.Element => {
  const location = useLocation()

  const hash = location.hash

  const [selectedTab, setSelectedTab] = useState<QRTabs>(
    (['#readMe', '#docs', '#apiReference'].includes(hash)
      ? hash.substring(1)
      : 'readMe') as QRTabs
  )

  const navigate = useNavigate()
  const tabData: TabData[] = [
    { value: 'readMe', label: 'Overview', child: <QRReadMe /> },
    {
      value: 'docs',
      label: 'Documentation',
      child: <QRDocumentaion />
    },
    {
      value: 'apiReference',
      label: 'API Reference',
      child: <QRAPIReference />
    }
  ]
  return (
    <div>
      <div className="qr-generator-block">
        <div>
          <h1 className="qr-generator-heading">QR generator</h1>
          {/* <PrimaryButton
      > */}
          {/* <CTAButton
        style={{
          float: 'right',
          margin: '30px',
          height: '48px',
          fontSize: '18px',
          fontWeight: '600'
        }}
        onClick={() => navigate('../plans')}>
        Subscribe
        </CTAButton> */}
          {/* </PrimaryButton> */}
          <Paragraph>
            Introducing our versatile QR code generator, where customization
            meets innovation. With an array of settings tailored to your needs,
            you can effortlessly create QR codes that resonate with your brand
            identity and captivate your audience. Whether you&apos;re aiming to
            enhance engagement, bolster security, or streamline operations, our
            generator offers a comprehensive suite of features to elevate your
            QR code experience.
            <br />
            <br />
            Dive into our QR code settings to fine-tune every detail, from the
            type of QR code to the margin spacing and even the addition of
            branded images. Elevate the visual appeal of your QR codes with
            cosmetic settings, allowing you to choose between square or circular
            shapes, define dot styles and colors, and customize corner types to
            achieve a polished finish. Explore advanced functionalities such as
            dynamic QR codes for real-time updates and bulk processing
            capabilities for efficient large-scale implementations. With our
            intuitive interface and robust features, unlocking the potential of
            QR codes has never been easier.
          </Paragraph>
        </div>
        <SubscriptionBox productName="qr" />
      </div>
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        <SecondaryButton onClick={() => navigate('/qr/generate')}>
          Generate QR code now!
        </SecondaryButton>
      </div>
      <PayPalSubscription />
      <br />
      <TabManager
        style={{ marginTop: '50px' }}
        selected={selectedTab}
        tabData={tabData}
        onTabChange={(e) => {
          navigate(`#${e as string}`)
          setSelectedTab(e)
        }}
      />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Generator
