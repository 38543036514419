import React, { useState } from 'react'
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg'
import styled from 'styled-components'

const Container = styled.div`
  padding: 10px 18px;
  border: 1px solid lightgray;
  border-radius: 8px;
  width: 250px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:focus {
    border-color: var(--er-primary);
  }
`

const Input = styled.input`
  width: calc(100% - 40px);
  margin-left: 9px;
  border: none;
  font-size: 16px;

  &:focus-visible {
    outline: none;
  }
`

let counter = 0

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  width?: string
}

const SearchInput = ({
  style,
  className,
  ...props
}: SearchInputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false)
  const [inputId] = useState(() => {
    counter++
    return `search-input-${counter}`
  }) // To have unique html id

  const focusOnInput = (): void => {
    const inputElement = document.getElementById(inputId)
    inputElement?.focus()
  }

  return (
    <Container
      style={isFocused ? { borderColor: 'var(--er-primary)', ...style } : style}
      className={className}
      onClick={focusOnInput}
    >
      <SearchIcon
        style={{ verticalAlign: 'middle', color: isFocused ? 'black' : 'gray' }}
      />
      <Input
        {...props}
        id={inputId}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </Container>
  )
}

export default SearchInput
