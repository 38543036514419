import React from 'react'
import { Paragraph } from '../../common/elements/CommonElements'
import './QR.ReadMe.css'

const QRReadMe = (): JSX.Element => {
  return (
    <div className="qr-read-me">
      <h1>Introduction</h1>
      <p>
        Our QR code generator combines enhanced branding, streamlined
        operations, and robust security features to provide an essential tool
        for individuals and businesses. With customizable codes, advanced
        engagement tracking, and convenient bulk processing, it empowers users
        to effectively share information and measure campaign success. Explore
        the sections below to learn more about our generator, pricing options,
        installation process, and how to share your feedback.
      </p>
      <h2>What makes our generator essential ?</h2>
      <Paragraph>
        There are several compelling reasons why someone might choose to use our
        QR code generator:
      </Paragraph>
      <ul className="uList">
        <li>
          <span>
            <span className="headingSpan">Enhanced Branding:</span> By
            customizing QR codes with logos, colors, and shapes, users can
            create visually appealing codes that reflect their brand identity.
            This helps to reinforce brand recognition and makes QR codes more
            engaging for consumers.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">Convenience and Efficiency:</span> QR
            codes provide a quick and convenient way to share information,
            whether it&apos;s a website URL, contact details, or event
            information. With our generator, users can easily create QR codes
            for various purposes and distribute them across different marketing
            channels.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">Engagement Tracking:</span> For
            businesses and marketers, QR codes offer a way to track engagement
            and measure the effectiveness of marketing campaigns. Our generator
            includes advanced features such as source arrays, secure QR codes,
            and dynamic updates, allowing users to track engagement metrics and
            make data-driven decisions.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">Security and Control:</span> With
            features like secure QR codes and password protection, users can
            control access to sensitive information and ensure data security.
            This is especially important for businesses handling confidential
            data or conducting transactions through QR codes.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">Streamlined Operations:</span> For
            large-scale implementations, our generator offers bulk processing
            capabilities, allowing users to create up to 50,000 QR codes in a
            single request. This streamlines operations and saves time for
            businesses managing QR code campaigns across multiple locations or
            products.
          </span>
        </li>
      </ul>
      <h2>Pricing</h2>
      <Paragraph>
        Curious about our pricing options? Explore our range of subscription
        plans tailored to your QR code generation needs. Whether you&apos;re an
        individual user, small business, or enterprise, we have plans designed
        to accommodate various requirements and budgets. Visit our{' '}
        <a href="/qr/plans">plans</a> page now to discover the perfect fit for
        you and start creating custom QR codes today!
      </Paragraph>
      <h2>Installation</h2>
      <Paragraph>
        To start using the QR code generator, users need to follow these steps:
      </Paragraph>
      <ul className="uList">
        <li>
          <span>
            <span className="headingSpan">Subscribe to a Plan:</span> Before
            accessing the QR code generator, users must subscribe to their
            desired plan. Choose from our range of subscription options to
            unlock the full potential of the generator.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">Generate API Key:</span> After
            subscribing, users need to generate an API key to authenticate their
            requests. Navigate to the settings or API section of your account
            dashboard to generate a unique API key.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">
              Enable Two-Factor Authentication (2FA):
            </span>{' '}
            As a security measure, users are required to enable Two-Factor
            Authentication (2FA) for their accounts. This adds an extra layer of
            protection to ensure the security of your data and QR code
            generation process.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">Update Domain Name:</span> Before
            generating QR codes, users must update their domain name in the{' '}
            <a href="/profile#domains">profile</a> section of their account
            settings. This step ensures that QR codes generated through the API
            are associated with the correct domain.
          </span>
        </li>
        <li>
          <span>
            <span className="headingSpan">Send API Request:</span> Once the API
            key is generated and 2FA is enabled with the domain name updated,
            users can start sending requests to create QR codes. Use the API
            documentation provided to construct the request with the necessary
            parameters, including the data for the QR code and any
            customizations desired.
          </span>
        </li>
      </ul>
      <h2>Your feedback</h2>
      <Paragraph>
        We value your input! If you have any suggestions, encounter issues, or
        would like to share your experience using our QR code generator, please
        don&apos;t hesitate to reach out. Your feedback helps us improve our
        tool and better serve your needs. You can contact us through the
        provided <a href="/support">support</a> channels or submit{' '}
        <a href="/feedback">feedback</a> form directly. We appreciate your
        contribution to making our generator even better.
      </Paragraph>
    </div>
  )
}

export default QRReadMe
