import React from 'react'
import { CLIENT_SITE_URL } from '../../Config'
import { POLICY_EMAIL } from '../../Constants'

const PrivacyPolicy = (): JSX.Element => {
  return (
    <div>
      <h1>
        <strong>PRIVACY POLICY</strong>
      </h1>
      <p>
        <strong>Last updated: September 14, 2024</strong>
      </p>
      <p>
        This privacy notice for ExaRoutes (&apos;<strong>we</strong>&apos;,
        &apos;
        <strong>us</strong>&apos;, or &apos;<strong>our</strong>&apos;),
        describes how and why we might collect, store, use, and/or share (&apos;
        <strong>process</strong>&apos;) your information when you use our
        services (&apos;<strong>Services</strong>
        &apos;), such as when you:
      </p>
      <ul>
        <li>
          Visit our website at{' '}
          <a href={CLIENT_SITE_URL} target="_blank" rel="noreferrer">
            {CLIENT_SITE_URL}
          </a>
          , or any website of ours that links to this privacy notice
        </li>
      </ul>
      <div>
        <ul>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
          notice will help you understand your privacy rights and choices. If
          you do not agree with our policies and practices, please do not use
          our Services. If you still have any questions or concerns, please
          contact us at {POLICY_EMAIL}.
        </p>
        <h3>
          <strong>SUMMARY OF KEY POINTS</strong>
        </h3>
        <p>
          <strong>
            <em>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our&nbsp;
            </em>
          </strong>
          <a href="#toc">
            <strong>
              <em>table of contents</em>
            </strong>
          </a>
          <strong>
            <em>&nbsp;below to find the section you are looking for.</em>
          </strong>
        </p>
        <p>
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about&nbsp;
          <a href="#personalinfo">personal information you disclose to us</a>.
        </p>
        <p>
          <strong>Do we process any sensitive personal information?</strong> We
          do not process sensitive personal information.
        </p>
        <p>
          <strong>Do we receive any information from third parties?</strong> We
          do not receive any information from third parties.
        </p>
        <p>
          <strong>How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about&nbsp;
          <a href="#infouse">how we process your information</a>.
        </p>
        <p>
          <strong>
            In what situations and with which parties do we share personal
            information?
          </strong>{' '}
          We may share information in specific situations and with specific
          third parties. Learn more about&nbsp;
          <a href="#whoshare">
            when and with whom we share your personal information
          </a>
          .
        </p>
        <p>
          <strong>How do we keep your information safe?</strong> We have
          organisational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission
          over the internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Learn more about&nbsp;
          <a href="#infosafe">how we keep your information safe</a>.
        </p>
        <p>
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more
          about&nbsp;
          <a href="#privacyrights">your privacy rights</a>.
        </p>
        <p>
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by emailing us at {POLICY_EMAIL} or contacting
          us directly. We will consider and act upon any request in accordance
          with applicable data protection laws.
        </p>
        <p>
          Want to learn more about what we do with any information we
          collect?&nbsp;
          <a href="#toc">Review the privacy notice in full</a>.
        </p>
        <h3 id="toc">
          <strong>TABLE OF CONTENTS</strong>
        </h3>
        <div>
          <a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
        </div>
        <div>
          <a href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
        </div>
        <div>
          <a href="#legalbases">
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </a>
        </div>
        <div>
          <a href="#whoshare">
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </div>
        <div>
          <a href="#cookies">
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </div>
        <div>
          <a href="#sociallogins">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        </div>
        <div>
          <a href="#inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </div>
        <div>
          <a href="#infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </div>
        <div>
          <a href="#infominors">9. DO WE COLLECT INFORMATION FROM MINORS?</a>
        </div>
        <div>
          <a href="#privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </div>
        <div>
          <a href="#DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </div>
        <div>
          <a href="#uslaws">
            12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </div>
        <div>
          <a href="#otherlaws">
            13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </div>
        <div>
          <a href="#policyupdates">14. DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </div>
        <div>
          <a href="#contact">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </div>
        <div>
          <a href="#request">
            16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </div>
        <h3 id="infocollect">
          <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
        </h3>
        <p>
          <strong>Personal information you disclose to us</strong>
        </p>
        <div>
          <p>
            <strong>
              <em>In Short: </em>
            </strong>
            <strong></strong>
            <em>We collect personal information that you provide to us.</em>
          </p>
        </div>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services,&nbsp;express an interest in
          obtaining information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong> The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul>
          <li>names</li>
        </ul>
        <ul>
          <li>email addresses</li>
        </ul>
        <ul>
          <li>phone numbers</li>
        </ul>
        <ul>
          <li>usernames</li>
        </ul>
        <ul>
          <li>passwords</li>
        </ul>
        <p>
          <strong>Sensitive Information.</strong> We do not process sensitive
          information.
        </p>
        <p>
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you make purchases, such as your payment
          instrument number, and the security code associated with your payment
          instrument. All payment data is stored by Stripe. You may find their
          privacy notice link(s) here:{' '}
          <a
            href="https://stripe.com/in/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://stripe.com/in/privacy
          </a>
          .
        </p>
        <p>
          <strong>Social Media Login Data.&nbsp;</strong>We may provide you with
          the option to register with us using your existing social media
          account details, like your Facebook, Twitter, or other social media
          account. If you choose to register in this way, we will collect the
          information described in the section called &apos;
          <a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>&apos;
          below.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h3 id="infouse">
          <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
        </h3>
        <div>
          <p>
            <strong>
              <em>In Short:&nbsp;</em>
            </strong>
            <em>
              We process your information to provide, improve, and administer
              our Services, communicate with you, for security and fraud
              prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </em>
          </p>
        </div>
        <p>
          <strong>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </strong>
        </p>
        <ul>
          <li>
            <p>
              <strong>
                To facilitate account creation and authentication and otherwise
                manage user accounts.&nbsp;
              </strong>
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
            </p>
          </li>
          <li>
            <p>
              <strong>
                To respond to user inquiries/offer support to users.&nbsp;
              </strong>
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </p>
          </li>
          <li>
            <p>
              <strong>To send administrative information to you.&nbsp;</strong>
              We may process your information to send you details about our
              products and services, changes to our terms and policies, and
              other similar information.
            </p>
          </li>
          <li>
            <p>
              <strong>To fulfil and manage your orders.</strong> We may process
              your information to fulfil and manage your orders, payments,
              returns, and exchanges made through the Services.
            </p>
          </li>
          <li>
            <p>
              <strong>
                To send you marketing and promotional communications.&nbsp;
              </strong>
              We may process the personal information you send to us for our
              marketing purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
              For more information, see &apos;
              <a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
              &apos; below.
            </p>
          </li>
          <li>
            <p>
              <strong>To protect our Services.</strong> We may process your
              information as part of our efforts to keep our Services safe and
              secure, including fraud monitoring and prevention.
            </p>
          </li>
          <li>
            <p>
              <strong>
                To save or protect an individual&apos;s vital interest.
              </strong>{' '}
              We may process your information when necessary to save or protect
              an individual&rsquo;s vital interest, such as to prevent harm.
            </p>
          </li>
        </ul>
        <h3 id="legalbases">
          <strong>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
          </strong>
        </h3>
        <p>
          <em>
            <strong>In Short:&nbsp;</strong>We only process your personal
            information when we believe it is necessary and we have a valid
            legal reason (i.e. legal basis) to do so under applicable law, like
            with your consent, to comply with laws, to provide you with services
            to enter into or fulfil our contractual obligations, to protect your
            rights, or to fulfil our legitimate business interests.
          </em>
        </p>
        <p>
          <em>
            <strong>
              <u>
                If you are located in the EU or UK, this section applies to you.
              </u>
            </strong>
          </em>
        </p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul>
          <li>
            <strong>Consent.&nbsp;</strong>We may process your information if
            you have given us permission (i.e. consent) to use your personal
            information for a specific purpose. You can withdraw your consent at
            any time. Learn more about&nbsp;
            <a href="#withdrawconsent">withdrawing your consent</a>.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Performance of a Contract.</strong> We may process your
            personal information when we believe it is necessary to fulfil our
            contractual obligations to you, including providing our Services or
            at your request prior to entering into a contract with you.
          </li>
        </ul>
        <ul>
          <li>
            <strong>Legitimate Interests.</strong> We may process your
            information when we believe it is reasonably necessary to achieve
            our legitimate business interests and those interests do not
            outweigh your interests and fundamental rights and freedoms. For
            example, we may process your personal information for some of the
            purposes described in order to:
          </li>
        </ul>
        <ul>
          <li>
            Send users information about special offers and discounts on our
            products and services
          </li>
        </ul>
        <ul>
          <li>Diagnose problems and/or prevent fraudulent activities</li>
        </ul>
        <ul>
          <li>
            <strong>Legal Obligations.</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
            <br />
          </li>
        </ul>
        <ul>
          <li>
            <strong>Vital Interests.</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </li>
        </ul>
        <p>
          <strong>
            <u>
              <em>
                If you are located in Canada, this section applies to you.
              </em>
            </u>
          </strong>
        </p>
        <p>
          We may process your information if you have given us specific
          permission (i.e. express consent) to use your personal information for
          a specific purpose, or in situations where your permission can be
          inferred (i.e. implied consent). You can&nbsp;
          <a href="#withdrawconsent">withdraw your consent</a>
          &nbsp;at any time.
        </p>
        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <ul>
          <li>
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </li>
        </ul>
        <ul>
          <li>For investigations and fraud detection and prevention</li>
        </ul>
        <ul>
          <li>For business transactions provided certain conditions are met</li>
        </ul>
        <ul>
          <li>
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim
          </li>
        </ul>
        <ul>
          <li>
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </li>
        </ul>
        <ul>
          <li>
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse
          </li>
        </ul>
        <ul>
          <li>
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </li>
        </ul>
        <ul>
          <li>
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records
          </li>
        </ul>
        <ul>
          <li>
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </li>
        </ul>
        <ul>
          <li>
            If the collection is solely for journalistic, artistic, or literary
            purposes
          </li>
        </ul>
        <ul>
          <li>
            If the information is publicly available and is specified by the
            regulations
          </li>
        </ul>
        <h3 id="whoshare">
          <strong>
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </strong>
        </h3>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;We may share information in specific situations described in
            this section and/or with the following third parties.
          </em>
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
        </ul>
        <h3 id="cookies">
          <strong>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong>
        </h3>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;We may use cookies and other tracking technologies to collect
            and store your information.
          </em>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice: www.exaroutes/com/cookies.
        </p>
        <h3 id="sociallogins">
          <strong>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</strong>
        </h3>
        <p>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            If you choose to register or log in to our Services using a social
            media account, we may have access to certain information about you.
          </em>
        </p>
        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
        <h3 id="inforetain">
          <strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
        </h3>
        <p>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            We keep your information for as long as necessary to fulfil the
            purposes outlined in this privacy notice unless otherwise required
            by law.
          </em>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than
          three (3) months past the termination of the user&apos;s account.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h3 id="infosafe">
          <strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
        </h3>
        <p>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            We aim to protect your personal information through a system of
            organisational and technical security measures.
          </em>
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <h3 id="infominors">
          <strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong>
        </h3>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;We do not knowingly collect data from or market to children
            under 18 years of age.
          </em>
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent&rsquo;s use of the Services. If we
          learn that personal information from users less than 18 years of age
          has been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at {POLICY_EMAIL}.
        </p>
        <h3 id="privacyrights">
          <strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
        </h3>
        <p>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            &nbsp;In some regions, such as the European Economic Area (EEA),
            United Kingdom (UK), Switzerland, and Canada, you have rights that
            allow you greater access to and control over your personal
            information.&nbsp;You may review, change, or terminate your account
            at any time.
          </em>
        </p>
        <p>
          In some regions (like the EEA, UK, Switzerland, and Canada), you have
          certain rights under applicable data protection laws. These may
          include the right (i) to request access and obtain a copy of your
          personal information, (ii) to request rectification or erasure; (iii)
          to restrict the processing of your personal information; (iv) if
          applicable, to data portability; and (v) not to be subject to
          automated decision-making. In certain circumstances, you may also have
          the right to object to the processing of your personal information.
          You can make such a request by contacting us by using the contact
          details provided in the section &apos;
          <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          &apos; below.
        </p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your{' '}
          <a
            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Member State data protection authority
          </a>{' '}
          or&nbsp;
          <a
            href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UK data protection authority
          </a>
          .
        </p>
        <p>
          If you are located in Switzerland, you may contact the{' '}
          <a
            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Federal Data Protection and Information Commissioner
          </a>
          .
        </p>
        <p>
          <strong>
            <u>Withdrawing your consent:</u>
          </strong>{' '}
          If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on
          the applicable law, you have the right to withdraw your consent at any
          time. You can withdraw your consent at any time by contacting us by
          using the contact details provided in the section &apos;
          <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          &apos; below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <p>
          <strong>
            <u>Opting out of marketing and promotional communications:</u>
          </strong>
          <strong></strong>
          You can unsubscribe from our marketing and promotional communications
          at any time by clicking on the unsubscribe link in the emails that we
          send, or by contacting us using the details provided in the section
          &apos;
          <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          &apos; below. You will then be removed from the marketing lists.
          However, we may still communicate with you &mdash; for example, to
          send you service-related messages that are necessary for the
          administration and use of your account, to respond to service
          requests, or for other non-marketing purposes.
        </p>
        <p>
          <strong>Account Information</strong>
        </p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <ul>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          <strong>
            <u>Cookies and similar technologies:</u>
          </strong>{' '}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. For
          further information, please see our Cookie Notice:
          www.exaroutes/com/cookies.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at {POLICY_EMAIL}.
        </p>
        <h3 id="DNT">
          <strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
        </h3>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (&apos;DNT&apos;) feature or
          setting you can activate to signal your privacy preference not to have
          data about your online browsing activities monitored and collected. At
          this stage no uniform technology standard for recognising and
          implementing DNT signals has been finalised. As such, we do not
          currently respond to DNT browser signals or any other mechanism that
          automatically communicates your choice not to be tracked online. If a
          standard for online tracking is adopted that we must follow in the
          future, we will inform you about that practice in a revised version of
          this privacy notice.
        </p>
        <h3 id="uslaws">
          <strong>
            12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </strong>
        </h3>
        <p>
          <strong>
            <em>In Short:&nbsp;</em>
          </strong>
          <em>
            If you are a resident of California, Colorado, Connecticut, Utah or
            Virginia, you are granted specific rights regarding access to your
            personal information.
          </em>
        </p>
        <h4>
          <strong>
            What categories of personal information do we collect?
          </strong>
        </h4>
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Category</strong>
              </td>
              <td>
                <strong>Examples</strong>
              </td>
              <td>
                <strong>Collected</strong>
              </td>
            </tr>
            <tr>
              <td>
                <div>A. Identifiers</div>
              </td>
              <td>
                <div>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  B. Personal information as defined in the California Customer
                  Records statute
                </div>
              </td>
              <td>
                <div>
                  Name, contact information, education, employment, employment
                  history, and financial information
                </div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  C. Protected classification characteristics under state or
                  federal law
                </div>
              </td>
              <td>
                <div>Gender and date of birth</div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>D. Commercial information</div>
              </td>
              <td>
                <div>
                  Transaction information, purchase history, financial details,
                  and payment information
                </div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>E. Biometric information</div>
              </td>
              <td>
                <div>Fingerprints and voiceprints</div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>F. Internet or other similar network activity</div>
              </td>
              <td>
                <div>
                  Browsing history, search history, online behaviour, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>G. Geolocation data</div>
              </td>
              <td>
                <div>Device location</div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </div>
              </td>
              <td>
                <div>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>I. Professional or employment-related information</div>
              </td>
              <td>
                <div>
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>J. Education Information</div>
              </td>
              <td>
                <div>Student records and directory information</div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  K. Inferences drawn from collected personal information
                </div>
              </td>
              <td>
                <div>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual&rsquo;s preferences and
                  characteristics
                </div>
              </td>
              <td>
                <div>NO</div>
              </td>
            </tr>
            <tr>
              <td>L. Sensitive personal Information</td>
              <td>
                <div data-empty="true">NO</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p>
            We will use and retain the collected personal information as needed
            to provide the Services or for:
          </p>
          <ul>
            <li>Category B - As long as the user has an account with us</li>
          </ul>
          <p>
            We may also collect other personal information outside of these
            categories through instances where you interact with us in person,
            online, or by phone or mail in the context of:
          </p>
          <ul>
            <li>Receiving help through our customer support channels;</li>
          </ul>
          <ul>
            <li>Participation in customer surveys or contests; and</li>
          </ul>
          <ul>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          </ul>
          <h4>
            <strong>How do we use and share your personal information?</strong>
          </h4>
          <p>
            Learn about how we use your personal information in the section,
            &apos;
            <a href="#infouse">HOW DO WE PROCESS YOUR INFORMATION?</a>
            &apos;
          </p>
          <h4>
            <strong>Will your information be shared with anyone else?</strong>
          </h4>
          <p>
            We may disclose your personal information with our service providers
            pursuant to a written contract between us and each service provider.
            Learn more about how we disclose personal information to in the
            section, &apos;
            <a href="#whoshare">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
            &apos;
          </p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            &apos;selling&apos; of your personal information.
          </p>
          <p>
            We have not disclosed, sold, or shared any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. We&nbsp;will not sell or share personal
            information in the future belonging to website visitors, users, and
            other consumers.
          </p>
          <h4>
            <strong>California Residents</strong>
          </h4>
          <p>
            California Civil Code Section 1798.83, also known as the &apos;Shine
            The Light&apos; law permits our users who are California residents
            to request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with the Services, you have the right to request
            removal of unwanted data that you publicly post on the Services. To
            request removal of such data, please contact us using the contact
            information provided below and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Services,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>
          <h4>
            <strong>
              <u>CCPA Privacy Notice</u>
            </strong>
          </h4>
          <p>
            This section applies only to California residents. Under the
            California Consumer Privacy Act (CCPA), you have the rights listed
            below.
          </p>
          <p>
            The California Code of Regulations defines a &apos;residents&apos;
            as:
          </p>
          <p>
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </p>
          <p>
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </p>
          <p>All other individuals are defined as &apos;non-residents&apos;.</p>
          <p>
            If this definition of &apos;resident&apos; applies to you, we must
            adhere to certain rights and obligations regarding your personal
            information.
          </p>
          <h4>
            <strong>Your rights with respect to your personal data</strong>
          </h4>
          <p>
            <u>
              Right to request deletion of the data &mdash; Request to delete
            </u>
          </p>
          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation, or any
            processing that may be required to protect against illegal
            activities.
          </p>
          <p>
            <u>Right to be informed &mdash; Request to know</u>
          </p>
          <p>Depending on the circumstances, you have a right to know:</p>
          <ul>
            <li>whether we collect and use your personal information;</li>
          </ul>
          <ul>
            <li>the categories of personal information that we collect;</li>
          </ul>
          <ul>
            <li>
              the purposes for which the collected personal information is used;
            </li>
          </ul>
          <ul>
            <li>
              whether we sell or share personal information to third parties;
            </li>
          </ul>
          <ul>
            <li>
              the categories of personal information that we sold, shared, or
              disclosed for a business purpose;
            </li>
          </ul>
          <ul>
            <li>
              the categories of third parties to whom the personal information
              was sold, shared, or disclosed for a business purpose;
            </li>
          </ul>
          <ul>
            <li>
              the business or commercial purpose for collecting, selling, or
              sharing personal information; and
            </li>
          </ul>
          <ul>
            <li>
              the specific pieces of personal information we collected about
              you.
            </li>
          </ul>
          <p>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>
          <p>
            <u>
              Right to Non-Discrimination for the Exercise of a Consumer&rsquo;s
              Privacy Rights
            </u>
          </p>
          <p>
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p>
            <u>
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </u>
          </p>
          <p>
            We do not process consumer&apos;s sensitive personal information.
          </p>
          <p>
            <u>Verification process</u>
          </p>
          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p>
            <u>Other privacy rights</u>
          </p>
          <ul>
            <li>
              You may object to the processing of your personal information.
            </li>
          </ul>
          <ul>
            <li>
              You may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the information.
            </li>
          </ul>
          <ul>
            <li>
              You can designate an authorised agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorised
              agent that does not submit proof that they have been validly
              authorised to act on your behalf in accordance with the CCPA.
            </li>
          </ul>
          <ul>
            <li>
              You may request to opt out from future selling or sharing of your
              personal information to third parties. Upon receiving an opt-out
              request, we will act upon the request as soon as feasibly
              possible, but no later than fifteen (15) days from the date of the
              request submission.
            </li>
          </ul>
          <p>
            To exercise these rights, you can contact us by email at{' '}
            {POLICY_EMAIL}, or by referring to the contact details at the bottom
            of this document. If you have a complaint about how we handle your
            data, we would like to hear from you.
          </p>
          <h4>
            <strong>Colorado Residents</strong>
          </h4>
          <p>
            This section applies only to Colorado residents. Under the Colorado
            Privacy Act (CPA), you have the rights listed below. However, these
            rights are not absolute, and in certain cases, we may decline your
            request as permitted by law.
          </p>
          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
          </ul>
          <ul>
            <li>Right to access your personal data</li>
          </ul>
          <ul>
            <li>Right to correct inaccuracies in your personal data</li>
          </ul>
          <ul>
            <li>Right to request deletion of your personal data</li>
          </ul>
          <ul>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
          </ul>
          <ul>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects (&apos;profiling&apos;)
            </li>
          </ul>
          <p>
            To submit a request to exercise these rights described above, please
            email {POLICY_EMAIL}.
          </p>
          <p>
            If we decline to take action regarding your request and you wish to
            appeal our decision, please email us at {POLICY_EMAIL}. Within
            forty-five (45) days of receipt of an appeal, we will inform you in
            writing of any action taken or not taken in response to the appeal,
            including a written explanation of the reasons for the decisions.
          </p>
          <h4>
            <strong>Connecticut Residents</strong>
          </h4>
          <p>
            This section applies only to Connecticut residents. Under the
            Connecticut Data Privacy Act (CTDPA), you have the rights listed
            below. However, these rights are not absolute, and in certain cases,
            we may decline your request as permitted by law.
          </p>
          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
          </ul>
          <ul>
            <li>Right to access your personal data</li>
          </ul>
          <ul>
            <li>Right to correct inaccuracies in your personal data</li>
          </ul>
          <ul>
            <li>Right to request deletion of your personal data</li>
          </ul>
          <ul>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
          </ul>
          <ul>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects (&apos;profiling&apos;)
            </li>
          </ul>
          <p>
            To submit a request to exercise these rights described above, please
            email {POLICY_EMAIL}.
          </p>
          <p>
            If we decline to take action regarding your request and you wish to
            appeal our decision, please email us at {POLICY_EMAIL}. Within sixty
            (60) days of receipt of an appeal, we will inform you in writing of
            any action taken or not taken in response to the appeal, including a
            written explanation of the reasons for the decisions.
          </p>
          <h4>
            <strong>Utah Residents</strong>
          </h4>
          <p>
            This section applies only to Utah residents. Under the Utah Consumer
            Privacy Act (UCPA), you have the rights listed below. However, these
            rights are not absolute, and in certain cases, we may decline your
            request as permitted by law.
          </p>
          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
          </ul>
          <ul>
            <li>Right to access your personal data</li>
          </ul>
          <ul>
            <li>Right to request deletion of your personal data</li>
          </ul>
          <ul>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
          </ul>
          <ul>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising or the sale of personal data
            </li>
          </ul>
          <p>
            To submit a request to exercise these rights described above, please
            email {POLICY_EMAIL}.
          </p>
          <h4>
            <strong>Virginia Residents</strong>
          </h4>
          <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
          <p>
            &apos;Consumer&apos; means a natural person who is a resident of the
            Commonwealth acting only in an individual or household context. It
            does not include a natural person acting in a commercial or
            employment context.
          </p>
          <p>
            &apos;Personal data&apos; means any information that is linked or
            reasonably linkable to an identified or identifiable natural person.
            &apos;Personal data&apos; does not include de-identified data or
            publicly available information.
          </p>
          <p>
            &apos;Sale of personal data&apos; means the exchange of personal
            data for monetary consideration.
          </p>
          <p>
            If this definition of &apos;consumer&apos; applies to you, we must
            adhere to certain rights and obligations regarding your personal
            data.
          </p>
          <p>
            <u>Your rights with respect to your personal data</u>
          </p>
          <ul>
            <li>
              Right to be informed whether or not we are processing your
              personal data
            </li>
          </ul>
          <ul>
            <li>Right to access your personal data</li>
          </ul>
          <ul>
            <li>Right to correct inaccuracies in your personal data</li>
          </ul>
          <ul>
            <li>Right to request deletion of your personal data</li>
          </ul>
          <ul>
            <li>
              Right to obtain a copy of the personal data you previously shared
              with us
            </li>
          </ul>
          <ul>
            <li>
              Right to opt out of the processing of your personal data if it is
              used for targeted advertising, the sale of personal data, or
              profiling in furtherance of decisions that produce legal or
              similarly significant effects (&apos;profiling&apos;)
            </li>
          </ul>
          <p>
            <u>Exercise your rights provided under the Virginia VCDPA</u>
          </p>
          <p>You may contact us by email at {POLICY_EMAIL}.</p>
          <p>
            If you are using an authorised agent to exercise your rights, we may
            deny a request if the authorised agent does not submit proof that
            they have been validly authorised to act on your behalf.
          </p>
          <p>
            <u>Verification process</u>
            <br />
          </p>
          <p>
            We may request that you provide additional information reasonably
            necessary to verify you and your consumer&apos;s request. If you
            submit the request through an authorised agent, we may need to
            collect additional information to verify your identity before
            processing your request.
          </p>
          <p>
            Upon receiving your request, we will respond without undue delay,
            but in all cases, within forty-five (45) days of receipt. The
            response period may be extended once by forty-five (45) additional
            days when reasonably necessary. We will inform you of any such
            extension within the initial 45-day response period, together with
            the reason for the extension.
          </p>
          <p>
            <u>Right to appeal</u>
          </p>
          <p>
            If we decline to take action regarding your request, we will inform
            you of our decision and reasoning behind it. If you wish to appeal
            our decision, please email us at {POLICY_EMAIL}. Within sixty (60)
            days of receipt of an appeal, we will inform you in writing of any
            action taken or not taken in response to the appeal, including a
            written explanation of the reasons for the decisions. If your appeal
            is denied, you may contact the{' '}
            <a
              href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
              target="_blank"
              rel="noopener noreferrer"
            >
              Attorney General to submit a complaint
            </a>
            .
          </p>
          <h3 id="otherlaws">
            <strong>13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
          </h3>
          <p>
            <em>
              <strong>In Short:</strong>
              &nbsp;You may have additional rights based on the country you
              reside in.
            </em>
          </p>
          <p>
            <strong>Australia</strong> <strong>and</strong>{' '}
            <strong>New Zealand</strong>
          </p>
          <p>
            We collect and process your personal information under the
            obligations and conditions set by Australia&apos;s Privacy Act 1988
            and New Zealand&apos;s Privacy Act 2020 (Privacy Act).
          </p>
          <p>
            This privacy notice satisfies the notice requirements defined in
            both Privacy Acts, in particular: what personal information we
            collect from you, from which sources, for which purposes, and other
            recipients of your personal information.
          </p>
          <p>
            If you do not wish to provide the personal information necessary to
            fulfill their applicable purpose, it may affect our ability to
            provide our services, in particular:
          </p>
          <ul>
            <li>offer you the products or services that you want</li>
          </ul>
          <ul>
            <li>respond to or help with your requests</li>
          </ul>
          <ul>
            <li>manage your account with us</li>
          </ul>
          <ul>
            <li>confirm your identity and protect your account</li>
          </ul>
          <p>
            At any time, you have the right to request access to or correction
            of your personal information. You can make such a request by
            contacting us by using the contact details provided in the section
            &apos;
            <a href="#request">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
            &apos;
          </p>
          <p>
            If you believe we are unlawfully processing your personal
            information, you have the right to submit a complaint about a breach
            of the Australian Privacy Principles to the{' '}
            <a
              href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Office of the Australian Information Commissioner
            </a>{' '}
            and a breach of New Zealand&apos;s Privacy Principles to the{' '}
            <a
              href="https://www.privacy.org.nz/your-rights/making-a-complaint/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Office of New Zealand Privacy Commissioner
            </a>
            .
          </p>
          <h4>
            <strong>Republic of South Africa</strong>
          </h4>
          <p>
            At any time, you have the right to request access to or correction
            of your personal information. You can make such a request by
            contacting us by using the contact details provided in the section
            &apos;
            <a href="#request">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
            &apos;
          </p>
          <p>
            If you are unsatisfied with the manner in which we address any
            complaint with regard to our processing of personal information, you
            can contact the office of the regulator, the details of which are:
          </p>
          <p>
            <a
              href="https://inforegulator.org.za/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Information Regulator (South Africa)
            </a>
          </p>
          <p>
            General enquiries:&nbsp;
            <a
              href="mailto:enquiries@inforegulator.org.za"
              target="_blank"
              rel="noopener noreferrer"
            >
              enquiries@inforegulator.org.za
            </a>
          </p>
          <p>
            Complaints (complete POPIA/PAIA form 5):&nbsp;
            <a
              href="mailto:PAIAComplaints@inforegulator.org.za"
              target="_blank"
              rel="noopener noreferrer"
            >
              PAIAComplaints@inforegulator.org.za
            </a>
            &nbsp;&amp;&nbsp;
            <a
              href="mailto:POPIAComplaints@inforegulator.org.za"
              target="_blank"
              rel="noopener noreferrer"
            >
              POPIAComplaints@inforegulator.org.za
            </a>
          </p>
          <h3 id="policyupdates">
            <strong>14. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
          </h3>
          <p>
            <em>
              <strong>In Short:&nbsp;</strong>
              Yes, we will update this notice as necessary to stay compliant
              with relevant laws.
            </em>
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated &apos;Revised&apos; date and
            the updated version will be effective as soon as it is accessible.
            If we make material changes to this privacy notice, we may notify
            you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <h3 id="contact">
            <strong>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
          </h3>
          <p>
            If you have questions or comments about this notice, you may contact
            our Data Protection Officer (DPO), Aviral Gohil,&nbsp;by email
            at&nbsp;{POLICY_EMAIL},&nbsp;by phone at +91 8770505152, or contact
            us by post at:
          </p>
          <div>ExaRoutes</div>
          <div>Aviral Gohil</div>
          <div>149, Darhai road</div>
          <div>Sarafa</div>
          <div>Jabalpur, Madhya Pradesh 482002</div>
          <div>India</div>
          <h3 id="request">
            <strong>
              16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </strong>
          </h3>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please contact us at{' '}
            {POLICY_EMAIL}.
          </p>
        </div>
        <br />
      </div>
      <br />
    </div>
  )
}

export default PrivacyPolicy
