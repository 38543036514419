import React, { useEffect } from 'react'
import { CLIENT_SITE_NAME } from '../Config'

const Layout = ({
  children,
  title
}: {
  children: JSX.Element
  title?: string
}): JSX.Element => {
  useEffect(() => {
    document.title = title || CLIENT_SITE_NAME
  }, [])

  return <>{children}</>
}

export default Layout
