import { VectorMap } from '@react-jvectormap/core'
import { worldMill } from '@react-jvectormap/world'
import React, { useEffect, useState } from 'react'

const CountMap = ({ data }: { data: Record<string, number> }): JSX.Element => {
  const [mapId, setMapId] = useState(0)

  const customTipOptions = {
    onRegionTipShow: (event: any, label: any, code: any) => {
      const customTooltipContent = `<div style="background-color: white; border: 0; outline: 10px solid white; border-radius: 6px; color: black; padding: 8px 20px;">
      <p style="font-size:16px"><b>${label.html() as string}</b>: ${
        data[code as keyof typeof data] || 0
      }</p></div>`
      label.html(customTooltipContent)
    }
  }

  useEffect(() => {
    setMapId((prevKey) => prevKey + 1)
  }, [data])

  return (
    <VectorMap
      key={mapId}
      map={worldMill}
      backgroundColor="white"
      zoomOnScroll={false}
      markerStyle={{
        initial: {
          fill: 'red',
          stroke: '#383f47'
        }
      }}
      series={{
        regions: [
          {
            scale: ['#8dc7fd', '#08427c'],
            attribute: 'fill',
            values: data,
            normalizeFunction: 'polynomial'
          }
        ]
      }}
      regionStyle={{
        initial: {
          fill: '#D1D5DB',
          fillOpacity: 1,
          stroke: '#265cff',
          strokeWidth: 0,
          strokeOpacity: 0
        },
        hover: {
          fillOpacity: 0.8,
          fill: '',
          stroke: '#2b2b2b'
        // },
        // selected: {
        //   fill: '#FFFB00'
        }
      }}
      regionsSelectable={true}
      regionsSelectableOne={true}
      {...customTipOptions}
    />
  )
}

export default CountMap
