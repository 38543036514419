import styled from 'styled-components'
import React, { useState } from 'react'
import { Hr } from '../elements/CommonElements'
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg'
import { useAuth } from '../context/Context'
import Snackbar, { type SnackbarBaseProps } from '../elements/Snackbar'
import { emptySnackbarData } from '../../components/dashboard/Dashboard'

const AltPriceSpan = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #777777;
`

const AltText = styled.div`
  margin: 20px 0;
  color: #777777;
`

const Container = styled.div`
  border-radius: 10px;
  padding: 0 20px;
  background-color: white;
  color: black;
  width: 350px;
  transition: all 0.2s ease-in-out;
  margin: 25px;
  display: inline-block;
  text-align: left;
  position: relative;

  &:hover {
    background-color: black;
    color: white;
  }

  &:hover ${AltPriceSpan}, &:hover ${AltText} {
    color: #cfcfcf;
  }

  &:hover button {
    color: black;
    background: white;
  }

  &:hover hr {
    background: #777777;
  }
`

const Heading = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin: 18px 0;
`

const PriceSpan = styled.span`
  font-size: 26px;
  font-weight: 700;
`

const DetailLine = styled.div`
  margin: 20px 0;

  .child {
    vertical-align: middle;
    line-height: 1em;
  }

  .opacity {
    opacity: 35%;
  }
`

const BlackButton = styled.button`
  padding: 10px 24px;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  background: black;
  border: 0;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: absolute;
  bottom: 20px;

  &:active {
    box-shadow: none;
  }

  &:hover {
    cursor: pointer;
  }
`

export interface PlanCardType {
  id: string
  heading: string
  price: string
  cycle?: string
  description: string
  lines: string[]
  // onClick: () => void
}

const PlanCard = ({
  planName,
  heading,
  price,
  cycle = 'month',
  description,
  lines,
  onClick,
  minHeight
}: PlanCardType & {
  planName: string
  onClick: (id: string) => void
  minHeight?: string
}): JSX.Element => {
  const { user, isUserLoggedIn } = useAuth()

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)
  return (
    <Container style={minHeight ? { minHeight } : {}}>
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <div>
        <Heading>
          <span>{heading}</span>
        </Heading>
        <Hr />
        <div style={{ margin: '20px 0' }}>
          <PriceSpan>{price}</PriceSpan>&nbsp;
          <AltPriceSpan>/&nbsp;{cycle}</AltPriceSpan>
        </div>
        <AltText>{description}</AltText>
        {lines.map((line, i) => (
          <DetailLine key={i}>
            {/* <CheckCircleIcon className={'child opacity'} /> */}
            <CheckCircle
              style={{ verticalAlign: 'middle', color: 'rgb(119, 119, 119)' }}
            />
            <span className={'child'} style={{ marginLeft: '10px' }}>
              {line}
            </span>
          </DetailLine>
        ))}
        <BlackButton
          onClick={(e) => {
            if (!isUserLoggedIn) return onClick('')
            user?.verified
              ? onClick(planName)
              : setSnackbarData({
                  type: 'info',
                  message:
                    'Please verify your email address from profile, before proceeding further.'
                })
          }
        }
        >
          Choose plan
        </BlackButton>
      </div>
    </Container>
  )
}

export default PlanCard
