import React, { useEffect, useState } from 'react'
import { api } from '../../utils/AxiosConfig'
import { useParams } from 'react-router-dom'
import CountMap from '../../common/mapNCharts/CountMap'
import CountChart from '../../common/mapNCharts/CountChart'
import SelectInput from '../../common/elements/SelectInput'
import Loader from '../../common/misc/loader/Loader'
import { Checkbox } from '../../common/elements/InputControls'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'

type RawQRAnalytics = Array<{
  _id: {
    country?: string
    createdAt?: string
    source?: string
  }
  count: number
}>

interface QRAnalytics {
  countryWise: Record<string, Record<string, number>>
  dayWise: {
    1: Record<string, Record<string, number>>
    3: Record<string, Record<string, number>>
    6: Record<string, Record<string, number>>
  }
}

const Analytics = (): JSX.Element => {
  const { subId, itemId } = useParams()
  // const { product } = useParams()      // could be used in future to show manage component for different products
  let qrId: string
  let subscriptionId: string
  try {
    qrId = atob(itemId as string)
    subscriptionId = atob(subId as string)
  } catch (err) {
    console.error('Error while getting qr id', err)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)
  const [dayRange, setDayRange] = useState(1)

  const [analytics, setAnalytics] = useState<QRAnalytics>({
    countryWise: {},
    dayWise: { 1: {}, 3: {}, 6: {} }
  })

  const [showHideDaySources, setShowHideDaySources] = useState<
    Record<string, boolean>
  >({})

  const [showHideCountrySources, setShowHideCountrySources] = useState<
    Record<string, boolean>
  >({})

  const collectAnalytics = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await api.get('/api/QR/analytics', {
        headers: { qrid: qrId, 'subscription-id': subscriptionId }
      })
      const sanitizedCountryWiseData = sanitizeCountData(
        response.data?.countryWiseAnalytics || []
      )
      const countrySourcesObj: Record<string, boolean> = {}
      Object.keys(sanitizedCountryWiseData).forEach((ck) => {
        countrySourcesObj[ck] = ck === 'total'
      })
      setShowHideCountrySources(countrySourcesObj)
      const responseAnalytics: QRAnalytics = {
        countryWise: sanitizedCountryWiseData,
        dayWise: {
          1: generateDayWiseObject(response.data?.dayWiseAnalytics || [], 1),
          3: {},
          6: {}
        }
      }
      setAnalytics(responseAnalytics)
    } catch (err) {
      console.error('Error occurred while fetching analytics', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching analytics'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const refreshDayRange = async (newDayRange: number): Promise<void> => {
    try {
      setIsLoading(true)
      setDayRange(newDayRange)
      if (
        Object.keys(
          analytics.dayWise[newDayRange as keyof typeof analytics.dayWise] || {}
        ).length !== 0
      )
        // return if data is already present
        return
      const response = await api.get('/api/QR/analytics/day-wise', {
        headers: {
          qrid: qrId,
          day: newDayRange * 30,
          'subscription-id': subscriptionId
        }
      })
      setAnalytics((prev) => ({
        ...prev,
        dayWise: {
          ...prev.dayWise,
          [newDayRange]: generateDayWiseObject(response.data || [], newDayRange)
        }
      }))
    } catch (err) {
      console.error('Error occurred while re-freshing day wise analytics', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while re-freshing day wise analytics'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const sanitizeCountData = (
    data: RawQRAnalytics
  ): Record<string, Record<string, number>> => {
    return data.reduce(
      (result: Record<string, Record<string, number>>, item) => {
        const { country, createdAt, source } = item._id
        const count = item.count

        const innerKey = country || createdAt

        if (!innerKey) return result

        if (!result.total) {
          result.total = {}
        }

        if (!result.total[innerKey]) {
          result.total[innerKey] = 0
        }

        result.total[innerKey] += count

        if (!source) {
          if (!result.noSource) {
            result.noSource = {}
          }

          result.noSource[innerKey] = count
        } else {
          if (!result[source]) {
            result[source] = {}
          }

          if (!result[source][innerKey]) {
            result[source][innerKey] = 0
          }

          result[source][innerKey] += count
        }
        return result
      },
      {}
    )
  }

  const generateDayWiseObject = (
    data: RawQRAnalytics,
    newDayRange: number
  ): Record<string, Record<string, number>> => {
    const emptyObject = generateEmptyObject(newDayRange)
    const dataObject = sanitizeCountData(data)
    const daySourcesObj: Record<string, boolean> = {}
    Object.keys(dataObject).forEach((dk) => {
      dataObject[dk] = { ...emptyObject, ...dataObject[dk] }
      daySourcesObj[dk] = dk === 'total'
    })
    setShowHideDaySources(daySourcesObj)
    return dataObject
  }

  const generateEmptyObject = (newDayRange: number): Record<string, number> => {
    const emptyObject: Record<string, number> = {}
    const currentDate = new Date()

    for (let i = 0; i < newDayRange * 30; i++) {
      const dateKey = currentDate.toISOString().split('T')[0]
      emptyObject[dateKey] = 0
      currentDate.setDate(currentDate.getDate() - 1)
    }

    return emptyObject
  }

  const parseMapData = (): Record<string, number> => {
    const mapKeys = Object.keys(showHideCountrySources).filter(
      (k) => showHideCountrySources[k]
    )
    if (mapKeys.includes('total')) return analytics.countryWise.total
    if (mapKeys && mapKeys.length > 0) {
      const parsedObj: Record<string, number> = {}
      mapKeys.forEach((k) => {
        for (const key in analytics.countryWise[k]) {
          parsedObj[key] = analytics.countryWise[k][key] + (parsedObj[key] || 0)
        }
      })
      return parsedObj
    } else return {}
  }

  useEffect(() => {
    collectAnalytics()
  }, [])

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      {Object.keys(analytics.countryWise).length > 0 ? (
        <>
          <h2 style={{ margin: '40px 0' }}>Country wise QR scanned</h2>
          <div style={{ margin: '20px 0' }}>
            {Object.keys(analytics.countryWise).map((k) => {
              const label =
                k === 'noSource'
                  ? 'No source'
                  : `${k === 'total' ? 'All' : `Source - ${k}`}`
              return (
                <Checkbox
                  key={k}
                  label={label}
                  checked={showHideCountrySources[k]}
                  style={{ marginRight: '20px' }}
                  onChange={(e) =>
                    setShowHideCountrySources((prev) => ({
                      ...prev,
                      [k]: e.target.checked
                    }))
                  }
                />
              )
            })}
          </div>
          <div style={{ height: '700px' }}>
            <CountMap data={parseMapData()} />
          </div>
        </>
      ) : null}
      {Object.keys(analytics.dayWise).length > 0 ? (
        <>
          <h2 style={{ margin: '40px 0' }}>Day wise QR scanned</h2>
          <SelectInput
            label="For past:"
            selectList={[
              { id: '1', label: '1 Month' },
              { id: '3', label: '3 Months' },
              { id: '6', label: '6 Months' }
            ]}
            onListItemClick={async (itemId) => await refreshDayRange(itemId)}
            selected={`${dayRange}`}
            width="250px"
          />
          <div style={{ margin: '20px 0' }}>
            {Object.keys(
              analytics.dayWise[dayRange as keyof typeof analytics.dayWise]
            ).map((k) => {
              const label =
                k === 'noSource'
                  ? 'No source'
                  : `${k === 'total' ? 'All' : `Source - ${k}`}`
              return (
                <Checkbox
                  key={k}
                  label={label}
                  checked={showHideDaySources[k]}
                  style={{ marginRight: '20px' }}
                  onChange={(e) =>
                    setShowHideDaySources((prev) => ({
                      ...prev,
                      [k]: e.target.checked
                    }))
                  }
                />
              )
            })}
          </div>
          <br />
          <br />
          <CountChart
            data={analytics.dayWise[dayRange as keyof typeof analytics.dayWise]}
            visibleDatasets={showHideDaySources}
          />
        </>
      ) : null}
    </div>
  )
}

export default Analytics
