import React, { useState } from 'react'
import Input, { TextArea } from '../../common/elements/Input'
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg'
import './Support.css'
import { PrimaryButton } from '../../common/elements/CommonElements'
import { emptySnackbarData } from '../dashboard/Dashboard'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import Loader from '../../common/misc/loader/Loader'
import { api } from '../../utils/AxiosConfig'
import { CLIENT_SITE_NAME, SUPPORT_EMAIL } from '../../Config'

interface FAQ {
  Q: string
  A: string
}

const Support = (): JSX.Element => {
  const websiteQuestionsArray: FAQ[] = [
    {
      Q: `How do I sign up for an account on ${CLIENT_SITE_NAME}?`,
      A: `To sign up for an account on ${CLIENT_SITE_NAME}, you can simply visit our sign-up page. There, you'll find a form where you can enter your name, email, and password to create your account. Alternatively, you can choose to sign up using your Google account for added convenience.`
    },
    {
      Q: 'What personal information is required to create an account?',
      A: "To create an account, you'll need to provide your name, email address, and choose a password. Alternatively, you can sign up using your Google account."
    },
    {
      Q: 'Is there a verification process for new accounts?',
      A: "Yes, for new accounts, we have a verification process in place. After you sign up, we'll send a verification email to the email address you provided during registration. Simply follow the instructions in the email to verify your account and gain access to our services."
    },
    {
      Q: 'How can I reset my password if I forget it?',
      A: "If you forget your password, don't worry, we've got you covered. Simply visit the login page and click on the `Forget password` button. We'll then send an email to the address associated with your account, containing a link that will allow you to reset your password securely."
    },
    {
      Q: 'Can I manage multiple users or accounts under one subscription?',
      A: "Our subscription model operates on a single API key basis, meaning any request made with this key will be authenticated and processed accordingly. While there's no specific provision for managing multiple users or accounts under one subscription, our system grants access and controls usage solely through the provided API key."
    },
    {
      Q: 'Is there a dashboard where I can track my usage and subscription status?',
      A: "Yes, we provide a dashboard where you can track your usage and subscription status. Within the dashboard, you'll find all your subscriptions listed. Each subscription includes an option to `Manage` it. By selecting `Manage`, you can access detailed information about your subscription, including usage metrics and status updates."
    },
    {
      Q: 'What security measures are in place to protect my account and data?',
      A: "We employ robust security measures to safeguard your account and data. We've implemented industry-standard methods for authentication to ensure the security of your account. Additionally, we implement Two-Factor Authentication (2FA) to bolster authorization processes, adding an extra layer of protection. All requests are processed through API keys, enhancing security by tightly controlling access to our systems. These measures collectively help fortify our platform against unauthorized access and data breaches, prioritizing the safety and confidentiality of your information."
    },
    {
      Q: 'Can I update my billing information or payment method?',
      A: 'Yes, you can update your billing information or payment method from `Account settings -> Payment` for the entire account. Additionally, for individual subscriptions, you can update these details by navigating to `Dashboard -> Selected subscription -> Update payment method (button)`.'
    },
    {
      Q: 'Can I change my subscription plan?',
      A: "Yes, you can change your subscription plan from the dashboard. When upgrading or downgrading, the changes take effect instantly. However, please note that if you choose to downgrade, you may not have immediate access to certain features if they're not included in the new plan. Also, downgrading is only possible if you're not actively using features that are not present in the downgrading plan.\n\n In the case of an upgrade, you'll gain access to the features of the new plan and will be billed accordingly. However, for downgrades, you'll continue to be charged at the prices of the current plan until the end of the billing cycle. During this period, you won't have access to features exclusive to the current plan. Hence, it's advisable to plan downgrades towards the end of your current billing cycle to optimize cost and feature availability."
    },
    {
      Q: `Can I provide feedback or suggestions for improving ${CLIENT_SITE_NAME}`,
      A: `Yes, absolutely. You can provide feedback or suggestions for improving ${CLIENT_SITE_NAME} by filling out the feedback form located on our dedicated feedback page. We highly value your input and use it to continually enhance and refine our services. Your feedback helps us better understand your needs and ensure that our platform meets your expectations.`
    },
    {
      Q: 'Can I customize my user profile or settings on the website?',
      A: "Yes, you can customize your user profile and settings directly on our website. Simply navigate to the `Account settings` section, where you'll find options to personalize your profile and adjust settings according to your preferences. This allows you to tailor your experience on our platform to best suit your needs."
    },
    {
      Q: `Is there a blog or resource section on the website for news and updates about the ${CLIENT_SITE_NAME}?`,
      A: `Yes, there is a blog or resource section on the website for news and updates about ${CLIENT_SITE_NAME}`
    },
    {
      Q: 'Can I view past invoices or payment history on the website?',
      A: "Yes, you can view past invoices or payment history directly on the website. To access invoices, navigate to the dashboard and select the subscription for which you want to view the invoice. Then, choose the `Invoices` option from the three-dot menu. For viewing payment history, you can simply navigate to the `Transactions` section, where you'll find a record of all past payments."
    },
    {
      Q: 'Are there any social media channels linked to the website for further engagement?',
      A: 'Yes, we have social media channels linked to the website for further engagement. You can find us on Facebook, X, and LinkedIn.'
    },
    {
      Q: 'How can I subscribe to your newsletter or mailing list for updates and promotions?',
      A: 'NEEDS IMPLEMENTATION'
    },
    {
      Q: 'How do I subscribe to a plan and generate an API key?',
      A: 'To subscribe to a plan, simply visit the desired product page and click on the `Subscribe` button. Alternatively, you can visit the pricing page and choose the plan that best fits your needs. Once subscribed, you can generate an API key from the dashboard and start utilizing the API immediately.'
    },
    {
      Q: 'Can I upgrade or downgrade my subscription plan at any time?',
      A: "Yes, you can upgrade or downgrade your subscription plan at any time, and the changes take effect immediately.\n\n When upgrading, you'll gain immediate access to the features of the upgraded plan and will be charged accordingly for the current month. From the upcoming months, you'll be charged according to the pricing of the upgraded plan.\n\n For downgrading, if you're actively utilizing features of your current plan, you won't be allowed to downgrade. However, if you proceed with the downgrade, the changes will take effect immediately. You won't have access to features exclusive to your current plan immediately after the downgrade, but you'll continue to be charged according to the pricing of the current subscribed plan until the end of the billing cycle. Hence, it's advisable to plan downgrades towards the end of your current billing cycle to optimize cost and feature availability."
    },
    {
      Q: 'What payment methods do you accept for subscriptions?',
      A: 'At present, we exclusively accept payments via credit or debit cards for subscriptions.'
    },
    {
      Q: 'Are there any usage restrictions or limitations on the QR code generator API?',
      A: 'Yes, there are usage restrictions or limitations on the QR code generator API, which vary depending on the selected plan.'
    },
    {
      Q: 'How do I integrate the APIs provided by your website into my application?',
      A: 'To integrate our APIs into your application, begin by adding or updating your domain name in the account settings. This action ensures that your domain is whitelisted for API access. From there, you can seamlessly send API calls from your domain to our servers, including your client ID, API key, and any necessary request parameters. This streamlined process facilitates smooth integration of our APIs, enhancing functionality across your application.'
    },
    {
      Q: 'What happens if I exceed the usage limits of my subscription plan?',
      A: 'If you exceed the usage limits of your subscription plan, any user requests beyond those limits will not be fulfilled. Instead, an error will be returned upon making an API call.'
    },
    {
      Q: 'Can I cancel my subscription at any time? If so, what happens to my API key?',
      A: 'Yes, you can cancel your subscription at any time, and the cancellation will take effect at the end of the current billing cycle. Following cancellation, your API key will remain active until the end of the billing cycle. However, after the billing cycle ends, you will no longer be able to make requests using that API key.'
    }
  ]

  const qrQuestionsArray: FAQ[] = [
    {
      Q: 'How does the QR code generator API work?',
      A: 'The QR code generator API works in a straightforward manner. Firstly, the user subscribes to a QR code generator plan. Upon successful subscription, they can generate an API key from the dashboard. With this API key and client ID, users can then make API requests to our servers, enabling them to generate QR codes seamlessly.'
    },
    {
      Q: 'What are the benefits of using your QR code generator API?',
      A: 'The benefits of using our QR code generator API are manifold. Firstly, it enables the creation of dynamic QR codes, allowing for flexible content updates. Security is ensured through advanced encryption methods, providing peace of mind for sensitive data. Additionally, detailed analytics offer insights into scan data and engagement metrics. With seamless file association and bulk processing capabilities, workflow efficiency is enhanced. These features collectively enhance user experience and drive meaningful outcomes for businesses and developers alike.'
    },
    {
      Q: 'Can I try the QR code generator API before subscribing?',
      A: 'Yes, you can try out the QR code generator before subscribing. Simply visit our `Generate QR` page to create QR codes. However, to access the full range of API features, including advanced functionalities, a subscription is required. We offer a 14-day free trial period, allowing you to experience the capabilities of our API firsthand before committing to a subscription.'
    },
    {
      Q: 'What subscription plans do you offer for the QR code generator API?',
      A: 'You can view the plans from our `pricing` page.'
    },
    {
      Q: 'Is there a free trial available for the QR code generator API?',
      A: 'Yes, we offer a 14-day free trial for the QR code generator API.'
    },
    {
      Q: 'Can I customize the design or appearance of the QR codes generated by your API?',
      A: 'Yes, you can customize the design and appearance of the QR codes generated by our API. We offer various modification options for design and appearance. You can find the full list of customization features by visiting our documentation page.'
    },
    {
      Q: 'How secure is the data transmitted through the QR code generator API?',
      A: "The data transmitted through the QR code generator API is highly secure. We implement industry-standard encryption protocols to ensure the confidentiality and integrity of your data during transmission. Additionally, for added security, users have the option to further encrypt or password-protect their QR codes before sharing them. This extra layer of protection enhances the overall security of the transmitted data, providing users with peace of mind regarding their information's safety and confidentiality."
    },
    {
      Q: 'Can I generate dynamic QR codes that can be edited or updated after creation?',
      A: 'Yes, you can generate dynamic QR codes that can be edited or updated after creation. These codes can be easily modified directly from our website, providing flexibility and convenience for your QR code management needs. Simply access our website and navigate to your dashboard. From there, select the subscription and click on `Manage.` You can then search for the QR code you wish to edit and make the necessary modifications.'
    },
    {
      Q: 'What file formats are supported for downloading the generated QR codes?',
      A: 'The generated QR codes can be downloaded in several file formats, including SVG, PNG, JPG, and WEBP.'
    },
    {
      Q: 'Is there a limit to the amount of data that can be encoded within a QR code?',
      A: "Yes, there is a limit to the amount of data that can be encoded within a QR code. The maximum amount of data that a QR code can hold depends on various factors, including the version of the QR code and the type of data being encoded.\n\n QR codes come in different versions, ranging from Version 1 to Version 40. Each version has a different maximum capacity for storing data. Additionally, the type of data being encoded (numeric, alphanumeric, binary, or Kanji characters) affects the amount of data that can be stored.\n\n For example, a standard QR code (Version 40) can hold up to 4,296 alphanumeric characters, 7,089 numeric characters, or 2,953 bytes of binary data. However, if error correction is included (which is common to ensure readability even if the QR code is damaged or partially obscured), the actual usable capacity will be lower.\n\n So while there's no fixed limit applicable to all QR codes, the practical limit depends on the version, error correction level, and type of data being encoded."
    },
    {
      Q: 'Can I track analytics or gather data on QR code scans?',
      A: "Yes, you can track analytics and gather data on QR code scans. To view analytics, simply navigate to your dashboard, select the subscription, and click on the `Manage` button. From there, you can search for the desired QR code by its ID or content (note that content search won't work for secured QR codes). Once you've selected the QR code, click on the `Analytics` button to access detailed analytics and scan data."
    },
    {
      Q: 'Can I customize the error correction level or other parameters of the QR code?',
      A: 'Yes, you can customize various parameters of the QR code, including the error correction level, type number, and mode. This allows you to tailor the QR code generation process to suit your specific requirements and preferences.'
    },
    // {
    //   Q: 'Are there any restrictions on the types of websites or content that can be linked to in QR codes generated with your API?',
    //   A: 'No, there are currently no restrictions on the types of websites or content that can be linked to in QR codes generated with our API.'
    // }
    {
      Q: 'Can I generate QR codes in bulk or through batch processing with your API?',
      A: "Yes, you can generate QR codes in bulk or through batch processing with our API. Each request allows for up to 50,000 QR codes or a total file size of 500 MB. Once you submit a bulk generation request, the server responds with a unique bulk order ID. You can track the status of your bulk order by navigating to the `Bulk orders` section in your dashboard. Once processing is complete, you can download the generated QR codes. It's important to note that the download can only be made once, as we delete the sensitive generated QR data once it is downloaded for security purposes."
    },
    {
      Q: 'How do I handle QR code generation errors or issues with the API?',
      A: 'In most cases, the API response will provide information about the issue encountered during QR code generation. Users can also refer to the "Error Handling" section in the API documentation for guidance on handling common errors. If the problem persists or if further assistance is needed, users can contact our support team for additional help and troubleshooting.'
    },
    {
      Q: 'Can I upload files, such as documents or images, to be associated with QR codes generated through your platform?',
      A: 'Yes, you have the option to upload files, such as documents or images, which can then be linked with QR codes generated through our platform.'
    },
    {
      Q: 'Can I encrypt or password-protect the data within QR codes generated using your platform?',
      A: 'Indeed, users have the ability to encrypt or password-protect the data contained within QR codes generated using our platform. Additionally, an expiration date can be added to QR codes for enhanced security and control.'
    },
    {
      Q: 'Are there any authentication measures in place to prevent unauthorized access to uploaded files associated with QR codes?',
      A: 'Yes, we have robust authentication measures in place to prevent unauthorized access to uploaded files associated with QR codes. These measures include user authentication through secure login credentials and access control mechanisms to ensure that only authorized individuals can access the files. Additionally, we regularly review and update our security protocols to maintain the integrity and confidentiality of user data.'
    },
    {
      Q: 'What types of files can be uploaded and associated with QR codes?',
      A: 'You can upload and associate any type of file with QR codes generated through our platform.'
    },
    {
      Q: 'Is there a limit to the size or number of files that can be uploaded and associated with QR codes?',
      A: 'Yes, there is a limit on the total size of files that can be uploaded and associated with QR codes, which varies depending on your subscription plan.'
    },
    {
      Q: 'Can I update or replace files associated with existing QR codes?',
      A: 'Yes, you can update or replace files associated with existing QR codes. Simply navigate to your dashboard, select the subscription, and click on `Manage.` From there, you can search for the QR code you wish to edit and make the necessary modifications.'
    }
  ]

  const questionsArray: FAQ[] = [...websiteQuestionsArray, ...qrQuestionsArray]

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [QNA, setQNA] = useState(questionsArray)
  const [selectedQuestion, setSelectedQuestion] = useState('')
  const [formData, setFormData] = useState({
    fName: '',
    lName: '',
    email: '',
    message: ''
  })

  function searchFAQs(query: string): void {
    if (query) {
      const weightedQNA = questionsArray.map((qna) => {
        let weight = 0
        query.split(' ').forEach((qWord) => {
          if (qna.Q.includes(qWord)) {
            weight++
          }
          if (qna.A.includes(qWord)) {
            weight++
          }
        })
        return { ...qna, weight }
      })
      const sortedResult = weightedQNA.sort((a, b) => b.weight - a.weight)
      setQNA(sortedResult)
      setSelectedQuestion(sortedResult[0].Q)
      return
    }
    setQNA(questionsArray)
    setSelectedQuestion('')
  }

  async function submitUserQuery(): Promise<void> {
    setIsLoading(true)
    try {
      await api.post('/api/support/', formData)
      setFormData({
        fName: '',
        lName: '',
        email: '',
        message: ''
      })
      setSnackbarData({
        type: 'success',
        message:
          "Thank you for reaching out! We've received your message and will be in touch shortly"
      })
    } catch (err) {
      setSnackbarData({
        type: 'error',
        message:
          'Something went wrong while submitting your query. Please write us at ' +
          SUPPORT_EMAIL
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <div className="headContainer">
        <h3>Hi, how can we help ?</h3>
        <input
          placeholder="FAQs ?"
          onChange={(e) => searchFAQs(e.target.value)}
        />
      </div>
      <div>
        <h4>Frequently asked questions</h4>
        {QNA.map((q) => {
          const isSelected = selectedQuestion === q.Q
          return (
            <div className="qnaContainer" key={q.Q}>
              <div
                className="questionContainer"
                onClick={() =>
                  isSelected
                    ? setSelectedQuestion('')
                    : setSelectedQuestion(q.Q)
                }
              >
                {q.Q}
                <ChevronDown
                  className={`questionChevron ${
                    isSelected ? 'questionChevronUp' : ''
                  }`}
                />
              </div>
              <div
                className={`answerContainer ${
                  isSelected ? 'answerContainerSelected' : ''
                }`}
              >
                {q.A}
              </div>
            </div>
          )
        })}
        <div className="contactUsContainer">
          <div>
            <h3>Get in touch!</h3>
            <span>
              Need to get in touch with us ? We would love to hear from you.
              Either fill out the form with your inquiry or email us at
              <br />
              <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            </span>
          </div>
          <div>
            <Input
              label="First name"
              style={{ display: 'inline-block', width: 'calc(50% - 40px)' }}
              value={formData.fName}
              onChange={(e) =>
                setFormData({ ...formData, fName: e.target.value })
              }
            />
            <Input
              label="Last name"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 40px)',
                marginLeft: '80px'
              }}
              value={formData.lName}
              onChange={(e) =>
                setFormData({ ...formData, lName: e.target.value })
              }
            />
            <br />
            <br />
            <br />
            <Input
              label="Email address"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            <br />
            <br />
            <TextArea
              label="What can we help you with ?"
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              resize="vertical"
            />
            <br />
            <br />
            <br />
            <PrimaryButton
              onClick={async () => await submitUserQuery()}
              disabled={!formData.email || !formData.fName || !formData.message}
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Support
