import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../common/context/Context'

const PrivateRoute = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  const { isUserLoggedIn } = useAuth()
  const location = useLocation();

  const currentUrl = location.pathname;

  return isUserLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ from: currentUrl }} />
  )
}

export default PrivateRoute
