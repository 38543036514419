import React, { useEffect, useState } from 'react'
import {
  PrimaryButton,
  SecondaryButton,
  Table
} from '../../../common/elements/CommonElements'
import { type UserDomain } from '../../../common/types/Types'
import Dialog from '../../../common/elements/Dialog'
import { IconButton } from '../../../common/elements/Buttons'
import { ReactComponent as PlusImage } from '../../../assets/images/plus.svg'
import { ReactComponent as TrashIcon } from '../../../assets/images/trash-2.svg'
import Input from '../../../common/elements/Input'
import Snackbar, {
  type SnackbarBaseProps
} from '../../../common/elements/Snackbar'
import { emptySnackbarData } from '../../dashboard/Dashboard'
import { Validate } from '../../../utils/Validation'
import { api } from '../../../utils/AxiosConfig'
import ConfirmDialog from '../../../common/elements/ConfirmDialog'
import CopyText from '../../../common/elements/CopyText'
import Loader from '../../../common/misc/loader/Loader'
import { useAuth } from '../../../common/context/Context'

const UserDomains = (): JSX.Element => {
  const { user } = useAuth()

  const [domains, setDomains] = useState<UserDomain[]>([])

  const [showAddDomainDialog, setShowAddDomainDialog] = useState(false)
  const [domainToAdd, setDomainToAdd] = useState('')
  const [domainToVerify, setDomainToVerify] = useState<UserDomain>()
  const [domainToRemove, setDomainToRemove] = useState<UserDomain>()
  const [verificationFailed, setVerificationFailed] = useState(false)
  const [errors, setErrors] = useState<Record<string, any>>({})

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const fetchDomains = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.get('/api/domains')
      setDomains(response.data)
    } catch (err) {
      console.error('Error occurred while fetching domains')
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching domains'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const addDomain = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.post('/api/domains', { domain: domainToAdd })
      setDomainToAdd('')
      setShowAddDomainDialog(false)
      setDomains(response.data)
    } catch (err) {
      console.error('Error occurred while adding domain')
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while adding domain'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const verifyDomain = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.put('/api/domains', {
        domain: domainToVerify?.domain
      })
      setDomainToVerify(undefined)
      setVerificationFailed(false)
      setDomains(response.data)
    } catch (err) {
      console.error('Error occurred while verifying domain')
      setVerificationFailed(true)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while verifying domain'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const removeDomain = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.delete('/api/domains', {
        headers: { domain: domainToRemove?.domain }
      })
      setDomainToRemove(undefined)
      setDomains(response.data)
    } catch (err) {
      console.error('Error occurred while removing domain')
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while removing domain'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDomainChange = (value: any): void => {
    setDomainToAdd(value)
    setErrors(Validate(errors, { domain: value }, 'domain', value))
  }

  useEffect(() => {
    fetchDomains()
  }, [])

  return (
    <div style={{ padding: '40px' }}>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <h3 style={{ marginTop: 0 }}>Manage your domains</h3>
      <p>
        To ensure the security and authenticity of your API requests, we require
        you to verify ownership of your domains. This helps us to confirm that
        you have control over the domains and prevents unauthorized access to
        your API keys. Follow the instructions below to complete the domain
        verification process.
      </p>

      <h4>Add a New Domain</h4>
      <p>
        Click the &quot;Add Domain&quot; button to enter a new domain you wish
        to use with our API.
      </p>
      <br />
      <IconButton
        icon={<PlusImage />}
        onClick={() => setShowAddDomainDialog(true)}
      >
        Add domain
      </IconButton>
      <br />

      {Array.isArray(domains) && domains.length > 0 ? (
        <>
          <p>
            Below is a list of domains you have added. To verify a domain, click
            the &quot;Verify&quot; button next to it.
          </p>
          <Table>
            <thead>
              <tr>
                <th>Domain</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {domains?.map((d) => (
                <tr key={d.domain}>
                  <td>{d.domain}</td>
                  <td>
                    {d.status === 'unverified' ? (
                      <span style={{ color: 'var(--er-red)' }}>Unverified</span>
                    ) : (
                      <span style={{ color: 'var(--er-green)' }}>Verified</span>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {d.status === 'unverified' ? (
                      <SecondaryButton onClick={() => setDomainToVerify(d)}>
                        Verify
                      </SecondaryButton>
                    ) : (
                      <IconButton
                        icon={<TrashIcon />}
                        onClick={() => setDomainToRemove(d)}
                      >
                        Remove
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}

      <Dialog
        open={!!domainToVerify}
        onClose={() => {
            setDomainToVerify(undefined)
            setVerificationFailed(false)
        }}
      >
        <h4>Domain verification instructions</h4>
        <p>
          To verify your domain, please add the following DNS TXT record to your
          domain’s DNS settings.
        </p>
        <Table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>TXT</td>
              <td>
                <CopyText
                  style={{ background: 'transparent', minWidth: '200px' }}
                  text={`_verify-${user.clientId}.${domainToVerify?.domain as string}`}
                />
              </td>
              <td>
                <CopyText
                  style={{ background: 'transparent' }}
                  text={domainToVerify?.secret as string}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          After you have added the DNS record, click the &quot;Verify&quot;
          button below to check the DNS record and complete the verification
          process. Once your domain is verified, you can remove the DNS record
          from your domain.
        </p>
        {verificationFailed ? (
          <>
            <div
              style={{
                borderRadius: '8px',
                border: '1px solid var(--er-red)',
                padding: '20px 40px'
              }}
            >
              <h5>
                We were unable to verify your domain. This could be due to one
                of the following reasons:
              </h5>

              <span style={{ fontWeight: 'bold' }}>
                DNS Records Have Not Propagated:
              </span>

              <p>
                DNS changes can take time to propagate across the internet,
                typically between a few minutes and 48 hours. Please wait a
                little longer and try verifying again.
              </p>
              <br />
              <span style={{ fontWeight: 'bold' }}>
                Name Server is Different from Registrar:
              </span>

              <p>
                Ensure that your domain&apos;s DNS settings are managed by the same
                name server listed with your domain registrar. If your name
                server settings are managed elsewhere, make sure the DNS TXT
                record is added correctly at the appropriate provider.
              </p>
            </div>
            <br />
          </>
        ) : null}
        <PrimaryButton onClick={verifyDomain}>Verify</PrimaryButton>
      </Dialog>

      <Dialog
        open={showAddDomainDialog}
        onClose={() => {
          setShowAddDomainDialog(false)
          setDomainToAdd('')
        }}
        style={{ width: '570px' }}
      >
        {/*  style={{ marginTop: 0 }} */}
        <h4>Add a New Domain</h4>
        <br />
        <Input
          label="Domain name"
          value={domainToAdd}
          onChange={(e) => handleDomainChange(e.target.value)}
          error={
            typeof errors.domainValue !== 'undefined'
              ? errors.domainMessage
              : ''
          }
        />
        <br />
        <br />
        <PrimaryButton
          style={{ position: 'absolute', bottom: '30px' }}
          disabled={!domainToAdd || errors.isNotValid}
          onClick={addDomain}
        >
          Add Domain
        </PrimaryButton>
      </Dialog>

      <ConfirmDialog
        open={!!domainToRemove}
        onClose={() => setDomainToRemove(undefined)}
        title="Confirm domain removal ?"
        text="By removing this domain, any associated API requests will no longer be accepted. Please confirm your decision."
        submitBtnName="Yes"
        onSubmit={removeDomain}
      />
    </div>
  )
}

export default UserDomains
