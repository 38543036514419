import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { getRandomColor } from '../../utils/CommonFunctions'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip)

const CountChart = ({
  data,
  visibleDatasets
}: {
  data: Record<string, Record<string, number>>
  visibleDatasets?: Record<string, boolean>
}): JSX.Element => {
  if (!Object.keys(data).length) return <></>

  let maxValue = 0

  const labels = Object.keys(data[Object.keys(data)[0]])
  const chartData = {
    labels,
    datasets: Object.keys(data).map((k, idx) => {
      let show = false
      const values = Object.values(data[k])
      if (
        visibleDatasets &&
        Object.prototype.hasOwnProperty.call(visibleDatasets, k)
      ) {
        show = !visibleDatasets[k]
        const datasetMaxValue = Math.max(...values)
        maxValue = datasetMaxValue > maxValue ? datasetMaxValue : maxValue
      }
      return {
        label: 'Scan count',
        data: values,
        borderColor: getRandomColor(idx),
        cubicInterpolationMode: 'monotone' as const,
        hidden: show
      }
    })
  }
  const options = {
    responsive: true,
    scales: {
      x: {
        reverse: true
      },
      y: {
        min: 0,
        max: Math.ceil(maxValue * 1.2),
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      tooltip: {
        mode: 'index' as const,
        intersect: false
      }
    }
  }
  return <Line options={options} data={chartData} />
}

export default CountChart
