import React, { useEffect, useState } from 'react'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg'
import { ReactComponent as CircleCheck } from '../../assets/images/check-circle.svg'
import './SelectInput.css'

export interface SelectListItem {
  id: string
  label: string
  labelPrefix?: string | JSX.Element
  disable?: boolean
}

interface SelectInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  selectList: SelectListItem[]
  selected?: string
  onListItemClick: (event: any) => void
  width?: string
  bordered?: boolean
  showClearButton?: boolean
}

let counter = 0;

function SelectInput({
  label,
  selectList,
  selected,
  onListItemClick,
  width,
  style,
  placeholder,
  bordered,
  showClearButton,
  ...props
}: SelectInputProps): JSX.Element {
  const [filteredList, setFilteredList] = useState(selectList)
  const [selectedItem, setSelectedItem] = useState<any>()
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const [inputId] = useState(() => {
    counter++;
    return `select-input-${counter}`;
  }); // To have unique html id

  const handleOutsideClick = (e: any): void => {
    const dropdownElement = document.getElementById(inputId)
    if (dropdownElement && !dropdownElement.contains(e.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleOutsideClick)
    } else {
      document.removeEventListener('click', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [open])

  const searchInList = (e: any): void => {
    if (
      typeof selectedItem !== 'undefined' &&
      !selectList.filter((item) => item.label === e.target.value).length
    ) {
      // If item is selected but list is updated and the item is no longer present in list
      clear()
    }
    setFilteredList(
      selectList.filter((item) =>
        item.label
          .toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase())
      )
    )
    setSearchValue(e.target.value)
  }

  const handleListItemClick = (id: string): void => {
    const clickedItem = selectList.filter((listItem) => listItem.id === id)[0]
    setSelectedItem(clickedItem)
    setSearchValue(clickedItem.label)
    setOpen(false)
    onListItemClick(clickedItem.id)
  }

  useEffect(() => {
    setFilteredList(selectList)
  }, [selectList])

  useEffect(() => {
    const selectedItemObj = selectList.filter(
      (listItem) => listItem.id === selected
    )[0]
    setSelectedItem(selectedItemObj)
    setSearchValue(
      typeof selectedItemObj?.label !== 'undefined' ? selectedItemObj.label : ''
    )
  }, [selected, selectList]) // updated selected item in case `selected` or `selectList` is updated

  const clear = (e?: any): void => {
    if (e) {
      // If called from jsx/html
      e.stopPropagation()
      setOpen(false)
    }
    setSearchValue('')
    setSelectedItem(null)
    onListItemClick(null)
  }

  return (
    <div
      className={`inputContainer ${props.className ?? ''}`}
      style={{ ...style, width: width ?? '' }}
    >
      {label ? (
        <label
          htmlFor={`custom-input-${label.toLocaleLowerCase()}`}
          className="inputLabel"
        >
          {label}
        </label>
      ) : null}
      <div
        className={`inputSubContainer ${bordered ? 'bordered' : ''}`}
        onClick={() => (!props.disabled ? setOpen(true) : null)}
        onFocus={() => (!props.disabled ? setOpen(true) : null)}
        onBlur={() => (!props.disabled ? setOpen(false) : null)}
      >
        <input
          {...props}
          style={{}}
          id={inputId}
          type="text"
          autoComplete="off"
          className={`customSelectInput ${bordered ? 'borderedInput' : ''} ${
            showClearButton ? 'clearButtonInput' : ''
          }`}
          value={searchValue}
          onChange={searchInList}
          placeholder={placeholder}
        />
        <ChevronDown className="cDown" />
        {searchValue && showClearButton ? (
          <XIcon className="xIcon" onClick={(e: any) => clear(e)} />
        ) : null}
      </div>
      {open ? (
        <ul className={'optionsContainer'}>
          {Array.isArray(filteredList) && filteredList.length > 0 ? (
            filteredList.map((listItem, idx) => (
              <li
                key={idx}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleListItemClick(listItem.id)}
                className={listItem.disable ? 'liDisable' : ''}
              >
                {listItem.labelPrefix || null}
                {listItem.label}
                {selectedItem?.id === listItem.id ? <CircleCheck className='checkIcon'/> : null}
              </li>
            ))
          ) : (
            <div className={'emptyList'}>No items found.</div>
          )}
        </ul>
      ) : null}
    </div>
  )
}

export default SelectInput
