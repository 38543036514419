import React from 'react'
import styled from 'styled-components'
import LogoImage from '../../assets/images/ER_logo_2_min.png'
import { ReactComponent as UserIcon } from '../../assets/images/user.svg'
import { ReactComponent as RepeatIcon } from '../../assets/images/repeat.svg'
import { ReactComponent as SettingIcon } from '../../assets/images/settings.svg'
import { ReactComponent as DashboardIcon } from '../../assets/images/grid.svg'
import { ReactComponent as LogoutIcon } from '../../assets/images/log-out.svg'
import DropdownButton, {
  BasicButton
} from '../../common/elements/DropdownButton'
import './Navbar.css'
import { MaterialButton } from '../../common/elements/CommonElements'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../common/context/Context'
import { removeCookies } from '../../utils/Cookies'

const BaseContainer = styled.div``

const LogoContainer = styled(BaseContainer)`
  cursor: pointer;
  width: max-content;
`

const Navbar = ({ height }: { height: string }): JSX.Element => {
  const navigate = useNavigate()
  const { isUserLoggedIn, dispatch } = useAuth()

  // const ProductList = [
  //   { label: 'QR generator', id: 'qr', url: '/qr/generator' },
  //   {
  //     label: 'Wikipedia scraper',
  //     id: 'wikiScraper',
  //     url: '/scrapers/wikipedia'
  //   }
  // ]

  const ProfileList = [
    {
      label: 'Dashboard',
      id: 'db',
      url: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      label: 'Transections',
      id: 'ts',
      url: '/transactions',
      icon: <RepeatIcon />
    },
    // {
    //   label: 'Help & support',
    //   id: 'hns',
    //   url: '/support',
    //   icon: <UserIcon />
    // },
    {
      label: 'Account settings',
      id: 'ac',
      url: '/settings',
      icon: <SettingIcon />
    },
    {
      label: 'Log out',
      id: 'logout',
      url: '/',
      icon: <LogoutIcon />
    }
  ]

  // const handleProductClick = (id: string): void => {
  //   const product = ProductList.filter((p) => p.id === id)[0]
  //   navigate(product.url)
  // }

  const handleLogout = (): void => {
    removeCookies('erjwt')
    sessionStorage.removeItem('erjwt')
    localStorage.removeItem('erjwt')
    removeCookies('refreshjwt')
    sessionStorage.removeItem('refreshjwt')
    localStorage.removeItem('refreshjwt')
    removeCookies('user')
    sessionStorage.removeItem('user')
    localStorage.removeItem('user')
    localStorage.removeItem('img')
    sessionStorage.removeItem('img')
    dispatch({ type: 'SIGNOUT' })
    navigate('/')
  }

  const handleProfileClick = (id: string): void => {
    if (id === 'logout') return handleLogout()
    const profileItem = ProfileList.filter((p) => p.id === id)[0]
    navigate(profileItem.url)
  }

  return (
    <nav className="navbar" id="mainNavbarContainer" style={{ height }}>
      <div className="nbSectionContainer">
        <div className='logoContainerHolder'>
          <LogoContainer onClick={() => navigate('/')}>
            <img
              src={LogoImage}
              style={{
                width: height === '100px' ? '300px' : '235px',
                transition: 'all 0.3s ease-in-out',
                verticalAlign: 'top'
              }}
            />
          </LogoContainer>
        </div>
        <div style={{ textAlign: 'center' }}>
          <BaseContainer style={{ minWidth: '318px' }}>
            {/* <DropdownButton
              label="Products"
              list={ProductList}
              onItemClick={(id) => handleProductClick(id)}
              className="dropdownButton"
            /> */}
            <BasicButton
              onClick={() => navigate('/qr/generator')}
              style={{ margin: '0 10px' }}
            >
              QR Generator
            </BasicButton>
            <BasicButton
              onClick={() => navigate('/about-us')}
              style={{ margin: '0 10px' }}
            >
              About us
            </BasicButton>
            <BasicButton
              onClick={() => navigate('/support')}
              style={{ margin: '0 10px' }}
            >
              Support
            </BasicButton>
          </BaseContainer>
        </div>
        <div style={{ textAlign: 'right' }}>
          <BaseContainer>
            {!isUserLoggedIn ? (
              <>
                <MaterialButton onClick={() => navigate('/login')}>
                  Login
                </MaterialButton>
                <MaterialButton onClick={() => navigate('/register')}>
                  Sign up
                </MaterialButton>
              </>
            ) : (
              <div style={{ paddingRight: '20px' }}>
                <DropdownButton
                  icon={<UserIcon />}
                  list={ProfileList}
                  onItemClick={(id) => handleProfileClick(id)}
                  className="profileDropdownButton"
                />
              </div>
            )}
          </BaseContainer>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
