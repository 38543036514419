import React, { useEffect, useState } from 'react'
import { useAuth, verifyToken } from '../../../common/context/Context'
import { Checkbox, SwitchButton } from '../../../common/elements/InputControls'
import { api, updateHeaders } from '../../../utils/AxiosConfig'
import Loader from '../../../common/misc/loader/Loader'
import Snackbar, {
  type SnackbarBaseProps
} from '../../../common/elements/Snackbar'
import { emptySnackbarData } from '../../dashboard/Dashboard'
import Dialog from '../../../common/elements/Dialog'
import CopyText from '../../../common/elements/CopyText'
import {
  PrimaryButton,
  SecondaryButton
} from '../../../common/elements/CommonElements'
import { ReactComponent as DownloadImage } from '../../../assets/images/download.svg'
import { getCookies, setCookies } from '../../../utils/Cookies'
import Input from '../../../common/elements/Input'
import { IconButton } from '../../../common/elements/Buttons'
import styles from './Security.module.css';

interface RecoveryData {
  recoveryCode: string
  qrCodeString: string
  verificationCode: string
}

const Security = (): JSX.Element => {
  const { user, dispatch } = useAuth()

  const [isLoading, setIsLoading] = useState(false)

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [mfaData, setMFAData] = useState<RecoveryData>()
  const [copiedRecoveryCodeCheckBox, setCopiedRecoveryCodeCheckBox] =
    useState(false)
  const [copiedRecoveryCode, setCopiedRecoveryCode] = useState(false)
  const [showVerificationCode, setShowVerificationCode] = useState(false)

  const [isDisabling, setIsDisabling] = useState(false)
  const [recoveryCode, setRecoveryCode] = useState('')

  const [needMFAVerifiedOnce, setNeedMFAVerifiedOnce] = useState(false)
  const [verificationToken, setVerificationToken] = useState('')

  const [shouldRegenerateRecovery, setShouldRegenerateRecovery] =
    useState(false)
  const [newRecoveryCode, setNewRecoveryCode] = useState('')

  const handleMFAChange = async (enable = true): Promise<void> => {
    setIsLoading(true)
    const hasRefreshToken =
      !!getCookies('refreshjwt') ||
      !!sessionStorage.getItem('refreshjwt') ||
      !!localStorage.getItem('refreshjwt')
    try {
      let result
      if (user?.hasSecret && !enable) {
        // Disable 2FA
        result = await api.put('/api/mfa/disable', {
          hasRefreshToken,
          recoveryCode
        })
        setIsDisabling(false)
        setRecoveryCode('')
      } else if (!user?.hasSecret && enable) {
        // Enable 2FA
        result = await api.put('/api/mfa/enable', { hasRefreshToken })
        setMFAData(result.data)
      }
      if (result?.data.token) {
        if (getCookies('user') && getCookies('erjwt')) {
          setCookies('erjwt', result?.data.token)
          setCookies('refreshjwt', result?.data.refreshToken)
        } else if (
          sessionStorage.getItem('user') &&
          sessionStorage.getItem('erjwt')
        ) {
          sessionStorage.setItem('erjwt', result?.data.token)
          sessionStorage.setItem('refreshjwt', result?.data.refreshToken) // Todo - High - this should be saved in secure HTTP cookies
        }
        updateHeaders()
        await verifyToken(dispatch)
      }
    } catch (err) {
      console.error('Error occurred while changing 2FA', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while changing 2FA'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleFirstMFAVerification = async (): Promise<void> => {
    setIsLoading(true)
    const hasRefreshToken =
      !!getCookies('refreshjwt') ||
      !!sessionStorage.getItem('refreshjwt') ||
      !!localStorage.getItem('refreshjwt')
    try {
      const result = await api.patch(
        '/api/mfa/first-verify',
        { hasRefreshToken },
        {
          headers: {
            'mfa-token': verificationToken
          }
        }
      )
      if (result?.data.token) {
        if (getCookies('user') && getCookies('erjwt')) {
          setCookies('erjwt', result?.data.token)
          setCookies('refreshjwt', result?.data.refreshToken)
        } else if (
          sessionStorage.getItem('user') &&
          sessionStorage.getItem('erjwt')
        ) {
          sessionStorage.setItem('erjwt', result?.data.token)
          sessionStorage.setItem('refreshjwt', result?.data.refreshToken) // Todo - High - this should be saved in secure HTTP cookies
        }
        updateHeaders()
        await verifyToken(dispatch)
      }
      setVerificationToken('')
      setNeedMFAVerifiedOnce(false)
    } catch (err) {
      console.error('Error occurred while verifying 2FA token', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while verifying 2FA token'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const regenerateRecoveryCode = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const result = await api.patch('/api/mfa/recovery', undefined, {
        headers: {
          'mfa-token': verificationToken
        }
      })
      setNewRecoveryCode(result?.data)
    } catch (err) {
      console.error('Error occurred while regenerating recovery code', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while regenerating recovery code'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const downloadRecoveryCode = (recoveryString: string): void => {
    if (!recoveryString) return
    const element = document.createElement('a')
    const file = new Blob([recoveryString], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = 'recovery.txt'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  useEffect(() => {
    if (verificationToken?.length === 6) {
      if (shouldRegenerateRecovery) regenerateRecoveryCode()
      else handleFirstMFAVerification()
    }
  }, [verificationToken])

  const renderRecoveryCodeDialog = (newRecovery = false): JSX.Element => {
    const dialogRecoveryCode = newRecovery
      ? newRecoveryCode
      : mfaData?.recoveryCode

    return (
      <div>
        <h2 className={styles['security-dialog-heading']}>Secure Your Recovery Code.</h2>
        <p>
          You will need the recovery code to deactivate your two-factor security
          or if you ever need to login without your device. Copy the recovery
          code to a safe place or download the .txt file. You can regenerate the
          recovery code whenever you want or if you believe that your old
          recovery code is compromised.
        </p>
        <br />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'calc(100% - 64px) 64px'
          }}
        >
          <CopyText text={dialogRecoveryCode as string} />
          <DownloadImage
            style={{
              padding: '20px',
              stroke: 'var(--er-primary)',
              cursor: 'pointer'
            }}
            onClick={() => downloadRecoveryCode(dialogRecoveryCode as string)}
          />
        </div>
        <br />
        <Checkbox
          label="I have safely recorded this code"
          style={{ width: '100%' }}
          checked={copiedRecoveryCodeCheckBox}
          onChange={(e) => setCopiedRecoveryCodeCheckBox(e.target.checked)}
        />
        <br />
        <br />
        <div style={{ textAlign: 'center' }}>
          <PrimaryButton
            disabled={!copiedRecoveryCodeCheckBox}
            onClick={() => {
              if (newRecovery) {
                setCopiedRecoveryCodeCheckBox(false)
                setShouldRegenerateRecovery(false)
                setNewRecoveryCode('')
                setVerificationToken('')
              } else setCopiedRecoveryCode(true)
            }}
          >
            Continue
          </PrimaryButton>
        </div>
      </div>
    )
  }

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <h4>Two-factor authentication</h4>
      {user?.hasSecret ? (
        <>
          <SwitchButton
            label="Disable 2FA"
            checked={user?.hasSecret}
            onChange={() => setIsDisabling(true)}
          />
          <br />
          <br />
          <br />
          <IconButton
            icon={<DownloadImage />}
            onClick={() => setShouldRegenerateRecovery(true)}
          >
            Generate New Recovery Code
          </IconButton>
        </>
      ) : (
        <SwitchButton
          label="Enable 2FA"
          checked={user?.hasSecret || false}
          onChange={async () => await handleMFAChange()}
        />
      )}
      <Dialog // Enable dialog
        open={!!mfaData}
        onClose={() => {
          setMFAData(undefined)
          setCopiedRecoveryCodeCheckBox(false)
          setCopiedRecoveryCode(false)
          setShowVerificationCode(true)
        }}
        style={{ maxWidth: '600px', textAlign: 'left' }}
      >
        {!copiedRecoveryCode ? (
          renderRecoveryCodeDialog()
        ) : (
          <div>
            <h2 className={styles['security-dialog-heading']}>
              Enable two-factor authentication
            </h2>
            <p>
              Scan the QR code below with an authentication app, such as Google
              Authenticator or Authy, on your phone
            </p>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              <img src={mfaData?.qrCodeString} alt="Recovery QR" />
            </div>
            <div style={{ textAlign: 'center' }}>
              Can&apos;t scan the QR code ?
              <br />
              <br />
              Type the code created for the authentication app.
              <br />
              <br />
              {!showVerificationCode ? (
                <SecondaryButton onClick={() => setShowVerificationCode(true)}>
                  Show verification code
                </SecondaryButton>
              ) : (
                <CopyText text={mfaData?.verificationCode as string} />
              )}
              <br />
              <br />
              <div style={{ textAlign: 'center' }}>
                <PrimaryButton
                  onClick={() => {
                    setMFAData(undefined)
                    setCopiedRecoveryCode(false)
                    setCopiedRecoveryCodeCheckBox(false)
                    setShowVerificationCode(false)
                    setNeedMFAVerifiedOnce(true)
                  }}
                >
                  Continue
                </PrimaryButton>
              </div>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog // first verify dialog
        open={needMFAVerifiedOnce}
        onClose={() => {
          setNeedMFAVerifiedOnce(false)
          setVerificationToken('')
        }}
        style={{ maxWidth: '600px', textAlign: 'left' }}
        heading="Enabling two-factor authentication"
      >
        <div>
          <p>
            To enable two-factor authentication (2FA), please enter the
            generated token in the authenticator app that you have just used to
            scan the 2FA QR code. This will complete the setup process and
            ensure your account is securely protected.
          </p>
          <br />
          <br />
          <br />
          <Input
            label="Token"
            value={verificationToken}
            onChange={(e) => setVerificationToken(e.target.value)}
          />
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <PrimaryButton
              onClick={handleFirstMFAVerification}
              disabled={verificationToken.length !== 6}
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
      </Dialog>
      <Dialog // recovery dialog
        open={shouldRegenerateRecovery}
        onClose={() => {
          setShouldRegenerateRecovery(false)
          setVerificationToken('')
          setNewRecoveryCode('')
          setCopiedRecoveryCodeCheckBox(false)
        }}
        style={{ maxWidth: '600px', textAlign: 'left' }}
      >
        {newRecoveryCode ? (
          renderRecoveryCodeDialog(true)
        ) : (
          <div>
            <h2 className={styles['security-dialog-heading']}>
              Regenerating your recovery code
            </h2>
            To regenerate your recovery code, please enter the 2FA token from
            your authenticator app. This ensures the security of your account
            during the recovery code regeneration process.
            <br />
            <br />
            <br />
            <Input
              label="Token"
              value={verificationToken}
              onChange={(e) => setVerificationToken(e.target.value)}
            />
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
              <PrimaryButton
                onClick={regenerateRecoveryCode}
                disabled={verificationToken.length !== 6}
              >
                Submit
              </PrimaryButton>
            </div>
          </div>
        )}
      </Dialog>
      <Dialog // Disable dialog
        open={isDisabling}
        onClose={() => {
          setIsDisabling(false)
          setRecoveryCode('')
        }}
        style={{ maxWidth: '600px', textAlign: 'left' }}
        heading="Disabling two-factor authentication"
      >
        <div>
          <p>
            Please enter the recovery code you received when you setup your 2FA
          </p>
          <br />
          <Input
            label="Recovery code"
            value={recoveryCode}
            onChange={(e) => setRecoveryCode(e.target.value)}
          />
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <PrimaryButton
              onClick={async () => await handleMFAChange(false)}
              disabled={!recoveryCode}
            >
              Continue
            </PrimaryButton>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default Security
