import ColorPicker from 'react-best-gradient-color-picker'
import { PrimaryButton, SecondaryButton } from './CommonElements'
import React, { useEffect, useState } from 'react'
import './Buttons.css'
// import rgbHex from 'rgb-hex'

interface PrimayIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element
  buttonType?: 'primary' | 'secondary'
}

const IconButton = ({
  icon,
  children,
  style,
  buttonType = 'primary',
  ...props
}: PrimayIconProps): JSX.Element => {
  const IconButton = buttonType === 'primary' ? PrimaryButton : SecondaryButton
  return (
    <IconButton {...props} style={{ padding: '12px 16px', ...style }}>
      <div style={{ verticalAlign: 'middle', display: 'inline-block' }}>
        {icon || null}
      </div>
      {children ? (
        <div
          style={{
            verticalAlign: 'middle',
            display: 'inline-block',
            marginLeft: '12px'
          }}
        >
          {children}
        </div>
      ) : null}
    </IconButton>
  )
}

interface ColorSelectProps extends React.HTMLAttributes<HTMLDivElement> {
  selectedColor?: string
  icon?: JSX.Element
  label: string
  onColorChange: (clr: string) => void
}

let counter = 0

const ColorSelectButton = ({
  selectedColor = '#fff',
  label,
  icon,
  onClick,
  onColorChange,
  children,
  ...props
}: ColorSelectProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [buttonId] = useState(() => {
    counter++
    return `color-select-${counter}`
  })
  const [labelWidth, setLabelWidth] = useState<number | undefined>(0)

  const handleDivClick = (e: any): void => {
    setOpen(true)
    if (onClick) onClick(e)
  }
  let formattedColor = ''

  if (selectedColor.trim().toLowerCase().startsWith('rgba'))
    formattedColor = '#' // + rgbHex(selectedColor)
  else
    formattedColor = `Gradient: ${
      selectedColor.trim().toLowerCase().startsWith('linear')
        ? `Linear - ${
            selectedColor.trim().toLowerCase().split(',')[0].split('(')[1]
          }`
        : 'Radical'
    }`

  useEffect(() => {
    const handleClickOutside = (event: any): void => {
      const buttonElement = document.getElementById(buttonId)
      const buttonElementCP = document.getElementById(`cp-${buttonId}`)
      if (
        buttonElement &&
        !buttonElement.contains(event.target) &&
        event.target.id !== buttonId &&
        event.target?.contains(buttonElementCP)
      ) {
        setOpen(false)
      }
    }

    const labelElement = document.getElementById(`label-${buttonId}`)
    console.log(
      'labelElement',
      labelElement?.clientWidth,
      labelElement?.scrollWidth
    )
    setLabelWidth(labelElement?.clientWidth)

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  console.log('labelWidth', labelWidth)

  return (
    <div {...props} style={{ position: 'relative', ...props.style }}>
      <div id={`label-${buttonId}`} style={{ display: 'inline-block' }}>
        {label}
      </div>
      <div
        onClick={handleDivClick}
        className="colorButton"
        id={buttonId}
        style={{ borderColor: selectedColor }}
      >
        <div style={{ background: selectedColor }} className="colorBlock"></div>
        <span>{formattedColor}</span>
      </div>
      {open ? (
        <div
          id={`cp-${buttonId}`}
          className="colorPickerContainer"
          style={{ left: `${(labelWidth || 0) + 10}px` }}
        >
          <ColorPicker
            value={selectedColor}
            onChange={(color: string) => {
              onColorChange(color)
            }}
            className={''}
          />
        </div>
      ) : null}
    </div>
  )
}

export { IconButton, ColorSelectButton }
