import './Input.css'
import React from 'react'
import { ReactComponent as AlertCircle } from '../../assets/images/alert-circle.svg'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: string
}

export default function Input({
  label,
  error,
  style,
  className,
  ...props
}: InputProps): JSX.Element {
  return (
    <div
      className={`col-3 input-effect ${error ? 'error' : ''} ${
        className ?? ''
      }`}
      style={style}
    >
      <input
        className="customInput effect-16"
        type="text"
        placeholder=""
        pattern=".*\S+.*"
        required
        {...props}
      />
      <label>{label}</label>
      <span className="focus-border"></span>
      {error ? (
        <>
          <AlertCircle />
          <span>{error}</span>
        </>
      ) : null}
    </div>
  )
}

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  error?: string
  resize?: 'horizontal' | 'vertical' | 'none'
}

export const TextArea = ({
  label,
  error,
  style,
  className,
  resize,
  rows = 3,
  ...props
}: TextAreaProps): JSX.Element => {
  return (
    <div
      className={`col-3 input-effect ${error ? 'error' : ''} ${
        className ?? ''
      }`}
      style={style}
    >
      <textarea
        className="customInput effect-16"
        placeholder=""
        required
        style={resize ? { resize } : undefined}
        rows={rows}
        {...props}
      />
      <label>{label}</label>
      <span className="focus-border" style={{ bottom: '4px' }}></span>
      {error ? (
        <>
          <AlertCircle />
          <span>{error}</span>
        </>
      ) : null}
    </div>
  )
}
