import React, { createContext, useContext, useReducer, useEffect } from 'react'
import { api, updateHeaders } from '../../utils/AxiosConfig'
import { getCookies, removeCookies, setCookies } from '../../utils/Cookies'
import { SERVER_URL } from '../../Config'
import { type User } from '../types/Types'

export const UserContext = createContext<any>(null)

interface UserContextOutput {
  state: {
    user: User
    isUserLoggedIn: boolean
  }
  user: User
  isUserLoggedIn: boolean
  uid: string
  dispatch: (args: any) => void
}

export const UserContextProvider = ({ children }: { children: any }): any => {
  const userReducer = (state: any, action: any): any => {
    switch (action.type) {
      case 'USER':
        return { isUserLoggedIn: true, user: action.payload }
      case 'VERIFY':
        return {
          isUserLoggedIn: true,
          user: { ...(state?.user || {}), ...action.payload }
        }
      case 'SIGNOUT':
        return { isUserLoggedIn: false, user: {} }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(
    userReducer,
    (sessionStorage.getItem('erjwt') && sessionStorage.getItem('user')) ||
      (getCookies('user') && getCookies('erjwt'))
      ? {
          isUserLoggedIn: true,
          user: JSON.parse(sessionStorage.getItem('user') as string)
        }
      : {
          isUserLoggedIn: false,
          user: {}
        }
  )

  useEffect(() => {
    verifyToken(dispatch)
  }, [])

  const response: UserContextOutput = {
    state,
    user: state?.user || {},
    isUserLoggedIn: state?.isUserLoggedIn || false,
    uid: state?._id,
    dispatch
  }

  return (
    <UserContext.Provider value={response}>{children}</UserContext.Provider>
  )
}

export const verifyToken = async (
  dispatch: (data: any) => void
): Promise<void> => {
  if (!getCookies('erjwt') && !sessionStorage.getItem('erjwt')) {
    return dispatch({ type: 'SIGNOUT' })
  }
  try {
    const user = await api.get(SERVER_URL + '/api/auth/verify')
    const { token, ...userData } = user.data
    if (getCookies('user') && getCookies('erjwt')) {
      setCookies('user', JSON.stringify(userData))
      if (token) setCookies('erjwt', token)
    } else {
      sessionStorage.setItem('user', JSON.stringify(userData))
      if (token) sessionStorage.setItem('erjwt', token)
    }
    updateHeaders()
    dispatch({ type: 'VERIFY', payload: userData })
  } catch (e) {
    removeCookies('erjwt')
    removeCookies('user')
    removeCookies('refreshjwt')
    localStorage.removeItem('img')
    sessionStorage.removeItem('erjwt')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('img')
    sessionStorage.removeItem('refreshjwt')
    dispatch({ type: 'SIGNOUT' })
  }
}

export const useAuth = (): UserContextOutput => {
  const context = useContext(UserContext)
  if (!context) return {
    state: {} as any,
    uid: undefined as any,
    user: {} as any,
    isUserLoggedIn: false,
    dispatch: () => {}
  }
  return context
}
