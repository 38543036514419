const isValidURL = (url: string): boolean => {
  // Regular expression pattern to match URLs
  const pattern = new RegExp(
    '^((https?|ftp)://)' +
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(:(\\d+))?' +
      '(\\/[-a-zA-Z\\d%@_.~+&:]*)*' +
      '(\\?[;&a-zA-Z\\d%@_.,~+&:=-]*)?' +
      '(\\#[-a-zA-Z\\d_]*)?$'
  )

  // Test the URL against the pattern
  return pattern.test(url)
}

async function fileToBase64(file: File): Promise<string> {
  if (!file) throw new Error('Error reading the file.')
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      resolve(reader?.result as string)
    }

    reader.onerror = () => {
      reject(new Error('Error reading the file.'))
    }

    reader.readAsDataURL(file)
  })
}

const base64toBlob = (base64Data: string): Blob => {
  const sliceSize = 512;
  const byteCharacters = atob(base64Data.replace(/^data:image\/(png|jpeg);base64,/, ''));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'image/png' });
};

function getHigherPlan(
  plan1: string | undefined,
  plan2: string | undefined
): string {
  if (!plan1 && !plan2) return ''
  if (!plan1) return plan2 ?? ''
  if (!plan2) return plan1
  const a1 = plan1.match(/\d+$/)
  const a2 = plan2.match(/\d+$/)
  const number1 = parseInt(Array.isArray(a1) ? a1[0] : '')
  const number2 = parseInt(Array.isArray(a2) ? a2[0] : '')
  if (number1 > number2) {
    return plan1
  } else if (number2 > number1) {
    return plan2
  } else {
    return 'Both plans are same'
  }
}

function formatDate(date: Date | string | number): string | null {
  if (!date) return null
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  if (typeof date === 'string' || typeof date === 'number')
    date = new Date(date)
  const day = String(date.getDate()).padStart(2, '0') // Ensure two digits for day
  const month = months[date.getMonth()]
  const year = String(date.getFullYear()).slice(-2) // Get the last two digits of the year

  return `${day}-${month}-${year}`
}

function getRandomColor(idx?: number): string {
  const colors = [
    'red',
    'green',
    'blue',
    'yellow',
    'pink',
    'purple',
    'voilet',
    'magenta',
    'cyan',
    'grey',
    'silver',
    'gold'
  ]
  if (typeof idx !== 'undefined' && idx < colors.length) {
    return colors[idx]
  }
  return colors[Math.floor(Math.random() * colors.length)]
}

const formatAmountToUSD = (amount: number | string): string => {
  amount = parseFloat(amount as string)
  // Check if the input is a valid number
  if (isNaN(amount)) {
    console.error('Invalid input. Please provide a valid number.')
    return ''
  }

  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = amount.toFixed(2).toString().split('.')

  // Add commas to the integer part for thousand separators
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Combine the formatted integer part with the decimal part and append USD symbol
  const formattedAmount = `$${formattedIntegerPart}.${decimalPart}`

  return formattedAmount
}

function camelCaseToSentence(camelCaseString: string): string {
  // Add a space before each capital letter
  const sentence = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Convert to lowercase and capitalize the first letter
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
}

function isBase64(str: string): boolean {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/promise-function-async
function wait(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export {
  isValidURL,
  fileToBase64,
  isBase64,
  base64toBlob,
  getHigherPlan,
  formatDate,
  getRandomColor,
  formatAmountToUSD,
  camelCaseToSentence,
  wait
}
