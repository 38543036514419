import React, { useEffect, useState } from 'react'
import {
  CLIENT_SITE_NAME,
  ENV,
  SERVER_URL
} from '../../Config'
import { api, updateHeaders } from '../../utils/AxiosConfig'
import { useLocation, useNavigate } from 'react-router-dom'
import { Validate } from '../../utils/Validation'
import { useAuth } from '../../common/context/Context'
import LoggedIn from '../../common/loggedIn/LoggedIn'
import { type LoginErrors, type LoginFormData } from '../login/Login'
import {
  PrimaryButton,
  SecondaryButton
} from '../../common/elements/CommonElements'
import Input from '../../common/elements/Input'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import Loader from '../../common/misc/loader/Loader'
import GoogleLoginButton from '../login/GoogleLogin'
import { type CountryData, type User } from '../../common/types/Types'
import loginNRegImg from '../../assets/images/login-n-reg.png'
import './Register.css'
import SelectInput from '../../common/elements/SelectInput'
import countriesData from '../../common/countries.json'
import IconsBG from '../../assets/images/icons-bg.png'
import { Checkbox } from '../../common/elements/InputControls'
import { getCookies, setCookies } from '../../utils/Cookies'
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

interface RegisterFormData {
  name: string
  confirmPassword: string
}

export interface RegisterErrors {
  nameValue?: string
  nameMessage?: string
  confirmPasswordValue?: string
  confirmPasswordMessage?: string
}

const Register = (): JSX.Element => {
  const [formData, setFormData] = useState<LoginFormData & RegisterFormData>({
    email: '',
    password: '',
    confirmPassword: '',
    name: ''
  })
  const [errors, setErrors] = useState<LoginErrors & RegisterErrors>({})
  const { isUserLoggedIn, dispatch } = useAuth()

  const countryList = countriesData as CountryData[]
  const [selectedCountry, setSelectedCountry] = useState('US')

  const navigate = useNavigate()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [emailUpdatesOptIn, setEmailUpdatesOptIn] = useState(false)
  const [showEmailOptInPopUp, setShowEmailOptInPopUp] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const checkAndSet = (key: string, value: string): void => {
    setFormData({ ...formData, [key]: value })
    setErrors(Validate(errors, formData, key, value))
  }

  const registerUser = async (): Promise<void> => {
    const recaptchaToken =
      ENV !== 'local' ? await executeRecaptcha?.('account_creation') : ''
    console.log('recaptchaToken', recaptchaToken)
    setIsLoading(true)
    if (
      !formData.email ||
      !formData.password ||
      !formData.confirmPassword ||
      !selectedCountry
    ) {
      return console.error('Invalid form data')
    }
    try {
      const user = await api.post(SERVER_URL + '/api/auth/register', {
        ...formData,
        country: selectedCountry,
        emailUpdatesOptIn,
        recaptchaToken
      })
      handleSuccessfulLogin(user.data, false)
    } catch (e: any) {
      if (e?.response?.status === 409) {
        return setSnackbarData({ type: 'error', message: e?.response?.data })
      }
      console.error('Error occurred ', e.message)
      setSnackbarData({ type: 'error', message: 'Something went wrong while signing up. Please try again later or contact support.' })
    } finally {
      setIsLoading(false)
    }
  }

  const handleSuccessfulLogin = (user: User, fromGoogle = true): void => {
    const filteredUserData = { ...user }
    delete filteredUserData.token
    sessionStorage.setItem('erjwt', user.token as string)
    sessionStorage.setItem('user', JSON.stringify(filteredUserData))
    updateHeaders()
    if (fromGoogle && (user as any).isNewUser) {
      // Don't dispatch here
      setEmailUpdatesOptIn(false)
      return setShowEmailOptInPopUp(true)
    }
    dispatch({ type: 'USER', payload: user })
    if (fromGoogle) {
      if (location.state?.from) {
        navigate(location.state.from, {
          state: location.state
        })
      } else {
        navigate('/', {
          state: location.state
        })
      }
    } else
      navigate('/verify-email', {
        state: { ...location.state, email: user.email }
      })
  }

  const handleOptInUpdate = async (): Promise<void> => {
    // profile update code
    setIsLoading(true)
    try {
      const user = await api.patch('/api/profile', { emailUpdatesOptIn })
      const filteredUserData = { ...user.data }
      delete filteredUserData.token

      dispatch({ type: 'USER', payload: user.data })
      if (getCookies('user') && getCookies('erjwt')) {
        setCookies('erjwt', user.data.token)
        setCookies('refreshjwt', user.data.refreshToken)
        setCookies('user', filteredUserData)
      } else if (
        sessionStorage.getItem('user') &&
        sessionStorage.getItem('erjwt')
      ) {
        sessionStorage.setItem('user', JSON.stringify(filteredUserData))
        sessionStorage.setItem('erjwt', user.data.token)
        sessionStorage.setItem('refreshjwt', user.data.refreshToken)
      }
      updateHeaders()
      setSnackbarData({
        type: 'success',
        message: 'Preference updated successfully'
      })
      if (location.state?.from) {
        navigate(location.state.from, {
          state: location.state
        })
      } else {
        navigate('/', {
          state: location.state
        })
      }
    } catch (e: any) {
      console.error('Error occurred while updating your preference.', e.message)
      setSnackbarData({ type: 'error', message: e.message })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    navigator.geolocation?.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        fetch(`https://geocode.xyz/${latitude},${longitude}?geoit=json`)
          // eslint-disable-next-line @typescript-eslint/promise-function-async
          .then((response) => response.json())
          .then((data) => {
            console.log('data', data)
            if (data.region !== 'Throttled! See geocode.xyz/pricing')
              setSelectedCountry(data.region)
          })
          .catch((error) => console.error(error))
      },
      (error) => {
        console.error(error)
      }
    )
  }, [])

  return showEmailOptInPopUp ? (
    <div style={{ textAlign: 'center', paddingTop: '200px' }}>
      <Checkbox
        label={`I would like to receive emails from ${CLIENT_SITE_NAME} about product and feature updates.`}
        checked={emailUpdatesOptIn}
        onChange={(e) => setEmailUpdatesOptIn(e.target.checked)}
        style={{ width: '100%' }}
      />
      <br />
      <br />
      <br />
      <PrimaryButton onClick={handleOptInUpdate}>Continue</PrimaryButton>
    </div>
  ) : isUserLoggedIn && !showEmailOptInPopUp ? (
    <LoggedIn />
  ) : (
    <>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <div
        style={{
          background: 'antiqueWhite',
          minHeight: 'calc(100vh - 440px)',
          position: 'relative',
          backgroundImage: `url(${IconsBG as string})`,
          backgroundSize: '100% auto',
          backgroundRepeat: 'repeat-y'
        }}
      >
        <div
          style={{
            textAlign: 'center',
            width: '850px',
            padding: '100px 0px 100px calc(50% - 400px)'
          }}
        >
          <div
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              width: '42%',
              height: '740px',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
            }}
          >
            <img
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                borderRadius: '6px 0px 0px 6px'
              }}
              alt="Vector art"
              src={loginNRegImg}
            />
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '58%',
              minHeight: '600px',
              backgroundColor: 'white',
              verticalAlign: 'middle',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              borderRadius: '6px',
              position: 'relative'
            }}
          >
            <h2 style={{ padding: '10px' }}>Sign up</h2>
            <div style={{ marginLeft: '30px', marginRight: '30px' }}>
              <Input
                label="Name"
                style={{ margin: '40px 0px' }}
                onChange={(e) => {
                  checkAndSet('name', e.target.value)
                }}
                value={formData?.name}
                error={
                  typeof errors.nameValue !== 'undefined'
                    ? errors.nameMessage
                    : ''
                }
              />
              <Input
                label="Email"
                type="email"
                style={{ margin: '40px 0px' }}
                onChange={(e) => {
                  checkAndSet('email', e.target.value)
                }}
                value={formData?.email}
                error={
                  typeof errors.emailValue !== 'undefined'
                    ? errors.emailMessage
                    : ''
                }
              />
              <Input
                label="Password"
                type="password"
                style={{ margin: '40px 0px' }}
                onChange={(e) => {
                  checkAndSet('password', e.target.value)
                }}
                value={formData?.password}
                error={
                  typeof errors.passwordValue !== 'undefined'
                    ? errors.passwordMessage
                    : ''
                }
              />
              <Input
                label="Confirm password"
                type="password"
                style={{ margin: '40px 0px' }}
                onChange={(e) => {
                  checkAndSet('confirmPassword', e.target.value)
                }}
                value={formData?.confirmPassword}
                error={
                  typeof errors.confirmPasswordValue !== 'undefined'
                    ? errors.confirmPasswordMessage
                    : ''
                }
              />
              <div style={{ textAlign: 'left', margin: '40px 0px' }}>
                <SelectInput
                  label="Country"
                  selectList={countryList.map((c) => ({
                    id: c.cca2,
                    label: c.name.common,
                    labelPrefix: (
                      <img
                        style={{
                          width: '24px',
                          marginRight: '12px',
                          verticalAlign: 'middle',
                          borderRadius: '20%'
                        }}
                        src={c.flags.svg}
                      />
                    )
                  }))}
                  selected={selectedCountry}
                  onListItemClick={(countryId) => setSelectedCountry(countryId)}
                />
                <br />
                <br />
                <Checkbox
                  label={`I would like to receive emails from ${CLIENT_SITE_NAME} about product and feature updates.`}
                  checked={emailUpdatesOptIn}
                  onChange={(e) => setEmailUpdatesOptIn(e.target.checked)}
                />
              </div>
              <p>
                By creating an account, you agree to our{' '}
                <a href="/terms" target="_blank" rel="noreferrer">
                  Terms
                </a>{' '}
                and have read and acknowledged the{' '}
                <a href="/privacy-policy" target="_blank" rel="noreferrer">
                  Global Privacy Statement
                </a>
              </p>
              <br />
              <br />
            </div>
            <div style={{ paddingBottom: '40px' }}>
              <div>
                <PrimaryButton
                  onClick={registerUser}
                  disabled={
                    !!(
                      !formData?.name ||
                      !formData?.email ||
                      !formData?.password ||
                      !formData?.confirmPassword ||
                      errors.isNotValid
                    )
                  }
                >
                  Sign up
                </PrimaryButton>
                <span style={{ padding: '20px' }}> Or </span>
                <SecondaryButton onClick={() => navigate('/login')}>
                  Sign in
                </SecondaryButton>
              </div>
              <GoogleLoginButton
                buttonText="Sign up with Google"
                handleSuccessfulLogin={handleSuccessfulLogin}
                setIsLoading={setIsLoading}
                setSnackbarData={setSnackbarData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Register
