import React from 'react'
import PnfGif from '../../assets/images/pnf.gif'
import styled from 'styled-components'

const Container = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 110px)); */
  text-align: center;
  width: 60%;
  max-width: 1536px;
  margin: 10% 0;
  margin-left: 20%;

  & > div {
    width: calc(100% - 80px);
    margin-left: 40px;
    border-radius: 16px;
    background: var(--er-infoLight);
  }

  & > div > h3 {
    padding: 40px;
    font-size: 24px;
    padding-bottom: 0px;
    color: var(--er-infoDark);
  }
`

const ImgContainer = styled.div`
  filter: drop-shadow(0px 3px 8px var(--er-infoDark));

  & > img {
    filter: brightness(0);
    width: 350px;
  }
`

const PNFContainer = styled.div`
  margin-top: -50px;
  font-size: 32px;
  font-family: inherit;
  font-weight: 600;
  background: var(--er-infoDark);
  padding: 20px;
  border-radius: 0 0 16px 16px;
  color: white;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
`

const PageNotFound = (): JSX.Element => {
  return (
    <Container>
      <div>
        <h3>
          Oops! It looks like the page you&apos;re looking for doesn&apos;t
          exist.
        </h3>
        <ImgContainer>
          <img
            src={PnfGif}
          />
        </ImgContainer>
        <PNFContainer>Page not found !</PNFContainer>
      </div>
    </Container>
  )
}

export default PageNotFound
