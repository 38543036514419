import React, { useState, useEffect } from 'react'
import { api } from '../../utils/AxiosConfig'
import { PLANS } from '../../Constants'
import MoreOptions from '../../common/elements/MoreOptions'
import {
  Li,
  PrimaryButton,
  SecondaryButton,
  Table,
  Ul
} from '../../common/elements/CommonElements'
import './Dashboard.css'
import Dialog from '../../common/elements/Dialog'
import Input from '../../common/elements/Input'
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import CopyText from '../../common/elements/CopyText'
import type { SubscriptionDetails } from '../../common/types/Types'
import SelectInput from '../../common/elements/SelectInput'
import { formatDate, getHigherPlan } from '../../utils/CommonFunctions'
import { useNavigate } from 'react-router-dom'
import Snackbar, {
  type SnackbarTypes,
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import Loader from '../../common/misc/loader/Loader'
import { useAuth } from '../../common/context/Context'
import NoItemsFound from '../../common/misc/noItemsFound/NoItemsFound'
import Payment from '../payment/Payment'
import { ValidateMFA } from '../../utils/Validation'

interface Invoices {
  number: string
  id: string
  invoice_pdf: string
}

export const statusMapping = {
  active: {
    name: 'Active',
    color: 'green'
  },
  inactive: {
    name: 'Inactive',
    color: 'red'
  },
  downgradePending: {
    name: 'Downgrade pending',
    color: 'yellow'
  },
  cancellationPending: {
    name: 'Cancellation pending',
    color: 'red'
  },
  cancelled: {
    name: 'Cancelled',
    color: 'red'
  },
  downgraded: {
    name: 'Downgraded',
    color: 'red'
  }
}

export const emptySnackbarData = {
  type: 'log' as SnackbarTypes,
  message: ''
}

const Dashboard = (): JSX.Element => {
  const [APIs, setAPIs] = useState<SubscriptionDetails[]>([])
  const [showOptionOf, setShowOptionOf] = useState(-1)
  const [showUpgradesOf, setShowUpgradesOf] = useState(-1)
  const [showInvoicesOf, setShowInvoicesOf] = useState(-1)
  const [updatePaymentOf, setUpdatePaymentOf] = useState('')
  const [cancelSubOf, setCancelSubOf] = useState(-1)
  const [showGenerateKey, setShowGenerateKey] = useState<number | null>(-1)

  const [selectedSubInvoices, setSelectedSubInvoices] = useState<Invoices[]>()
  const [selectedInvoice, setSelectedInvoice] = useState()

  const [mfaToken, setMFAToken] = useState('')
  const [key, setKey] = useState()

  const [selectedPlan, setSelectedPlan] = useState('')

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const { user } = useAuth()

  const fetchAllApis = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const apis = await api.get('/api/subscription/all')
      setAPIs(apis.data)
    } catch (err) {
      console.error('Error occurred while fetching APIs')
      setSnackbarData({ type: 'error', message: 'Unable to fetch APIs' })
    } finally {
      setIsLoading(false)
    }
  }

  const generateAPIKey = async (
    apiProduct: SubscriptionDetails
  ): Promise<void> => {
    setIsLoading(true)
    try {
      const result = await api.patch(
        '/api/generate',
        {
          'subscription-id': apiProduct.subscriptionId
        },
        {
          headers: {
            'mfa-token': mfaToken
          }
        }
      )
      setKey(result.data.apiKey)
    } catch (err) {
      console.error('Error occurred while generating API key', err)
      setSnackbarData({
        type: 'error',
        message: 'Issue occurred while generating API key.'
      })
      clear()
    } finally {
      setIsLoading(false)
    }
  }

  const cancelSubscription = async (idx: number): Promise<void> => {
    setIsLoading(true)
    const cancellingSub = APIs[idx]
    try {
      await api.delete('/api/stripe', {
        headers: {
          'subscription-id': cancellingSub.subscriptionId,
          'mfa-token': mfaToken
        }
      })
      await clearAndFetch()
      setSnackbarData({
        type: 'success',
        message: 'Subscription marked to cancel at the end of billing cycle.'
      })
    } catch (err: any) {
      console.error('Error occurred while cancelling subscription', err)
      if (err?.response?.status === 444) {
        setSnackbarData({
          type: 'error',
          message:
            'The subscription which you are trying to cancel is already cancelled.'
        })
      } else
        setSnackbarData({
          type: 'error',
          message: 'Issue occurred while cancelling subscription.'
        })
      clear()
    } finally {
      setMFAToken('')
      setIsLoading(false)
    }
  }

  const upgradePlan = async (): Promise<void> => {
    setIsLoading(true)
    try {
      if (!selectedPlan)
        throw new Error('Something went wrong, unable to find plan Id')
      await api.patch('/api/stripe', {
        subscriptionId: APIs[showUpgradesOf].subscriptionId,
        planName: `qr.${selectedPlan}`
      })
      await clearAndFetch()
      setSnackbarData({
        type: 'success',
        message: 'Plan modified successfully.'
      })
    } catch (err: any) {
      console.error('Error occurred while upgrading plan', err)
      if (err?.response?.status === 422)
        setSnackbarData({
          type: 'info',
          autoClose: false,
          message: `Your downgrade request can't be processed as you've exceeded the ${
            err.response?.data.replaceAll(',', ', ') as string
          } codes limit for the new plan.
            Please review and delete unnecessary QR codes or consider upgrading to a plan accommodate your current usage.`
        })
      else
        setSnackbarData({
          type: 'error',
          message: 'Issue occurred while modifying plan.'
        })
      clear()
    } finally {
      setIsLoading(false)
    }
  }

  const fetchInvoices = async (idx: number): Promise<void> => {
    setIsLoading(true)
    try {
      const invoices = await api.get('/api/stripe/invoices', {
        headers: { 'subscription-id': APIs[idx].subscriptionId }
      })
      setSelectedSubInvoices(invoices.data)
    } catch (err) {
      console.error('Error occurred while fetching invoices', err)
      setSnackbarData({
        type: 'error',
        message: 'Issue occurred while fetching invoices.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const downloadInvoice = async (): Promise<void> => {
    if (!selectedSubInvoices || !selectedInvoice) return

    const pdfUrl = selectedSubInvoices.find(
      (inv) => inv.id === selectedInvoice
    )?.invoice_pdf

    if (!pdfUrl) return

    const link = document.createElement('a')

    link.href = pdfUrl
    link.setAttribute('download', (selectedInvoice as string) + '.pdf')

    link.click()
  }

  const clear = (): void => {
    setCancelSubOf(-1)
    setShowUpgradesOf(-1)
    setSelectedPlan('')
    setShowOptionOf(-1)
    setShowInvoicesOf(-1)
  }

  const clearAndFetch = async (): Promise<void> => {
    clear()
    await fetchAllApis()
  }

  useEffect(() => {
    fetchAllApis()
  }, [key])

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <Table className="productTable">
        <thead>
          <tr>
            <th>Product name</th>
            <th>Plan</th>
            <th>Valid from</th>
            <th>Valid upto</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {APIs.map((api, idx) => {
            const [apiOf, planOf] = api.apiName.split('.')
            const currentPlan = PLANS[apiOf as keyof typeof PLANS]
            const currentPlanDetail = currentPlan.planDetails.find(
              (p) => p.id === planOf
            )
            if (!currentPlanDetail) return null
            const higherPlan = getHigherPlan(currentPlanDetail.id, selectedPlan)
            return (
              <tr key={idx}>
                <td>{currentPlan.name}</td>
                <td>{currentPlanDetail.heading}</td>
                <td>
                  {api.validFrom
                    ? formatDate(parseInt(api.validFrom)) || ''
                    : ''}
                </td>
                <td>
                  {api.validUpto
                    ? formatDate(parseInt(api.validUpto)) || ''
                    : ''}
                </td>
                <td>
                  <div
                    className={`${
                      statusMapping[api.status]
                        ? `status-${statusMapping[api.status].color}`
                        : ''
                    }`}
                  >
                    {statusMapping[api.status]?.name}
                  </div>
                </td>
                <td>
                  {api.status === 'active' ||
                  api.status?.toLocaleLowerCase().includes('pending') ? (
                    <MoreOptions
                      open={showOptionOf === idx}
                      onClick={(id) => setShowOptionOf(id === null ? -1 : idx)}
                      containerClassName="dashboardMoreOptions"
                    >
                      <Ul>
                        <Li
                          onClick={() => {
                            if (
                              ValidateMFA(
                                user?.hasSecret as boolean,
                                setSnackbarData
                              )
                            ) {
                              setShowGenerateKey(idx)
                              setShowOptionOf(-1)
                              setShowUpgradesOf(-1)
                              setShowInvoicesOf(-1)
                              setUpdatePaymentOf('')
                            }
                          }}
                        >
                          {api.hasApiKey
                            ? 'Regenerate API key'
                            : 'Generate API key'}
                        </Li>
                        <Li
                          onClick={() => {
                            setShowGenerateKey(-1)
                            setShowOptionOf(-1)
                            setShowUpgradesOf(-1)
                            setShowInvoicesOf(idx)
                            fetchInvoices(idx)
                            setUpdatePaymentOf('')
                          }}
                        >
                          Invoices
                        </Li>
                        <Li
                          onClick={() => {
                            setShowGenerateKey(-1)
                            setShowOptionOf(-1)
                            setShowUpgradesOf(idx)
                            setShowInvoicesOf(-1)
                            setUpdatePaymentOf('')
                          }}
                        >
                          Modify plan
                        </Li>
                        <Li
                          onClick={() =>
                            navigate(
                              `/manage/${apiOf}/${btoa(api.subscriptionId)}`
                            )
                          }
                        >
                          Manage
                          {/* Usage/analytics/my qrs */}
                        </Li>
                        <Li
                          onClick={() =>
                            navigate(
                              `/bulk-orders/${apiOf}/${btoa(
                                api.subscriptionId
                              )}`
                            )
                          }
                        >
                          Bulk orders
                        </Li>
                        <Li
                          onClick={() => {
                            setShowGenerateKey(-1)
                            setShowOptionOf(-1)
                            setShowUpgradesOf(-1)
                            setShowInvoicesOf(-1)
                            setUpdatePaymentOf(api.subscriptionId)
                          }}
                        >
                          Update payment method
                        </Li>
                        <Li
                          onClick={() => {
                            if (
                              ValidateMFA(
                                user?.hasSecret as boolean,
                                setSnackbarData
                              )
                            )
                              setCancelSubOf(idx)
                          }}
                        >
                          Cancel subscription
                        </Li>
                      </Ul>
                    </MoreOptions>
                  ) : null}
                  <Dialog
                    open={idx === showGenerateKey}
                    onClose={() => {
                      setShowGenerateKey(null)
                      setKey(undefined)
                      setMFAToken('')
                    }}
                    className="generationDialog"
                  >
                    {key ? (
                      <>
                        <h4 style={{ marginBottom: 0 }}>API key</h4>
                        <div className="infoContainer">
                          <InfoIcon />
                          <div>
                            Note - You won&apos;t be able to see this key again,
                            so make sure to keep it safe somewhere!
                          </div>
                        </div>
                        <CopyText text={key} />
                      </>
                    ) : (
                      <>
                        <h4>Generate API key</h4>
                        <Input
                          label="Enter 6-digit Verification Code:"
                          onChange={(e: any) => {
                            setMFAToken(e.target.value)
                          }}
                          maxLength={6}
                          value={mfaToken}
                        />
                        {api.hasApiKey ? (
                          <div className="infoContainer">
                            <InfoIcon />
                            <div>
                              Note - Creating a new API key will deactivate the
                              old one. Before you proceed, it&apos;s a good idea
                              to take precautions to make sure your workflows
                              keep running smoothly.
                            </div>
                          </div>
                        ) : null}
                        <PrimaryButton
                          style={{ marginTop: '30px' }}
                          onClick={async () => await generateAPIKey(api)}
                          disabled={!mfaToken || mfaToken?.length > 6}
                        >
                          Generate
                        </PrimaryButton>
                      </>
                    )}
                  </Dialog>
                  <Dialog
                    open={idx === showUpgradesOf}
                    onClose={() => {
                      setShowUpgradesOf(-1)
                      setSelectedPlan('')
                    }}
                    className="selectPlanDialog"
                  >
                    <h4>Modify plan</h4>
                    <span className="inputLabel">Current plan:</span>
                    <span className="currentPlanHeading">
                      {currentPlanDetail.heading}
                    </span>
                    <SelectInput
                      label="Select plan"
                      selectList={currentPlan.planDetails
                        .filter((pd) => pd.id !== planOf)
                        .map((pd) => ({
                          label: pd.heading,
                          id: pd.id
                        }))}
                      selected={selectedPlan}
                      onListItemClick={(id) => setSelectedPlan(id)}
                    />
                    <div
                      className="planLinesContainer"
                      style={
                        selectedPlan
                          ? higherPlan === currentPlanDetail.id
                            ? { borderColor: 'var(--er-green)' }
                            : { borderColor: 'var(--er-red)' }
                          : {}
                      }
                    >
                      <h4>Current plan</h4>
                      {currentPlanDetail.lines.map((line, i) => (
                        <div key={i}>
                          <span key={i}>{line}</span>
                          <br />
                        </div>
                      ))}
                    </div>
                    {selectedPlan ? (
                      <>
                        <div
                          className="planLinesContainer"
                          style={
                            higherPlan === selectedPlan
                              ? {
                                  float: 'right',
                                  borderColor: 'var(--er-green)'
                                }
                              : { float: 'right', borderColor: 'var(--er-red)' }
                          }
                        >
                          <h4>Selected plan</h4>
                          {currentPlan.planDetails
                            .find((pd) => pd.id === selectedPlan)
                            ?.lines.map((line, i) => (
                              <div key={i}>
                                <span>{line}</span>
                                <br />
                              </div>
                            ))}
                        </div>

                        <div
                          className="infoContainer"
                          style={{ padding: '25px 0' }}
                        >
                          <InfoIcon />
                          <div>
                            {higherPlan === selectedPlan ? (
                              "Note - You're upgrading your plan, and this change will take effect right away. Your billing will be adjusted accordingly."
                            ) : (
                              <>
                                Note - You&apos;re switching to a lower-tier
                                plan, and this change will happen at the end of
                                your billing cycle. Until then, you&apos;ll
                                still be billed according to your current plan.
                                <br />
                                <br />
                                Therefore, we recommend downgrading your plan at
                                the end of your billing cycle. During this time,
                                you won&apos;t have access to the features of
                                your current subscribed plan. Instead,
                                you&apos;ll be compelled to use the features of
                                the downgraded plan, even though you are still
                                being billed for the higher-tier subscription.
                              </>
                            )}
                          </div>
                          {/*  */}
                        </div>
                      </>
                    ) : null}
                    <PrimaryButton
                      disabled={!selectedPlan}
                      style={{ marginTop: '30px', display: 'block' }}
                      onClick={upgradePlan}
                    >
                      {selectedPlan && higherPlan !== selectedPlan
                        ? 'Downgrade plan'
                        : 'Upgrade plan'}
                    </PrimaryButton>
                  </Dialog>
                  <Dialog
                    open={showInvoicesOf === idx}
                    onClose={() => setShowInvoicesOf(-1)}
                  >
                    <h4 style={{ marginTop: 0 }}>Download invoices</h4>
                    <SelectInput
                      label="Invoices"
                      selectList={
                        selectedSubInvoices &&
                        Array.isArray(selectedSubInvoices) &&
                        selectedSubInvoices.length
                          ? selectedSubInvoices.map((invoices) => ({
                              id: invoices.id,
                              label: invoices.number
                            }))
                          : []
                      }
                      selected={selectedInvoice}
                      onListItemClick={(id) => setSelectedInvoice(id)}
                    />

                    <PrimaryButton
                      disabled={!selectedInvoice}
                      onClick={downloadInvoice}
                      className="downloadInvoiceBtn"
                    >
                      Download invoice
                    </PrimaryButton>
                  </Dialog>
                  <Dialog
                    open={cancelSubOf === idx}
                    onClose={() => {
                      setCancelSubOf(-1)
                      setMFAToken('')
                    }}
                  >
                    <h3 style={{ marginTop: '0' }}>
                      Are you sure you want to cancel this subscription ?
                    </h3>
                    <p>
                      You will not be able to use any service after the current
                      subscription is over.
                    </p>
                    <br />
                    <Input
                      label="Enter 6-digit Verification Code:"
                      onChange={(e: any) => {
                        setMFAToken(e.target.value)
                      }}
                      maxLength={6}
                      value={mfaToken}
                    />
                    <br />
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                      }}
                    >
                      <PrimaryButton
                        onClick={async () =>
                          await cancelSubscription(cancelSubOf)
                        }
                      >
                        Yes
                      </PrimaryButton>
                      <SecondaryButton
                        onClick={() => {
                          setCancelSubOf(-1)
                          setMFAToken('')
                        }}
                      >
                        No
                      </SecondaryButton>
                    </div>
                  </Dialog>
                  <Dialog
                    open={updatePaymentOf === api.subscriptionId}
                    onClose={() => {
                      setUpdatePaymentOf('')
                    }}
                  >
                    <Payment subscriptionId={updatePaymentOf} product={apiOf} />
                  </Dialog>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {APIs.length === 0 && !isLoading ? <NoItemsFound /> : null}
    </div>
  )
}

export default Dashboard
