import React, { useState } from 'react'
import type { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg'

interface DropdownButtonProps extends HTMLAttributes<HTMLButtonElement> {
  label?: string
  icon?: JSX.Element
  list: Array<{
    label: string
    id: string
    icon?: JSX.Element
  }>
  onItemClick: (...args: any) => void
}

export const BasicButton = styled.button`
  position: relative;
  height: 32px;
  border: 0;
  background: var(--er-primary);
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
`

const ListContainer = styled.ul`
  position: absolute;
  z-index: 4;
  top: 18px;
  left: 0px;
  list-style: none;
  padding: 10px;
  background: white;
  color: black;
  border-radius: 8px;
  width: max-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-align: left;

  li {
    padding: 10px;
    text-transform: none;
  }

  li:hover {
    cursor: pointer;
    color: var(--er-primary);
  }

  li:active {
    color: var(--er-primaryDark);
  }

  svg {
    vertical-align: middle;
    padding-right: 10px;
  }
`

const StyledChevronDown = styled(ChevronDown)`
  color: white;
  position: absolute;
  height: 32px;
  top: 0;
  width: 18px;
  right: -18px;
  transition: all 0.2s;
`

const DropdownButton = ({
  label,
  icon,
  list,
  onItemClick,
  ...props
}: DropdownButtonProps): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false)
  return (
    <BasicButton
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      {...props}
    >
      {label ?? null}
      {icon ?? null}
      <StyledChevronDown
        style={showDropdown ? { transform: 'rotate(-180deg)' } : {}}
      />
      {showDropdown ? (
        <ListContainer>
          {list.map((item) => (
            <li
              key={item.id}
              value={item.id}
              onClick={() => onItemClick(item.id)}
            >
              {item.icon ?? null}
              {item.label}
            </li>
          ))}
        </ListContainer>
      ) : null}
    </BasicButton>
  )
}

export default DropdownButton
