import React from 'react'
import PartyPopper from '../misc/partyPopper/PartyPopper'
import { H3, SecondaryButton } from '../elements/CommonElements'
import './PaymentCompleted.css'
import { useAuth } from '../context/Context'

export interface PaymentProps {
  mode?: 'setup' | 'subscription'
}

const PaymentCompleted = ({
  mode = 'subscription'
}: PaymentProps): JSX.Element => {
  const isSetup = mode === 'setup'
  const { state } = useAuth()
  return (
    <div style={{ textAlign: 'center' }}>
      <PartyPopper width="400px" height="400px" />
      <H3>
        {isSetup
          ? `Payment method ${state?.user?.hasCustomerId ? 'updated' : 'added'}`
          : 'We received your payment!'}
      </H3>
      <span>
        {isSetup
          ? `Congratulations! Your payment method has been successfully ${
              state?.user?.hasCustomerId ? 'updated' : 'added'
            }. You're now all set to breeze through transactions hassle-free.`
          : 'You can check the status of payment in the dashboard. You will get a mail once the payment has been processed on our end. To use this feature, you will then need to generate an API key from the dashboard.'}
      </span>
      <br />
      <SecondaryButton
        onClick={() => {
          window.location.href = '/dashboard'
        }}
        style={{ marginTop: '45px' }}
      >
        Go to Dashboard
      </SecondaryButton>
    </div>
  )
}

export default PaymentCompleted
