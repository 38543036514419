import PlanSuite from '../../common/plans/PlanSuite'
import React, { useEffect, useState } from 'react'
import { PLANS } from '../../Constants'

import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../common/context/Context'
import Loader from '../../common/misc/loader/Loader'
import { api } from '../../utils/AxiosConfig'
import type { SnackbarBaseProps } from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import Snackbar from '../../common/elements/Snackbar'
import { SERVER_URL } from '../../Config'

const QRPlanSuite = (): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isUserLoggedIn } = useAuth()
  const [loading, setLoading] = useState(false)

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const handlePlanClick = async (id: string): Promise<void> => {
    setLoading(true)
    if (!isUserLoggedIn)
      return navigate('/login', {
        state: { from: window.location.pathname, data: { id } }
      })
    try {
      const response = await api.get(
        `${SERVER_URL}/api/stripe/checkout`,
        {
          headers: {
            name: id,
            'success-url': '/qr/success',
            'error-url': '/qr/error'
          }
        }
      )
      if (response?.data?.url) window.location.href = response.data.url
      else throw new Error('Server not responded')
    } catch (e: any) {
      console.error('An error occurred while creating checkout session.')
      setSnackbarData({ type: 'error', message: 'An error occurred while creating checkout session.' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    async function handleRedirectionAfterLogin(): Promise<void> {
      if (location.state?.data?.id)
        try {
          await handlePlanClick(location.state.data.id)
        } catch (e: any) {
          console.error('Error while redirecting to payment page')
        }
    }

    handleRedirectionAfterLogin()
  }, [])

  return (
    <>
      {loading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <PlanSuite
        onClick={handlePlanClick}
        headKey={'qr'}
        minHeight="585px"
        plans={PLANS.qr.planDetails}
      />
    </>
  )
}

export default QRPlanSuite
