import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'
import React from 'react'
import { api } from '../../utils/AxiosConfig'
import { GOOGLE_CLIENT_ID } from '../../Config'
import { ReactComponent as GoogleIcon } from '../../assets/images/google.svg'
import styled from 'styled-components'
import { type SnackbarBaseProps } from '../../common/elements/Snackbar'

const GButton = styled.button`
  background: white;
  border-radius: 8px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px 30px;
  margin-top: 30px;
  cursor: pointer;

  &:active {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: lightgray;
  }

  span,
  svg {
    vertical-align: middle;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`

const GoogleLoginButton = ({
  handleSuccessfulLogin,
  setSnackbarData,
  buttonText = 'Sign in with Google',
  setIsLoading
}: {
  handleSuccessfulLogin: (user: any) => void
  setIsLoading: (state: boolean) => void
  setSnackbarData: (data: SnackbarBaseProps) => void
  buttonText?: string
}): JSX.Element => {
  const LoginButton = (): JSX.Element => {
    const login = useGoogleLogin({
      onSuccess: async (codeResponse) => {
        setIsLoading(true)
        try {
          const response = await api.post('/api/auth/google', {
            code: codeResponse?.code
          })
          handleSuccessfulLogin(response.data?.user)
        } catch (err: any) {
          console.error('Error ', err)
          if (err?.response?.status === 403 && err?.response?.data === 'Account is deactivated.')
            return setSnackbarData({
              type: 'error',
              message:
                'Your account is deactivated. Kindly reach out to our support team for assistance.'
            })
          setSnackbarData({
            type: 'error',
            message: 'Error occurred while signing in from Google'
          })
        } finally {
          setIsLoading(false)
        }
      },
      onError: () => {
        setSnackbarData({
          type: 'error',
          message: 'Error occurred while signing in from Google'
        })
      },
      flow: 'auth-code'
    })

    if (!GOOGLE_CLIENT_ID) return <>Google login not available</>

    return (
      <GButton onClick={login}>
        <GoogleIcon />
        <span>{buttonText}</span>
      </GButton>
    )
  }
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <LoginButton />
    </GoogleOAuthProvider>
  )
}

export default GoogleLoginButton
