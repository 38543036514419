
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export const setCookies = (key: string, value: string): void => {
  cookies.set(key, value, { path: '/', expires: new Date(Date.now() + 2592000) })
}

export const getCookies = (key: string): any => {
  return cookies.get(key)
}

export const removeCookies = (key: string): void => {
  cookies.remove(key)
}
