import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QRViewer from '../../common/qrViewer/QRViewer'
import type { DynamicQR } from '../../common/types/QR.Types'
import Loader from '../../common/misc/loader/Loader'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import Input, { TextArea } from '../../common/elements/Input'
import { PrimaryButton } from '../../common/elements/CommonElements'
import { api } from '../../utils/AxiosConfig'
import usePageTitle from '../../hooks/UsePageTitle'

export interface EncryptionPostData {
  'encryption-key': string
  password: string
  iv: string
  id: string
}

const QRRedirect = (): JSX.Element => {
  usePageTitle('View QR')
  const { id } = useParams()
  const [redirectData, setRedirectData] = useState<DynamicQR>()

  const [isLoading, setIsLoading] = useState(false)

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [encryptionData, setEncryptionData] = useState<EncryptionPostData>({
    'encryption-key': '',
    password: '',
    iv: '',
    id: id as string
  })

  const fetchQRDetails = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const queryParams = new URLSearchParams(window.location.search)
      const queryString = queryParams.toString()
      const details = await api.get<DynamicQR>(
        `/api/qr/redirect-link/${id as string}?${queryString}`
      )
      const formattedRawData = details.data.rawData
        ? JSON.parse(details.data.rawData)
        : ''
      setRedirectData({
        ...details.data,
        formattedRawData
      })
    } catch (e: any) {
      console.error(
        'Error occurred while fetching QR details. Please try again later.',
        e
      )
      setSnackbarData({
        type: 'error',
        title: e?.response?.status === 401 ? 'QR code expired' : 'Error',
        message:
          e?.response?.status === 401
            ? e.response?.data?.error?.message
            : 'Error occurred while fetching/formatting QR details. ' +
              (e.response?.data as string)
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getDecryptedData = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.get('/api/qr/encrypted-data', {
        headers: {
          ...(encryptionData as unknown as Record<string, string>),
          'check-expiry': true // It should throw error in case of expired QRs
        }
      })
      const decryptedData: {
        data: string
        rawData: string
        files: string[]
        secureToken?: string
      } = response.data
      setRedirectData((prev) =>
        prev
          ? {
              ...prev,
              data: decryptedData.data,
              formattedRawData: decryptedData.rawData,
              files: decryptedData.files,
              isSecured: false,
              secureToken: decryptedData.secureToken
            }
          : undefined
      )
    } catch (err: any) {
      console.error(
        'Issue occurred while fetching decrypted data',
        err.response.status
      )
      setSnackbarData({
        type: 'error',
        message: 'Issue occurred while fetching decrypted data'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleEncryptionDataChange = (
    key: string,
    value: any,
    convertToBase64 = false
  ): void => {
    setEncryptionData((prevData) => ({
      ...prevData,
      [key]: convertToBase64 ? btoa(value) : value
    }))
  }

  useEffect(() => {
    fetchQRDetails()
  }, [])

  return (
    <>
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      {isLoading ? <Loader /> : null}
      <div style={{ marginTop: '50px', padding: '50px' }}>
        <h2>QR data:</h2>
        {redirectData ? (
          <>
            {Array.isArray(redirectData.secure) && redirectData.isSecured ? (
              <>
                <span>
                  {`To access the requested data, please enter the ${
                    redirectData.isEncrypted && redirectData.isPasswordProtected
                      ? redirectData.encryptionType === 'AES'
                        ? 'password, encryption key and IV'
                        : 'password and encryption key'
                      : redirectData.isEncrypted
                      ? redirectData.encryptionType === 'AES'
                        ? 'encryption key and IV'
                        : 'encryption key'
                      : redirectData.isPasswordProtected
                      ? 'password'
                      : ''
                  } for secure authentication.`}
                </span>
                {redirectData.isPasswordProtected ? (
                  <Input
                    label="Password"
                    value={encryptionData.password || ''}
                    onChange={(e) =>
                      handleEncryptionDataChange('password', e.target.value)
                    }
                    style={{ margin: '30px 0', width: '400px' }}
                  />
                ) : null}
                {redirectData.isEncrypted ? (
                  <>
                    {redirectData.encryptionType === 'AES' ? (
                      <Input
                        label="Encryption IV"
                        value={encryptionData.iv || ''}
                        onChange={(e) =>
                          handleEncryptionDataChange('iv', e.target.value, true)
                        }
                        style={{ margin: '30px 0', width: '400px' }}
                      />
                    ) : null}
                    <TextArea
                      label="Encryption key"
                      value={encryptionData?.['encryption-key'] || ''}
                      onChange={(e) =>
                        handleEncryptionDataChange(
                          'encryption-key',
                          e.target.value,
                          true
                        )
                      }
                      style={{ margin: '30px 0' }}
                      resize="none"
                    />
                  </>
                ) : null}
                <br />
                <br />
                <PrimaryButton onClick={getDecryptedData}>Submit</PrimaryButton>
              </>
            ) : (
              <QRViewer redirectData={redirectData} setSnackbarData={setSnackbarData}/>
            )}{' '}
          </>
        ) : null}
      </div>
    </>
  )
}

export default QRRedirect
