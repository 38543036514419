import * as React from 'react'

const LoggedIn = (): JSX.Element => {
  return <div>
  Welcome back! You are already logged in and will be redirected back to homepage shortly.

  If you are not redirected automatically, follow this link.
</div>
}

export default LoggedIn
