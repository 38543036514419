import React, { type HTMLAttributes } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css'
import Input from './Input'

interface DatePickerProps extends HTMLAttributes<HTMLDivElement> {
  selectedDate: Date | null
  onDateChange: (date: Date | null) => void
  bordered?: boolean
  label?: string
  width?: string
  minDate?: Date
  showEndsText?: boolean
}

export default function DatePickerInput({
  selectedDate,
  onDateChange,
  bordered,
  label,
  width,
  className,
  minDate,
  showEndsText,
  ...props
}: DatePickerProps): JSX.Element {
  return (
    <div
      className={`mainDateContainer ${bordered ? 'bordered' : ''} ${
        className ?? ''
      }`}
      {...props}
      style={{ width, ...props.style }}
    >
      <DatePicker
        selected={selectedDate}
        onChange={onDateChange}
        dateFormat="dd MMM yyyy"
        calendarClassName="customDatePickerCalendar"
        wrapperClassName="customDatePickerContainer"
        popperClassName="customDatePickerPopper"
        required
        customInput={
          <Input
            label="Expiry date"
            value={selectedDate ? selectedDate.toDateString() : ''}
          />
        }
        minDate={minDate}
      />
    </div>
  )
}
