import axios from 'axios'
import { SERVER_URL } from '../Config'
import { getCookies } from './Cookies'

const api = axios.create({
  baseURL: SERVER_URL
})

updateHeaders();

function updateHeaders(): void {
  let token = getCookies('erjwt')
  if (!token) {
    token = sessionStorage.getItem('erjwt')
  }
  let refreshToken = getCookies('refreshjwt')
  if (!token) {
    refreshToken = sessionStorage.getItem('refreshjwt')
  }

  api.defaults.headers.common.erjwt = token
  api.defaults.headers.common.refreshjwt = refreshToken
}

export { api, updateHeaders }
