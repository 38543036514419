import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../utils/AxiosConfig'
import type {
  DynamicQR,
  QROptions,
  SecureType
} from '../../common/types/QR.Types'
import InputFieldRenderer from '../qr/common/QR.InputFieldRenderer'
import { ReactComponent as TrashIcon } from '../../assets/images/trash-2.svg'
import { ReactComponent as CheckIcon } from '../../assets/images/check-circle.svg'
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg'
import { ReactComponent as XCircleIcon } from '../../assets/images/x-circle.svg'
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg'
import { ReactComponent as ExtLinkIcon } from '../../assets/images/external-link.svg'
import { ReactComponent as UnlockIcon } from '../../assets/images/unlock.svg'
import { ReactComponent as DownloadImage } from '../../assets/images/download.svg'
import { PrimaryButton } from '../../common/elements/CommonElements'
import './ManageAPI.css'
import ConfirmDialog from '../../common/elements/ConfirmDialog'
import SearchInput from '../../common/elements/SearchInput'
import { Checkbox, Slider } from '../../common/elements/InputControls'
import Loader from '../../common/misc/loader/Loader'
import { PLANS } from '../../Constants'
import type { SubscriptionDetails } from '../../common/types/Types'
import { emptySnackbarData, statusMapping } from '../dashboard/Dashboard'
import { base64toBlob, formatDate } from '../../utils/CommonFunctions'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { type EncryptionPostData } from '../qr/QR.Redirect'
import {
  InputSecureKeysForFetching,
  InputSecureKeysForPosting
} from './ManageAPI.KeyInputs'
import Dialog from '../../common/elements/Dialog'
import CopyText from '../../common/elements/CopyText'
import NoItemsFound from '../../common/misc/noItemsFound/NoItemsFound'
import { CLIENT_SITE_URL } from '../../Config'

const ManageAPI = (): JSX.Element => {
  let { subId } = useParams()
  // const { product } = useParams()      // could be used in future to show manage component for different products
  const [editQRId, setEditQRId] = useState('')
  const [resetQRId, setResetQRId] = useState('')
  const [cancelQRId, setCancelQRId] = useState('')

  let isSubIdValid = false
  try {
    if (subId) {
      subId = atob(subId)
      isSubIdValid = true
    }
  } catch (err) {
    isSubIdValid = false
    console.warn('Error while getting subscription id', err)
  }
  const [dynamicQRs, setDynamicQRs] = useState<DynamicQR[]>([])
  const [isValidated, setIsValidated] = useState(true)
  const [errors, setErrors] = useState<Record<string, any>>({})

  const [modifiedQRData, setModifiedQRData] = useState<
    QROptions & DynamicQR & { newFiles?: File[] }
  >()

  const emptySearchData = {
    text: '',
    secureOnly: false,
    sourceOnly: false
  }

  const [searchData, setSearchData] = useState(emptySearchData)

  const [searchedQRs, setSearchedQRs] = useState<DynamicQR[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [stats, setStats] = useState<Record<string, any>>({
    total: {},
    used: {},
    remaining: {}
  })

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [subDetails, setSubDetails] = useState<SubscriptionDetails>()

  const emptyEncryptionData = {
    'encryption-key': '',
    password: '',
    iv: '',
    id: ''
  }
  const [encryptionData, setEncryptionData] =
    useState<EncryptionPostData>(emptyEncryptionData)

  const [generatedEncryptionKeys, setGeneratedEncryptionKeys] = useState({})

  const [downloadedFiles, setDownloadedFiles] =
    useState<Record<string, string[]>>()

  const [showDownloadDropdown, setShowDownloadDropdown] = useState<
    string | undefined
  >()

  const statsMapping = {
    apiCalls: 'Requests',
    scans: 'Scans',
    dynamicQr: 'Dynamic QR',
    securedQr: 'Secured QR',
    sourceQr: 'Enhanced analytic QR',
    bulkOrders: 'Bulk orders',
    storage: 'File storage'
  }

  const handleEncryptionDataChange = (key: string, value: any): void => {
    setEncryptionData((prevData) => ({ ...prevData, [key]: value }))
  }

  const handleOptionChange = (key: string, value: any): void => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete errors[key] // Clear any error
    if (key === 'margin') {
      if (parseInt(value) < 0) {
        value = '0'
      }
    }
    setModifiedQRData((prevData) => {
      const keys: string[] = key.split('.')
      const newData: any = { ...prevData }

      // Use reduce to traverse the nested structure and update the target property
      keys.reduce((acc, currentKey, index) => {
        if (index === keys.length - 1) {
          // If it's the last key, update the value
          acc[currentKey as keyof QROptions] = value
        } else {
          // If it's not the last key, create nested objects if necessary
          acc[currentKey as keyof QROptions] =
            acc[currentKey as keyof QROptions] || {}
        }

        return acc[currentKey as keyof QROptions]
      }, newData)

      return newData
    })
  }

  const validate = (): boolean => {
    setIsValidated(true)
    const allErrors: Record<string, any> = {}
    if (!modifiedQRData?.data) {
      allErrors.data = 'Please enter some data'
    }
    if (Object.keys(allErrors).length) {
      setErrors(allErrors)
      return false
    }
    return true
  }

  const fetchDynamicQRs = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const qrs = await api.get<DynamicQR[]>('/api/qr/dynamic-qr', {
        headers: { 'subscription-id': subId }
      })
      setDynamicQRs(qrs.data)
    } catch (err) {
      console.error('Error occurred while fetching QR codes')
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching QR codes'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const updateQR = async (cancel?: boolean): Promise<void> => {
    try {
      setIsLoading(true)
      const formData = new FormData()
      if (cancel) {
        formData.append('qrId', cancelQRId)
        formData.append('active', 'false')
      } else if (validate()) {
        formData.append('qrId', modifiedQRData?.id as string)
        formData.append(
          'data',
          typeof modifiedQRData?.data === 'object'
            ? JSON.stringify(modifiedQRData?.data)
            : (modifiedQRData?.data as string)
        )
        formData.append('dataType', modifiedQRData?.dataType as string)
        if (modifiedQRData?.expiry)
          formData.append('expiry', `${modifiedQRData?.expiry}`)
        if (
          modifiedQRData?.secure &&
          Array.isArray(modifiedQRData?.secure) &&
          modifiedQRData?.secure?.length > 0
        )
          formData.append('secure', JSON.stringify(modifiedQRData?.secure))
        if (modifiedQRData?.password)
          formData.append('password', modifiedQRData?.password)
        if (
          typeof modifiedQRData?.encryption === 'object' &&
          Object.keys(modifiedQRData?.encryption).length > 0
        )
          formData.append(
            'encryption',
            JSON.stringify(modifiedQRData?.encryption)
          )
        if (modifiedQRData?.files)
          formData.append('files', JSON.stringify(modifiedQRData?.files))
        modifiedQRData?.newFiles?.forEach((nf) => {
          formData.append('newFiles', nf)
        })
      } else {
        return
      }
      const updatedQR = await api.put('/api/qr/dynamic-qr', formData, {
        headers: {
          'subscription-id': subId,
          'Content-Type': 'multipart/form-data'
        }
      })
      if (typeof updatedQR.data === 'object') {
        if (Object.prototype.hasOwnProperty.call(updatedQR.data, 'iv')) {
          setGeneratedEncryptionKeys({ IV: updatedQR.data.iv })
        } else if (
          Object.prototype.hasOwnProperty.call(updatedQR.data, 'secureKey')
        ) {
          setGeneratedEncryptionKeys({ 'secure key': updatedQR.data.secureKey })
        }
      }
      if (searchedQRs.length > 0) await searchQR()
      else await fetchDynamicQRs()
      setTimeout(async () => {
        await fetchPlanDetails()
      }, 2000) // Wait 2 seconds for backend to update the usage records
      setEditQRId('')
      setCancelQRId('')
      setModifiedQRData(undefined)
      setSnackbarData({ type: 'success', message: 'QR updated successfully' })
    } catch (err) {
      console.error('Error occurred while updating dynamic QR', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while updating dynamic QR'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const searchQR = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const payload: Record<string, any> = {
        'subscription-id': subId,
        data: searchData.text
      }
      if (searchData.secureOnly) payload.secured_only = true
      if (searchData.sourceOnly) payload.sources_only = true

      const response = await api.get('/api/qr/dynamic-qr', { headers: payload })
      setSearchedQRs(response.data)
      if (Array.isArray(response.data) && response.data.length === 0) {
        setSnackbarData({ type: 'log', message: 'No dynamic QRs found' })
      }
    } catch (err) {
      console.error('Error occurred while fetching search results', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while searching dynamic QR'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPlanDetails = async (): Promise<void> => {
    try {
      const planDetailsResponse = await api.get('/api/stripe/priceId', {
        headers: { 'subscription-id': subId }
      })
      const planDetails = planDetailsResponse.data?.data
      const currentUsageResponse = await api.get('/api/usage', {
        headers: { 'subscription-id': subId }
      })
      const currentUsage = currentUsageResponse.data?.data
      const emptyPlanObject: Record<string, number> = {}
      Object.keys(planDetails).forEach((pi) => {
        emptyPlanObject[pi] = 0
      })
      const used = {
        ...emptyPlanObject,
        apiCalls: currentUsageResponse.data?.usageCount || 0,
        ...currentUsage
      }
      const remaining = Object.entries(planDetails).reduce(
        (result: any, [key, value]) => {
          result[key] = parseInt(value as string, 10) - parseInt(used[key], 10)
          return result
        },
        {}
      )
      setStats({
        total: planDetails,
        used,
        remaining
      })
    } catch (err) {
      console.error('Error occurred while fetching plan details', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching plan details'
      })
    }
  }

  const fetchSubDetails = async (): Promise<void> => {
    try {
      const response = await api.get('/api/subscription', {
        headers: {
          'subscription-id': subId
        }
      })
      const subDetail = { ...response.data }
      if (response.data?.apiName) {
        const splittedApiName = response.data?.apiName.split('.')

        if (splittedApiName.length < 2) throw new Error('Invalid API name')

        const currentPlan = PLANS[splittedApiName[0] as keyof typeof PLANS]
        const currentPlanDetails = currentPlan.planDetails

        subDetail.productName = currentPlan.name
        subDetail.planName = currentPlanDetails.find(
          (pd) => pd.id === splittedApiName[1]
        )?.heading
      }
      setSubDetails(subDetail)
    } catch (err) {
      console.error('Error occurred while fetching subscription details', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching subscription details'
      })
    }
  }

  const getDecryptedData = async (qrUrl: string): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.get('/api/qr/encrypted-data', {
        headers: {
          id: qrUrl,
          password: encryptionData.password,
          'encryption-key': btoa(encryptionData?.['encryption-key']),
          iv: btoa(encryptionData.iv)
        }
      })
      const decryptedData: {
        data: string
        rawData: string
        files: string[]
      } = response.data
      setModifiedQRData((prev) =>
        prev
          ? {
              ...prev,
              data: decryptedData.rawData,
              rawData: decryptedData.rawData,
              files: decryptedData.files,
              isSecured: false
            }
          : undefined
      )
    } catch (err: any) {
      console.error('Issue occurred while fetching decrypted data', err)
      setSnackbarData({
        type: 'error',
        message: 'Issue occurred while fetching decrypted data'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const downloadQRImage = async (
    qr: DynamicQR & { source?: string }
  ): Promise<void> => {
    setIsLoading(true)
    try {
      const imageString = await api.get('/api/qr/image', {
        headers: {
          'subscription-id': subId,
          qrid: qr.id,
          source: qr.source
        }
      })

      const returnType: string = imageString.data?.returntype

      const blob =
        returnType === 'svg'
          ? new Blob([imageString.data?.text], { type: 'image/svg+xml' })
          : base64toBlob(imageString.data?.text)
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = url
      a.download = `${qr.id}${qr.source ? `_${qr.source}` : ''}.${returnType}`
      document.body.appendChild(a)
      a.click()

      // Clean up
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    } catch (err: any) {
      console.error('Issue occurred while fetching QR image', err)
      setSnackbarData({
        type: 'error',
        message: 'Issue occurred while fetching QR image'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAll = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await Promise.all([
        fetchSubDetails(),
        fetchDynamicQRs(),
        fetchPlanDetails()
      ])
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSecureChange = (secureType: SecureType): void => {
    const secureArray =
      modifiedQRData && Array.isArray(modifiedQRData?.secure)
        ? modifiedQRData.secure
        : []
    const index = secureArray.indexOf(secureType)

    if (index !== -1) {
      secureArray.splice(index, 1)
    } else {
      secureArray.push(secureType)
    }
    setModifiedQRData((prev: any) => ({ ...prev, secure: secureArray }))
  }

  const handleFileDownload = async (
    qrId: string,
    file: string
  ): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.get('/api/qr/file', {
        headers: {
          'subscription-id': subId,
          'qr-id': qrId,
          'file-name': file
        },
        responseType: 'blob'
      })

      const link = document.createElement('a')
      link.href = URL.createObjectURL(response.data)
      link.download = file
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      if (downloadedFiles?.[qrId])
        setDownloadedFiles({ [qrId]: [...downloadedFiles[qrId], file] })
      else setDownloadedFiles({ [qrId]: [file] })
    } catch (err) {
      console.error('Error occurred while downloading file', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while downloading file'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const resetEncryptedQR = (): void => {
    setModifiedQRData((prev: any) => ({
      ...prev,
      secure: [],
      password: undefined,
      encryptionType: undefined,
      expiry: undefined,
      isEncrypted: false,
      isPasswordProtected: false,
      isSecured: false,
      files: []
    }))
    setEncryptionData(emptyEncryptionData)
    setResetQRId('')
  }

  useEffect(() => {
    if (isSubIdValid) fetchAll()
  }, [subId])

  return (
    <div style={{ padding: '40px' }}>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
        autoClose={false}
      />
      <div className="manageAPILeftContainer">
        <h2 style={{ marginBottom: '40px' }}>Details</h2>
        <div className="detailsContainer">
          <div>
            <span>Product name:</span>
            <span>{subDetails?.productName}</span>
          </div>
          <div>
            <span>Plan name:</span>
            <span>{subDetails?.planName}</span>
          </div>
          <div>
            <span>Status:</span>
            <span>
              {subDetails?.status ? statusMapping[subDetails.status].name : ''}
            </span>
          </div>
          <div>
            <span>Subscribed from:</span>
            <span>
              {subDetails?.validFrom
                ? formatDate(parseFloat(subDetails.validFrom))
                : ''}
            </span>
          </div>
        </div>
        <h2 style={{ margin: '40px 0' }}>Manage dynamic QRs</h2>
        <div className="searchContainer">
          <SearchInput
            value={searchData.text}
            onChange={(e) =>
              setSearchData({ ...searchData, text: e.target.value })
            }
            style={{
              display: 'inline-block',
              marginRight: '20px',
              width: '350px'
            }}
          />
          <PrimaryButton
            onClick={async () => await searchQR()}
            disabled={
              !searchData.text &&
              !searchData.secureOnly &&
              !searchData.sourceOnly
            }
          >
            Search
          </PrimaryButton>
          <br />
          <br />
          <Checkbox
            label="Secured only"
            checked={!!searchData.secureOnly}
            onChange={(e) =>
              setSearchData({ ...searchData, secureOnly: e.target.checked })
            }
            style={{ marginRight: '20px' }}
          />
          <Checkbox
            label="Sourced only"
            checked={!!searchData.sourceOnly}
            onChange={(e) =>
              setSearchData({ ...searchData, sourceOnly: e.target.checked })
            }
          />
          {searchedQRs.length ? (
            <div
              className="filterIconContainer"
              onClick={() => {
                setSearchedQRs([])
                setSearchData(emptySearchData)
              }}
            >
              <FilterIcon />
              <XCircleIcon className="filterXIcon" />
            </div>
          ) : null}
        </div>
        {(searchedQRs.length > 0
          ? searchedQRs
          : dynamicQRs.length > 0
          ? dynamicQRs
          : []
        ).map((qr, idx) => {
          const isEditable = editQRId === qr.id
          const hasSources = qr.sources?.length > 0
          return (
            <div
              key={idx}
              className="qrContainer"
              style={
                isEditable ? { borderColor: 'var(--er-primary)' } : undefined
              }
            >
              <span className="idSpan">
                <span>ID: </span>
                <span>{qr.id}</span>
              </span>
              <div className="buttonsContainer">
                <PrimaryButton
                  style={{ margin: '0 30px 10px 0', verticalAlign: 'middle' }}
                  onClick={() =>
                    window.open(
                      CLIENT_SITE_URL +
                        '/analytics/qr/' +
                        btoa(qr.subscriptionId) +
                        '/' +
                        btoa(qr.id),
                      '_blank'
                    )
                  }
                >
                  <ExtLinkIcon />
                  <span>Analytics</span>
                </PrimaryButton>
                {hasSources ? (
                  <div
                    className="multipleSourceDownloadContainer"
                    onMouseEnter={() => setShowDownloadDropdown(qr.id)}
                    onMouseLeave={() => setShowDownloadDropdown(undefined)}
                  >
                    <DownloadImage
                      style={{
                        marginRight: '20px',
                        stroke: 'var(--er-infoDark)'
                      }}
                    />
                    <br />
                    {qr.id === showDownloadDropdown ? (
                      <ul className="multipleSourceDownloadList">
                        {qr.sources.map((source) => (
                          <li
                            key={source}
                            onClick={async () =>
                              await downloadQRImage({ ...qr, source })
                            }
                          >
                            {source}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                ) : (
                  <DownloadImage
                    style={{
                      marginRight: '20px',
                      stroke: 'var(--er-infoDark)',
                      cursor: 'pointer'
                    }}
                    onClick={async () => await downloadQRImage(qr)}
                  />
                )}
                {isEditable ? (
                  <PrimaryButton
                    style={{
                      verticalAlign: 'middle',
                      height: '44px',
                      marginRight: 0
                    }}
                    onClick={async () => await updateQR()}
                  >
                    Save
                  </PrimaryButton>
                ) : (
                  <EditIcon
                    onClick={() => {
                      setModifiedQRData(qr)
                      setEditQRId(qr.id)
                      setDownloadedFiles(undefined)
                    }}
                    style={{
                      color: 'var(--er-primary)',
                      marginTop: '4px',
                      cursor: 'pointer'
                    }}
                  />
                )}
                <TrashIcon
                  onClick={() => setCancelQRId(qr.id)}
                  style={{
                    color: 'var(--er-red)',
                    marginLeft: '20px',
                    marginTop: '4px',
                    cursor: 'pointer'
                  }}
                />
                <ConfirmDialog
                  open={cancelQRId === qr.id}
                  onClose={() => setCancelQRId('')}
                  title="Are you sure you want to delete this QR ?"
                  text=""
                  submitBtnName="Yes"
                  onSubmit={async () => await updateQR(true)}
                />
              </div>
              <div style={{ marginTop: '28px' }}>
                <div className="featureContainer">
                  <CheckIcon style={{ color: 'var(--er-green)' }} />
                  <span>Dynamic</span>
                </div>
                <div className="featureContainer">
                  {qr.secure ? (
                    <CheckIcon style={{ color: 'var(--er-green)' }} />
                  ) : (
                    <XCircleIcon />
                  )}
                  <span>Secured</span>
                </div>
                <div className="featureContainer">
                  {qr.sources?.length ? (
                    <CheckIcon style={{ color: 'var(--er-green)' }} />
                  ) : (
                    <XCircleIcon />
                  )}
                  <span>Source</span>
                </div>
                <div className="featureContainer">
                  {qr.files?.length ? (
                    <CheckIcon style={{ color: 'var(--er-green)' }} />
                  ) : (
                    <XCircleIcon />
                  )}
                  <span>Files</span>
                </div>
              </div>
              <div
                className={
                  stats.remaining?.securedQr > 0 ? '' : 'disableSecure'
                }
                style={{ margin: '0 20px' }}
              >
                {modifiedQRData?.isSecured && modifiedQRData.id === qr.id ? (
                  <InputSecureKeysForFetching
                    qr={qr}
                    encryptionData={encryptionData}
                    resetQRId={resetQRId}
                    setResetQRId={setResetQRId}
                    resetEncryptedQR={resetEncryptedQR}
                    getDecryptedData={getDecryptedData}
                    handleEncryptionDataChange={handleEncryptionDataChange}
                  />
                ) : isEditable ? (
                  <InputSecureKeysForPosting
                    modifiedQRData={modifiedQRData}
                    handleOptionChange={handleOptionChange}
                    handleSecureChange={handleSecureChange}
                  />
                ) : null}
              </div>
              <div className={!isEditable ? 'disableEdit' : ''}>
                {(modifiedQRData &&
                  !modifiedQRData?.isSecured &&
                  modifiedQRData.id === qr.id) ||
                !qr?.isSecured ? (
                  <>
                    <InputFieldRenderer
                      qrOptions={isEditable ? modifiedQRData || qr : qr}
                      isValidated={isValidated}
                      handleOptionChange={handleOptionChange}
                      errors={errors}
                    />
                    {(modifiedQRData?.files &&
                      modifiedQRData?.files.length > 0) ||
                    (modifiedQRData?.newFiles &&
                      modifiedQRData?.newFiles.length > 0) ? (
                      <div className="filesSection">
                        <h5>Files included -</h5>
                        {modifiedQRData?.files?.map((file, i) => {
                          return (
                            <div className="filesContainer" key={i}>
                              <span>{file}</span>
                              <DownloadImage
                                onClick={async () =>
                                  await handleFileDownload(qr.id, file)
                                }
                                style={{
                                  stroke: downloadedFiles?.[qr.id]?.includes(
                                    file
                                  )
                                    ? 'var(--er-infoBorder)'
                                    : 'var(--er-infoDark)',
                                  cursor: 'pointer'
                                }}
                              />
                              <TrashIcon
                                style={{
                                  stroke: 'var(--er-red)',
                                  cursor: 'pointer'
                                }}
                                onClick={() =>
                                  setModifiedQRData({
                                    ...modifiedQRData,
                                    files: modifiedQRData.files?.filter(
                                      (f) => f !== file
                                    )
                                  })
                                }
                              />
                            </div>
                          )
                        })}
                        {modifiedQRData?.newFiles?.map((file, i) => {
                          return (
                            <div className="filesContainer" key={i}>
                              <span>{file.name}</span>
                              <div></div>
                              <TrashIcon
                                style={{
                                  stroke: 'var(--er-red)',
                                  cursor: 'pointer'
                                }}
                                onClick={() =>
                                  setModifiedQRData({
                                    ...modifiedQRData,
                                    newFiles: modifiedQRData.newFiles?.filter(
                                      (f) => f !== file
                                    )
                                  })
                                }
                              />
                            </div>
                          )
                        })}
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
              {qr?.isSecured && modifiedQRData?.id !== qr.id ? (
                <div
                  className="unlockDiv"
                  onClick={() => {
                    setModifiedQRData(qr)
                    setEditQRId(qr.id)
                  }}
                >
                  <UnlockIcon />
                  <br />
                  <br />
                  <span>{`To access/modify the QR data, you will need to enter the ${
                    qr.isEncrypted && qr.isPasswordProtected
                      ? qr.encryptionType === 'AES'
                        ? 'password, encryption key and IV'
                        : 'password and encryption key'
                      : qr.isEncrypted
                      ? qr.encryptionType === 'AES'
                        ? 'encryption key and IV'
                        : 'encryption key'
                      : qr.isPasswordProtected
                      ? 'password'
                      : ''
                  } for secure authentication.`}</span>
                </div>
              ) : null}
            </div>
          )
        })}
        {dynamicQRs.length === 0 && !isLoading ? <NoItemsFound /> : null}
      </div>
      <div style={{ width: '40%', minWidth: '400px', display: 'inline-block' }}>
        <h2 style={{ marginBottom: '40px' }}>Usage</h2>
        {Object.keys(stats.total).map((k, idx) => {
          if (k === 'scans' && !!stats.total[k]) return null
          return (
            <div key={idx}>
              <br />
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              <span className="statSpan">{`${
                statsMapping[k as keyof typeof statsMapping]
              } :`}</span>
              <Slider
                readOnly
                value={stats.used[k]}
                max={stats.total[k]}
                sliderColor={
                  stats.total[k] * 0.8 <= stats.used[k] ? 'red' : 'primary'
                }
                valueClassName="sliderValueClass"
              />
            </div>
          )
        })}
      </div>
      <Dialog
        open={Object.keys(generatedEncryptionKeys).length > 0}
        onClose={() => setGeneratedEncryptionKeys({})}
      >
        {Object.entries(generatedEncryptionKeys).map(([k, v]) => {
          return (
            <>
              <h4 style={{ marginTop: '10px' }}>
                Here is the newly generated {k}:
              </h4>
              <span>
                Please make sure to store it securely, as it will be necessary
                for making changes to or accessing this QR code.
              </span>
              <CopyText
                style={{ marginTop: '30px' }}
                key={k}
                text={v as string}
              />
            </>
          )
        })}
      </Dialog>
    </div>
  )
}

export default ManageAPI
