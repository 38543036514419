import React, { useEffect, useState } from 'react'
import type { HTMLAttributes } from 'react'
import { ReactComponent as MoreIcon } from '../../assets/images/more-vertical.svg'
import './MoreOptions.css'

interface MoreOptionsProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean
  onClick: (...args: any) => any
  defaultId?: string | number
  iconClassName?: string
  containerClassName?: string
}

let counter = 0

function MoreOptions({
  open,
  onClick,
  defaultId,
  iconClassName,
  containerClassName,
  ...props
}: MoreOptionsProps): JSX.Element {
  const [buttonId] = useState(() => {
    counter++
    return `more-option-id-${counter}`
  }) // To have unique html id
  const handleOutsideClick = (e: any): void => {
    const dropdownElement = document.getElementById(buttonId)
    if (dropdownElement && !dropdownElement.contains(e.target)) {
      onClick(defaultId ?? null)
    }
  }

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleOutsideClick)
    } else {
      document.removeEventListener('click', handleOutsideClick)
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [open])

  return (
    <div {...props} id={buttonId} style={{ position: 'relative' }}>
      <MoreIcon className="moreOptionIcon" onClick={onClick} />
      {open ? (
        <div className={`moreOptionContainer ${containerClassName ?? ''}`}>
          {props.children}
        </div>
      ) : null}
    </div>
  )
}

export default MoreOptions
