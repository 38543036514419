import React, { useState } from 'react'
import { ReactComponent as CopyIcon } from '../../assets/images/copy.svg'
import './CopyText.css'

interface CopyTextProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string
}

function CopyText({ text, className, ...props }: CopyTextProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false)

  const copyText = async (): Promise<void> => {
    setIsVisible(true)
    await navigator.clipboard.writeText(text)
    const timeoutDuration = 1500

    const timeoutId = setTimeout(() => {
      setIsVisible(false)
      clearTimeout(timeoutId)
    }, timeoutDuration)
  }

  return (
    <div className={`copyTextContainer ${className ?? ''}`} {...props}>
      <CopyIcon onClick={async () => await copyText()} />
      <span className={`copiedSpan ${isVisible ? 'showSpan' : 'hideSpan'}`}>
        Copied!
      </span>
      <div className='copyText'>{text}</div>
    </div>
  )
}

export default CopyText
