import React from 'react'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
import { PAYPAL_CLIENT_ID, PAYPAL_PLAN_ID } from '../../Config'

const PayPalSubscription = (): JSX.Element => {
  return (
    <PayPalScriptProvider
      options={{
        clientId: PAYPAL_CLIENT_ID,
        vault: true,
        intent: 'subscription'
      }}
    >
      <PayPalButtons
        createSubscription={async (data, actions) => {
          console.log('Inside createSubscription', data, actions)
          return await actions.subscription.create({
            plan_id: PAYPAL_PLAN_ID
          })
        }}
        onApprove={async (data, actions) => {
          alert(`Subscription successful! ID: ${data.subscriptionID as string}`)
        }}
        onError={(err) => {
          console.error('Subscription creation error: ', err)
        }}
      />
    </PayPalScriptProvider>
  )
}

export default PayPalSubscription
