import React from 'react'
import SadBox from '../../../assets/images/sad-box.png'

interface NoItemsFoundProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  note?: string
}

const NoItemsFound = ({
  title = 'No items found',
  note,
  style,
  ...props
}: NoItemsFoundProps): JSX.Element => {
  return (
    <div style={{ textAlign: 'center', ...style }} {...props}>
      <img src={SadBox} style={{ width: '250px' }} />
      <h4 style={{ color: 'var(--er-greyDark)', marginTop: '0px' }}>{title}</h4>
      {note ? <p>{note}</p> : null}
    </div>
  )
}

export default NoItemsFound
