const setWithExpiry = (key: string, value: string, ttl: number): void => {
  const item = {
    value,
    expiry: new Date().getTime() + ttl
  }

  localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key: string): any => {
  const itemString = localStorage.getItem(key)

  if (!itemString) {
    return null
  }

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export { setWithExpiry, getWithExpiry }
