import React, { type ReactNode } from 'react'
import { getWithExpiry, setWithExpiry } from '../utils/Storage'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
  error: Error | null
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: null
    }
  }

  static getDerivedStateFromError(error: Error): any {
    return {
      hasError: true,
      error
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(`Error: ${error.message} ${errorInfo.componentStack as string}`)
  }

  render(): ReactNode {
    const { hasError, error } = this.state
    const { children } = this.props

    const chunkFailedMessage = /Loading [CSS ]*chunk [\d]+ failed./

    if (error?.message && chunkFailedMessage.test(error.message)) {
      console.info('Handle chunk load failure')

      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000)
        window.location.reload()
      }
    }

    if (hasError && process.env.NODE_ENV !== 'development') {
      window.location.href = '/'
    }

    return children
  }
}

export default ErrorBoundary
