import React, { useState } from 'react'
import { CLIENT_SITE_NAME, SERVER_URL } from '../../Config'
import { api, updateHeaders } from '../../utils/AxiosConfig'
import { getCookies, setCookies } from '../../utils/Cookies'
import { useLocation, useNavigate } from 'react-router-dom'
import { Validate } from '../../utils/Validation'
import { useAuth } from '../../common/context/Context'
import LoggedIn from '../../common/loggedIn/LoggedIn'
import {
  PrimaryButton,
  SecondaryButton
} from '../../common/elements/CommonElements'
import { Checkbox } from '../../common/elements/InputControls'
import Input from '../../common/elements/Input'
import Loader from '../../common/misc/loader/Loader'
import { emptySnackbarData } from '../dashboard/Dashboard'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import GoogleLoginButton from './GoogleLogin'
import { type User } from '../../common/types/Types'
import MultiFactorAuth from '../../common/MFA/MultiFactorAuth'
import loginNRegImg from '../../assets/images/login-n-reg.png'
import IconsBG from '../../assets/images/icons-bg.png'
import styles from './Login.module.css'

export interface LoginFormData {
  email: string
  password: string
}

export interface LoginErrors {
  emailValue?: boolean
  emailMessage?: string
  passwordValue?: boolean
  passwordMessage?: string
  isNotValid?: boolean
}

interface LoginProps {
  loginBoxOnly?: boolean
}

const Login = ({ loginBoxOnly = false }: LoginProps): JSX.Element => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState<LoginErrors>({})
  const [rememberUser, setRememberUser] = useState(false)
  const navigate = useNavigate()
  const { isUserLoggedIn, dispatch } = useAuth()
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [emailUpdatesOptIn, setEmailUpdatesOptIn] = useState(false)
  const [showEmailOptInPopUp, setShowEmailOptInPopUp] = useState(false)

  const [hasSecret, setHasSecret] = useState(false)
  const [clientId, setClientId] = useState('')

  const [userData, setUserData] = useState<User>()

  const checkAndSet = (
    key: string,
    value: string,
    verify: boolean = true
  ): void => {
    setFormData({ ...formData, [key]: value })
    if (verify) {
      setErrors(Validate(errors, formData, key, value))
    }
  }

  const checkForMFA = (user: User): void => {
    setClientId(user?.clientId)
    if (user.hasSecret) {
      setHasSecret(true)
      setUserData(user)
    } else handleSuccessfulLogin(user)
  }

  const handleSuccessfulLogin = (incommingUser: User): void => {
    const user = userData ? { ...userData, ...incommingUser } : incommingUser
    const filteredUserData = { ...user }
    delete filteredUserData.token
    delete filteredUserData.img
    if (rememberUser) {
      setCookies('erjwt', user.token as string)
      setCookies('refreshjwt', user.refreshToken as string)
      setCookies('user', JSON.stringify(filteredUserData))
      if (user.img) localStorage.setItem('img', user.img)
    } else {
      sessionStorage.setItem('erjwt', user.token as string)
      sessionStorage.setItem('refreshjwt', user.refreshToken as string)
      sessionStorage.setItem('user', JSON.stringify(filteredUserData))
      if (user.img) sessionStorage.setItem('img', user.img)
    }
    updateHeaders()
    if ((user as any).isNewUser) {
      // Don't dispatch here
      setEmailUpdatesOptIn(false)
      return setShowEmailOptInPopUp(true)
    }
    dispatch({ type: 'USER', payload: user })
    if (location.state?.from) {
      navigate(location.state.from, {
        state: location.state
      })
    } else {
      navigate('/', {
        state: location.state
      })
    }
  }

  const loginUser = async (): Promise<void> => {
    if (!formData.email || !formData.password) {
      console.error('Invalid form data')
    }
    setIsLoading(true)
    try {
      const user = await api.post(SERVER_URL + '/api/auth/login', {
        email: formData.email,
        password: formData.password,
        rememberMe: rememberUser
      })
      checkForMFA(user.data)
    } catch (e: any) {
      if (e?.response?.status === 401) {
        if (e?.response?.data === 'Google login required') {
          return setSnackbarData({
            type: 'error',
            message:
              "Oops! This account is linked to Google. Please log in using 'Sign-in with Google'."
          })
        }
        return setSnackbarData({
          type: 'error',
          message:
            'Invalid email or password. Please check your credentials and try again.'
        })
      }
      if (
        e?.response?.status === 403 &&
        e?.response?.data === 'Account is deactivated.'
      )
        return setSnackbarData({
          type: 'error',
          message:
            'Your account is deactivated. Kindly reach out to our support team for assistance.'
        })
      setSnackbarData({ type: 'error', message: e.message })
    } finally {
      setIsLoading(false)
    }
  }

  const resetPassword = async (): Promise<void> => {
    if (!formData.email)
      return setErrors({
        emailMessage: 'Please enter email associated with the account',
        emailValue: true
      })
    setIsLoading(true)
    try {
      await api.post('/api/auth/reset-password', { email: formData.email })
      setSnackbarData({
        type: 'success',
        message:
          'If an account is associated with this email, a recovery link will be sent shortly.'
      })
    } catch (err) {
      console.error('Error occurred while resetting password.', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while resetting password.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleRememberMeChange = (e: any): void => {
    setRememberUser(e.target.checked)
  }

  const handleOptInUpdate = async (): Promise<void> => {
    // profile update code
    setIsLoading(true)
    try {
      const user = await api.patch('/api/profile', { emailUpdatesOptIn })
      const filteredUserData = { ...user.data }
      delete filteredUserData.token

      dispatch({ type: 'USER', payload: user.data })
      if (getCookies('user') && getCookies('erjwt')) {
        setCookies('erjwt', user.data.token)
        setCookies('refreshjwt', user.data.refreshToken)
        setCookies('user', filteredUserData)
      } else if (
        sessionStorage.getItem('user') &&
        sessionStorage.getItem('erjwt')
      ) {
        sessionStorage.setItem('user', JSON.stringify(filteredUserData))
        sessionStorage.setItem('erjwt', user.data.token)
        sessionStorage.setItem('refreshjwt', user.data.refreshToken)
      }
      updateHeaders()
      setSnackbarData({
        type: 'success',
        message: 'Preference updated successfully'
      })
      if (location.state?.from) {
        navigate(location.state.from, {
          state: location.state
        })
      } else {
        navigate('/', {
          state: location.state
        })
      }
    } catch (e: any) {
      console.error('Error occurred while updating your preference.', e.message)
      setSnackbarData({ type: 'error', message: e.message })
    } finally {
      setIsLoading(false)
    }
  }

  if (loginBoxOnly)
    return (
      <div className={styles.loginBox}>
        <PrimaryButton onClick={() => navigate('/login')}>
          Sign in
        </PrimaryButton>

        <span style={{ padding: '20px' }}>Or</span>

        <SecondaryButton onClick={() => navigate('/register')}>
          Sign up
        </SecondaryButton>
        <br/>
        <br/>
        <GoogleLoginButton
          handleSuccessfulLogin={checkForMFA}
          setIsLoading={setIsLoading}
          setSnackbarData={setSnackbarData}
        />
      </div>
    )

  return showEmailOptInPopUp ? (
    <div style={{ textAlign: 'center', paddingTop: '200px' }}>
      <Checkbox
        label={`I would like to receive emails from ${CLIENT_SITE_NAME} about product and feature updates.`}
        checked={emailUpdatesOptIn}
        onChange={(e) => setEmailUpdatesOptIn(e.target.checked)}
        style={{ width: '100%' }}
      />
      <br />
      <br />
      <br />
      <PrimaryButton onClick={handleOptInUpdate}>Continue</PrimaryButton>
    </div>
  ) : isUserLoggedIn && !showEmailOptInPopUp ? (
    <LoggedIn />
  ) : (
    <>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      {!hasSecret ? (
        <div
          style={{
            background: 'antiqueWhite',
            minHeight: 'calc(100vh - 440px)',
            position: 'relative',
            backgroundImage: `url(${IconsBG as string})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'repeat-y'
          }}
        >
          <div
            style={{
              textAlign: 'center',
              width: '850px',
              padding: '100px 0px 100px calc(50% - 400px)'
            }}
          >
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                width: '42%',
                height: '450px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
              }}
            >
              <img
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '6px 0px 0px 6px'
                }}
                alt="Vector art"
                src={loginNRegImg}
              />
            </div>
            <div
              style={{
                display: 'inline-block',
                height: '500px',
                width: 'calc(58% - 60px)',
                backgroundColor: 'white',
                verticalAlign: 'middle',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                borderRadius: '6px',
                textAlign: 'center',
                padding: '0px 30px'
              }}
            >
              <h2 style={{ padding: '10px' }}>Sign in</h2>
              <div>
                <Input
                  label="Email"
                  style={{ margin: '40px 0px' }}
                  onChange={(e: any) => {
                    checkAndSet('email', e.target.value)
                  }}
                  value={formData?.email}
                  error={
                    typeof errors.emailValue !== 'undefined'
                      ? errors.emailMessage
                      : ''
                  }
                />
                <Input
                  label="Password"
                  type="password"
                  style={{ margin: '40px 0px 6px 0px' }}
                  onChange={(e: any) => {
                    setFormData({ ...formData, password: e.target.value })
                  }}
                  value={formData?.password}
                  error={
                    typeof errors.passwordValue !== 'undefined'
                      ? errors.passwordMessage
                      : ''
                  }
                />
                <a
                  onClick={resetPassword}
                  className={styles['forget-password-a']}
                >
                  Forget password ?
                </a>
              </div>
              <br />
              <Checkbox
                label={'Remember me'}
                className={styles['remember-me']}
                onChange={handleRememberMeChange}
              />
              <br />
              <br />
              <div>
                <PrimaryButton
                  onClick={async () => {
                    await loginUser().catch()
                  }}
                  disabled={
                    !!(
                      !formData?.email ||
                      !formData?.password ||
                      formData?.password.length < 8 ||
                      errors.isNotValid
                    )
                  }
                >
                  Sign in
                </PrimaryButton>

                <span style={{ padding: '20px' }}>Or</span>

                <SecondaryButton onClick={() => navigate('/register')}>
                  Sign up
                </SecondaryButton>
                <GoogleLoginButton
                  handleSuccessfulLogin={checkForMFA}
                  setIsLoading={setIsLoading}
                  setSnackbarData={setSnackbarData}
                />
              </div>
            </div>
          </div>
        </div>
      ) : clientId ? (
        <MultiFactorAuth
          clientId={clientId}
          rememberMe={rememberUser}
          onFailure={(err) =>
            err?.response?.status !== 401
              ? setSnackbarData({
                  type: 'error',
                  message:
                    'Error occurred while authenticating 2FA. Please retry after sometime or contact support.'
                })
              : setSnackbarData({
                  type: 'error',
                  message:
                    'Two-Factor Authentication failed. Please check your code and try again.'
                })
          }
          onSuccess={(user) => handleSuccessfulLogin(user)}
        />
      ) : null}
    </>
  )
}

export default Login
