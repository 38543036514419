import React, { useEffect, useState } from 'react'
import Input from '../../common/elements/Input'
import {
  PrimaryButton,
  SecondaryButton
} from '../../common/elements/CommonElements'
import { api } from '../../utils/AxiosConfig'
import Loader from '../../common/misc/loader/Loader'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import { useLocation, useNavigate } from 'react-router-dom'
import { wait } from '../../utils/CommonFunctions'
import { useAuth } from '../../common/context/Context'
import { getCookies, setCookies } from '../../utils/Cookies'

const VerifyEmail = ({ propEmail, propNewEmail, submit }: { propEmail?: string, propNewEmail?: string, submit?: (verified: boolean) => void }): JSX.Element => {
  const [verificationCode, setVerificationCode] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const navigate = useNavigate()
  const location = useLocation()

  const { user, dispatch } = useAuth()

  const email = location.state?.email || propEmail
  const sendEmail = location.state?.sendEmail

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.post('/api/auth/verify-email', {
        email,
        newEmail: propNewEmail,
        verificationCode
      })

      dispatch({ type: 'USER', payload: { ...user, ...response.data } })
      if (getCookies('user') && getCookies('erjwt')) {
        setCookies('erjwt', response.data.token)
      } else if (
        sessionStorage.getItem('user') &&
        sessionStorage.getItem('erjwt')
      ) {
        sessionStorage.setItem('erjwt', response.data.token)
      }

      setIsLoading(false)
      setSnackbarData({
        type: 'success',
        message: 'Email verified successfully'
      })
      if (submit) {
        return submit(true)
      }
      await wait(2500) // Wait before redirection
      if (location.state?.from) {
        // Todo - Low - We might not require this
        navigate(location.state.from, {
          state: location.state
        })
      } else {
        navigate('/', {
          state: location.state
        })
      }
    } catch (err: any) {
      console.error('Error occurred ', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while verifying email'
      })
      // if (submit) {
      //   submit(false)
      // }
    } finally {
      setIsLoading(false)
    }
  }

  const resendEmail = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await api.post('/api/auth/resend-email', {
        email,
        newEmail: propNewEmail
      })
    } catch (err: any) {
      console.error('Error occurred ', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while resending email'
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (sendEmail && sendEmail === true) {
      resendEmail()
    }
  }, sendEmail)

  return (
    <div
      style={propEmail ? undefined : {
        backgroundColor: 'white',
        maxWidth: '550px',
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderRadius: '6px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, calc(-50% - 125px))',
        padding: '30px'
      }}
    >
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <h3>Email verification</h3>
      <Input
        label="Enter verification code"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <br />
      <br />
      <PrimaryButton onClick={handleSubmit} disabled={!verificationCode}>
        Submit
      </PrimaryButton>
      <br />
      <br />
      Not received mail?
      <br />
      <br />
      <SecondaryButton onClick={resendEmail}>Resend email</SecondaryButton>
    </div>
  )
}

export default VerifyEmail
