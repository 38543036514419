import React from 'react'
import { Route, Routes } from 'react-router-dom'
import QRRedirect from './QR.Redirect'
import QR from './QR'
import Generate from './QR.Generate'
import QRPlanSuite from './QR.PlanSuite'
import PaymentCompleted from '../../common/payment/PaymentCompleted'
import PageNotFound from '../pageNotFound/PageNotFound'

function QRApp(): JSX.Element {
  return (
      <Routes>
        <Route path="/generate" element={<Generate />} />
        <Route path="/generator" element={<QR />} />
        <Route path="/redirect/:id" element={<QRRedirect />} />
        <Route path="/plans" element={<QRPlanSuite />} />
        <Route path="/success" element={<PaymentCompleted />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
  )
}

export default QRApp
