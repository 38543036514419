import React, { type CSSProperties } from 'react'
import './SecondaryLoader.css'

const SecondaryLoader = (
  props: React.HTMLAttributes<HTMLDivElement> & {
    className?: string
    style?: CSSProperties
    center?: boolean
  }
): JSX.Element => {
  return (
    <div
      className={`${props.className as string} lds-ring ${
        props.center ? 'screen-center' : ''
      }`}
      style={props.style}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      {props.children}
    </div>
  )
}

export default SecondaryLoader
