import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as BellIcon } from '../../assets/images/bell.svg'
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import { ReactComponent as CheckIcon } from '../../assets/images/check-circle.svg'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import { ReactComponent as XCircleIcon } from '../../assets/images/x-circle.svg'

const MainContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0px);
  top: 150px;
  z-index: 20;
`

const SnackbarContainer = styled.div`
  width: 400px;
  border-radius: 8px;
  color: white;
  position: relative;

  div:first-of-type {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    width: calc(100% - 96px);
    padding: 20px;
  }

  div:nth-of-type(2) {
    height: 20px;
    width: 100%;
    background: white;
    opacity: 40%;
    transition: 0.3s width ease;
  }

  svg:first-of-type {
    scale: 0.8;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  svg:nth-of-type(2) {
    width: 36px;
    height: 36px;
    vertical-align: middle;
    padding-left: 20px;
  }

  h3 {
    margin: 0;
    font-size: 32px;
    margin-bottom: 5px;
  }
`

export type SnackbarTypes = 'log' | 'warn' | 'error' | 'info' | 'success'

export interface SnackbarBaseProps {
  type: SnackbarTypes
  message: string
  autoClose?: boolean
  title?: string
}

interface SnackbarProps
  extends React.HTMLAttributes<HTMLDivElement>,
    SnackbarBaseProps {
  autoClose?: boolean
  clear: () => void
}

const typeMapping = {
  log: {
    title: 'Notice',
    color: 'mediumslateblue',
    icon: <BellIcon />
  },
  warn: {
    title: 'Warning',
    color: 'var(--er-yellow)',
    icon: (
      <InfoIcon
        style={{
          transform: 'rotate(180deg)',
          paddingRight: '20px',
          paddingLeft: '0px'
        }}
      />
    )
  },
  error: {
    title: 'Error',
    color: 'var(--er-red)',
    icon: <XCircleIcon />
  },
  info: {
    title: 'Info',
    color: 'var(--er-infoDark)',
    icon: <InfoIcon />
  },
  success: {
    title: 'Success',
    color: 'var(--er-green)',
    icon: <CheckIcon />
  }
}

const Snackbar = ({
  type,
  title,
  autoClose = true,
  message,
  clear
}: SnackbarProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(true)
  const [timeoutPercentage, setTimeoutPercentage] = useState(100)

  useEffect(() => {
    if (!message) return setIsVisible(false)
    setIsVisible(true)
    let intervalId: any
    let timeoutId: any
    if (autoClose) {
      const timeoutDuration = 5000

      const updatePercentage = (): void => {
        const elapsedTime = Date.now() - startTime
        const remainingTime = Math.max(timeoutDuration - elapsedTime, 0)
        const percentage = (remainingTime / timeoutDuration) * 100
        setTimeoutPercentage(percentage)
      }

      const startTime = Date.now()
      updatePercentage()

      // Update the percentage every 50 milliseconds
      intervalId = setInterval(updatePercentage, 50)

      timeoutId = setTimeout(() => {
        setIsVisible(false)
        clear()
        clearInterval(intervalId)
      }, timeoutDuration)
    } else {
      setTimeoutPercentage(100)
    }
    return () => {
      clearInterval(intervalId)
      clearTimeout(timeoutId)
    }
  }, [type, title, message])

  return isVisible ? (
    <MainContainer>
      <SnackbarContainer style={{ background: typeMapping[type].color }}>
        <XIcon
          onClick={() => {
            clear()
            setIsVisible(false)
          }}
        />
        {typeMapping[type].icon}
        <div>
          <h3>{title || typeMapping[type].title}</h3>
          <span>{message}</span>
        </div>
        <div style={{ width: `${timeoutPercentage}%` }}></div>
      </SnackbarContainer>
    </MainContainer>
  ) : (
    <></>
  )
}

export default Snackbar
