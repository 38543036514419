import React from 'react'
import './QR.Documentation.css'

const QRDocumentaion = (): JSX.Element => {
  return (
    <div className="documentation">
      <h1>API Documentation</h1>

      <section>
        {/* <h2>Introduction</h2> */}
        <p>
          Welcome to the QR Code Generation API documentation. This API empowers
          you to generate customized QR codes for various use cases. Whether
          you&apos;re creating QR codes for URLs, text, vCards, or more, this
          API provides a flexible and feature-rich solution for your QR code
          generation needs.
        </p>
      </section>

      <section>
        <h2>Authentication</h2>
        <p>
          To access the QR Code Generation API, authentication is required.
          Provide the following details in the request headers:
        </p>
        <ul>
          <li>
            <strong>API Key (api-key header):</strong> Include your API key in
            the <code>api-key</code> header of your API requests.
            <pre>api-key: YOUR_API_KEY</pre>
          </li>
          <li>
            <strong>Client ID (client-id header):</strong> Include your client
            ID in the <code>client-id</code> header of your API requests.
            <pre>client-id: YOUR_CLIENT_ID</pre>
          </li>
        </ul>
      </section>

      <section>
        <h2>Getting Started</h2>
        <ol>
          <li>
            <strong>Login to your Account:</strong> Visit our landing page and
            log in to your account.
          </li>
          <li>
            <strong>Subscribe to Desired Product:</strong> Subscribe to the
            product that suits your requirements to get access to the QR Code
            Generation API.
          </li>
          <li>
            <strong>Generate API Key from Dashboard:</strong> Once subscribed,
            navigate to your dashboard to generate an API key. This key will be
            used for authenticating your requests.
          </li>
          <li>
            <strong>Explore API Documentation:</strong> Familiarize yourself
            with the comprehensive API documentation to make the most of the
            available features.
          </li>
        </ol>
      </section>

      <section>
        <h2>QR Code Settings</h2>
        <p>Customize advanced parameters for your QR codes:</p>
        <ul>
          <li>
            <strong>Type Number:</strong> Set the QR code version or size which
            determines the capacity of data it can hold.
          </li>
          <li>
            <strong>Mode:</strong> Choose from different modes such as numeric,
            alphanumeric, byte, or kanji to encode different types of data.
          </li>
          <li>
            <strong>Error Correction Level:</strong> Specify the level of error
            correction to enhance the QR code&apos;s ability to withstand damage or
            distortion.
          </li>
        </ul>
      </section>

      <section>
        <h2>Additional QR Code Settings</h2>
        <p>Enhance your QR codes with these customizable options:</p>
        <ul>
          <li>
            <strong>QR Types:</strong> Choose from a variety of QR types such as
            url, email, phone, SMS, geo, wifi, vcard, vcalendar, mecard.
          </li>
          <li>
            <strong>Margin:</strong> Set the margin space around the QR code on
            all sides to ensure proper readability.
          </li>
          <li>
            <strong>Add Image:</strong> Embed an image in the center of the QR
            code, fetched from a URL or URI for enhanced branding.
          </li>
        </ul>
      </section>

      <section>
        <h2>Cosmetic Settings</h2>
        <p>
          Enhance the visual appeal of your QR codes with cosmetic settings:
        </p>
        <ul>
          <li>
            <strong>Shape:</strong> Choose between square or circle shapes for
            your QR codes based on your design preferences.
          </li>
          <li>
            <strong>Dots and Colors:</strong> Define dot styles, colors, and
            gradients to create visually appealing QR codes.
          </li>
          <li>
            <strong>Corner Square Types:</strong> Select square, round, or
            extra-rounded corner types with customizable colors and gradients.
          </li>
          <li>
            <strong>Corner Dot Types:</strong> Choose square or round corner dot
            types with color and gradient options for a polished look.
          </li>
          <li>
            <strong>Background Color:</strong> Set the background color of the
            QR code, including gradients for a unique and branded appearance.
          </li>
        </ul>
      </section>

      <section>
        <h2>Advanced Features</h2>
        <p>
          Explore advanced features to take your QR codes to the next level:
        </p>
        <ul>
          <li>
            <strong>Source:</strong> Utilize an array of strings to generate
            different QR codes with the same data, allowing for effective
            engagement tracking.
          </li>
          <li>
            <strong>Secure QR Codes:</strong> Create password-protected QR codes
            for restricted access, encrypt QR codes for additional security, or
            set an expiry to control their lifespan.
          </li>
          <li>
            <strong>Dynamic QR Codes:</strong> Update QR code data dynamically,
            providing flexibility and real-time changes without the need to
            recreate the code.
          </li>
          <li>
            <strong>Bulk Processing:</strong> Efficiently process up to 50,000
            QR codes in a single request, streamlining operations for
            large-scale implementations.
          </li>
          <li>
            <strong>File Upload:</strong> Seamlessly upload and generate QR
            codes associated with your data, simplifying the process for
            handling diverse file formats and enhancing your QR code creation
            efficiency.
          </li>
        </ul>
      </section>

      <section>
        <h2>Error Handling</h2>
        <p>
          Comprehensive error handling is implemented in the API, providing
          detailed information for troubleshooting:
        </p>
        <ul>
          <li>
            <strong>Bad Request (400):</strong> Occurs when the request is
            malformed or missing required parameters. Check the request payload
            and ensure all necessary parameters are provided.
          </li>
          <li>
            <strong>Unauthorized (401):</strong> Indicates that the API key is
            invalid, missing, or lacks the necessary permissions. Ensure you are
            using a valid API key with the appropriate permissions.
          </li>
          <li>
            <strong>Forbidden (403):</strong> Access to the requested resource
            is forbidden. Check your API key permissions and ensure you have the
            necessary rights to perform the requested action.
          </li>
          <li>
            <strong>Not Found (404):</strong> The requested resource or endpoint
            does not exist. Review your API request and verify that the endpoint
            and resource path are correct.
          </li>
          <li>
            <strong>Too Many Requests (429):</strong> Throttling error
            indicating that the rate limit has been exceeded. Developers should
            reduce the request rate or implement backoff strategies to avoid
            this error.
          </li>
          <li>
            <strong>Internal Server Error (500):</strong> Indicates unexpected
            server-side issues. Developers should check their request payload
            and retry if the issue persists.
          </li>
        </ul>
      </section>

      <section>
        <h2>Security Considerations</h2>
        <p>
          Ensuring secure interactions with the QR Code Generation API is
          crucial. Follow these security considerations to protect your data and
          maintain a secure integration:
        </p>
        <ul>
          <li>
            <strong>Use HTTPS:</strong> Always make API requests using HTTPS to
            encrypt data in transit and enhance security. This prevents
            potential eavesdropping and man-in-the-middle attacks.
          </li>
          <li>
            <strong>Protect API Key and Client ID:</strong> Keep your API key
            and client ID confidential. Avoid exposing them in public
            repositories or client-side code. Treat these credentials with the
            same level of sensitivity as you would for any other sensitive
            information.
          </li>
          <li>
            <strong>Data Sensitivity:</strong> Be mindful of the data included
            in QR codes, especially when using secure features such as password
            protection or encryption. Avoid including sensitive information in
            QR codes that may be exposed unintentionally.
          </li>
          <li>
            <strong>Client-Side Security:</strong> If you are embedding the QR
            code generation functionality on the client-side, ensure that your
            client-side code follows security best practices. Implement proper
            input validation and sanitize user inputs to prevent potential
            security vulnerabilities.
          </li>
        </ul>
      </section>

      {/* Rate Limiting and Quotas - We'll do it later
      Support and community - We'll do it later
      changelog - We'll do it later
      FAQs - We'll do it later */}
    </div>
  )
}

export default QRDocumentaion
