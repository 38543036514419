import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import type { HTMLAttributes } from 'react'
import { ReactComponent as XIcon } from '../../assets/images/x.svg'
import './Dialog.css'

export interface DialogProps extends HTMLAttributes<HTMLDialogElement> {
  open: boolean
  onClose: () => void
  heading?: string
}

const DialogElement = styled.dialog`
  border: 0;
  background: white;
  min-height: 350px;
  min-width: 350px;
  box-shadow: var(--box-shadow-1);
  border-radius: 10px;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transition: height 0.2s ease;
  margin: 0;
  z-index: 14;
  overflow: auto;

  &::backdrop {
    background: red;
  }
`

const BackdropDiv = styled.div`
  width: 100%;
  position: fixed;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 13;
`

const Dialog = ({
  open,
  onClose,
  style,
  heading,
  ...props
}: DialogProps): JSX.Element => {
  const [show, setShow] = useState(open)
  const [mainContainerHeight, setMainContainerHeight] = useState(1000)
  const [mainNavbarHeight, setMainNavbarHeight] = useState(100)

  const handleResize = (): void => {
    const mainNavbar = document.getElementById('mainNavbarContainer')
    const mainScrollableSection = document.getElementById(
      'mainScrollableSection'
    )
    const mainContainer = document.getElementById('mainContainer')
    if (mainScrollableSection && mainNavbar && mainContainer) {
      setMainNavbarHeight(mainNavbar.clientHeight + 30)
      setMainContainerHeight(mainScrollableSection.clientHeight)
      if (open) mainContainer.style.minHeight = 'calc(100vh + 500px)'
      else mainContainer.style.minHeight = 'calc(100vh - 440px)'
    }
  }

  useEffect(() => {
    setShow(open)
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [open])

  return (
    <>
      {show ? <BackdropDiv /> : null}
      <DialogElement
        open={show}
        className={props.className}
        style={{
          ...style,
          maxHeight: `${mainContainerHeight - mainNavbarHeight - 20}px`,
          transform: `translate(-50%, calc(-50% + ${
            mainNavbarHeight / 2 - 20
          }px))`
        }}
      >
        <XIcon
          onClick={() => {
            setShow(false)
            onClose()
          }}
          className="xIcon"
        />
        {heading ? <h2 className='dialog-heading'>{heading}</h2> : null}
        {props.children}
      </DialogElement>
    </>
  )
}

export default Dialog
