import React, { useState } from 'react'
import './Footer.css'
import { SecondaryButton, SocialButton } from '../../common/elements/CommonElements'
import FbLogo from '../../assets/images/fb-logo.png'
import XLogo from '../../assets/images/x-logo.png'
import XLogoGS from '../../assets/images/x-logo-gs.png'
import LiLogo from '../../assets/images/li-logo.png'
import { useNavigate } from 'react-router-dom'

const Footer = (): JSX.Element => {
  const [xHover, setXHover] = useState(false)
  const navigate = useNavigate()

  return (
    <div id="mainFooterContainer" style={{ position: 'relative', zIndex: 10, marginTop: 'auto' }}>
      <div className="footerImage" />
      <div className="mainFooter">
        <div>
          <h1>Crafting Success with Scalable Solutions.</h1>
        </div>
        <div>
          <SecondaryButton className='marginController' onClick={() => navigate('/feedback')}>Help Us Improve</SecondaryButton>
          <div className='marginController'>
            © {new Date().getFullYear()} All rights reserved, ExaRoutes
          </div>
          <div className='footer-policies'>
            <a href='/terms'>Terms and conditions</a>
            <a href='/privacy-policy'>Privacy policy</a>
            <a href='/cookies-policy'>Cookies policy</a>
          </div>
        </div>
        <div className='marginControllerSB'>
          <div>
            <SocialButton>
              <img src={FbLogo} />
            </SocialButton>
            <SocialButton
              onMouseLeave={() => setXHover(false)}
              onMouseEnter={() => setXHover(true)}
            >
              <img src={xHover ? XLogo : XLogoGS} />
            </SocialButton>
            <SocialButton>
              <img src={LiLogo} />
            </SocialButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
