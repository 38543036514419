import React, { useState } from 'react'
import { PrimaryButton } from '../../common/elements/CommonElements'
import { api } from '../../utils/AxiosConfig'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import Loader from '../../common/misc/loader/Loader'
import { emptySnackbarData } from '../dashboard/Dashboard'
import { useAuth } from '../../common/context/Context'

interface PaymentProps {
  subscriptionId?: string
  product?: string
}

const Payment = ({ subscriptionId, product }: PaymentProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const { state } = useAuth()

  const updatePaymentMethod = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.get(`/api/stripe/new-checkout${subscriptionId ? '-update' : ''}`, {
        headers: {
          'success-url': '/payment-method/success',
          'error-url': '/payment-method/error',
          'subscription-id': subscriptionId
        }
      })
      if (response?.data?.url) window.location.href = response.data.url
      else throw new Error('Server not responded')
    } catch (err) {
      console.log('Error occurred while updating payment method', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while updating payment method.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={!subscriptionId ? { padding: '40px' } : undefined}>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <h4>
        {state?.user?.hasCustomerId || subscriptionId
          ? 'Update payment method'
          : 'Add payment method'}
      </h4>
      {!subscriptionId ? (
        <p>
          {state?.user?.hasCustomerId
            ? 'You can update the payment method for your account by clicking the button below. Please note that this action will update the payment method for all subscriptions. To update the payment method for a specific subscription, please visit the dashboard.'
            : 'You can easily add a payment method by clicking the button below. This action does not incur any charges.'}
        </p>
      ) : (
        <p>
          You can update the payment method for subscription ID -{' '}
          <a href={`/manage/${product || ''}/${btoa(subscriptionId)}`}>{subscriptionId}</a> by clicking the button below. Please note that this
          action will specifically update the payment method for this
          subscription ID. To update the payment method for all subscriptions,
          please navigate to the <a href='/settings#payment'>settings</a> page.
        </p>
      )}
      <br />
      <br />
      <PrimaryButton onClick={async () => await updatePaymentMethod()}>
        {state?.user?.hasCustomerId ? 'Update' : 'Add'}
      </PrimaryButton>
    </div>
  )
}

export default Payment
