import { PrimaryButton, SecondaryButton } from './CommonElements'
import Dialog from './Dialog'
import type { DialogProps } from './Dialog'
import { ReactComponent as AlertIcon } from '../../assets/images/alert-circle.svg'
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import styled from 'styled-components'
import React from 'react'

interface ConfirmDialogProps {
  title?: string
  text: string | JSX.Element
  note?: string
  onSubmit: () => void
  submitBtnName?: string
  submitBtnColor?: 'red'
  cancelBtnName?: string
  alertType?: 'warn' | 'info'
}

const AlertDiv = styled.div<{ alertType?: 'warn' | 'info' }>`
  width: 100%;
  text-align: center;
  padding: 30px 0;

  & svg {
    scale: 4;
    stroke: ${(props) => props.alertType === 'warn' ? 'var(--er-red)' : 'var(--er-infoDark)'};
  }
`

const FooterDiv = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  place-content: space-evenly;
  width: calc(100% - 60px);
`

const InfoContainer = styled.div`
  font-size: 14px;
  position: relative;
  padding: 50px 0;
  color: var(--er-grey);
  display: grid;
  grid-template-columns: 40px calc(100% - 40px);
  width: 100%;
`

const ConfirmDialog = ({
  title,
  text,
  note,
  submitBtnName,
  submitBtnColor,
  cancelBtnName,
  open,
  alertType = 'warn',
  onClose,
  onSubmit,
  ...props
}: DialogProps & ConfirmDialogProps): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <AlertDiv alertType={alertType}>
        <AlertIcon />
      </AlertDiv>
      <h4 style={{ fontSize: '24px' }}>{title}</h4>
      <span>{text}</span>
      {note ? (
        <InfoContainer>
          <InfoIcon />
          <div>Note - {note}</div>
        </InfoContainer>
      ) : null}
      <FooterDiv>
        <PrimaryButton onClick={onSubmit} color={submitBtnColor}>
          {submitBtnName || 'Submit'}
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>
          {cancelBtnName || 'Cancel'}
        </SecondaryButton>
      </FooterDiv>
    </Dialog>
  )
}

export default ConfirmDialog
