import React, { useEffect, useState } from 'react'
import { api } from '../../utils/AxiosConfig'
import Loader from '../../common/misc/loader/Loader'
import './Transactions.css'
import { Table } from '../../common/elements/CommonElements'
import {
  type TransactionStatus,
  type Transaction
} from '../../common/types/Types'
import { formatAmountToUSD } from '../../utils/CommonFunctions'
import { PLANS } from '../../Constants'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import NoItemsFound from '../../common/misc/noItemsFound/NoItemsFound'

export const transactionStatusMapping: Record<
  TransactionStatus,
  {
    name: string
    color: string
  }
> = {
  Updated: {
    name: 'Updated',
    color: 'var(--er-green)'
  },
  Added: {
    name: 'Added',
    color: 'var(--er-green)'
  },
  Updating: {
    name: 'Updating',
    color: 'var(--er-blue)'
  },
  Adding: {
    name: 'Adding',
    color: 'var(--er-blue)'
  },
  paid: {
    name: 'Paid',
    color: 'var(--er-green)'
  },
  unpaid: {
    name: 'Unpaid',
    color: 'var(--er-blue)'
  },
  void: {
    name: 'Void',
    color: 'var(--er-blue)'
  },
  partial: {
    name: 'Partial',
    color: 'var(--er-blue)'
  }
}

const Transections = (): JSX.Element => {
  const [transactions, setTransections] = useState<Transaction[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const fetchTransections = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await api.get<Transaction[]>('/api/transaction')
      setTransections(response.data)
    } catch (err) {
      console.error('Error occurred while fetching transactions', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching transactions'
      })
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    fetchTransections()
  }, [])

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <Table className="transactionTable">
        <thead>
          <tr>
            <th>Transaction Id</th>
            <th>Product</th>
            <th>Plan</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Type</th>
            <th>Subscription Id</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, idx) => {
            const plan = PLANS[transaction.product as keyof typeof PLANS]
            const productName = plan?.name
            const planName = plan?.planDetails.find(
              (pd) => pd.id === transaction.plan
            )
            return (
              <tr key={idx}>
                <td>{transaction.paymentId}</td>
                <td>{productName}</td>
                <td>{planName?.heading || ''}</td>
                <td>{formatAmountToUSD(transaction.amount)}</td>
                <td>
                  <span
                    style={{
                      color: transactionStatusMapping[transaction.status].color
                    }}
                  >
                    {transactionStatusMapping[transaction.status].name}
                  </span>
                </td>
                <td>{transaction.type}</td>
                <td>{transaction.subscriptionId}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {transactions.length === 0 && !isLoading ? <NoItemsFound /> : null}
    </div>
  )
}

export default Transections
