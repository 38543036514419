import * as React from 'react'
import styled from 'styled-components'

interface TabData {
  value: string
  label: string
  child: any
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  selected: string
  onTabChange: (...args: any) => void
  tabData: TabData[]
}

const TabContainer = styled.div``

const ContentContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 2px solid #2f2b3c;
  position: relative;
`

const TabButton = styled.button<{ selected?: boolean }>`
  padding: 12px 24px;
  cursor: pointer;
  background: none;
  border: 2px solid #2f2b3c;
  border-bottom: 0;
  font-size: larger;
  border-radius: 8px 8px 0px 0px;
  z-index: 2;
  position: relative;
  background: ${(props) => (props.selected ? '#2f2b3c' : 'white')};
  color: ${(props) => (props.selected ? 'white' : '')};
  transition: all 0.3s ease;
`

const TabManager = ({
  selected,
  onTabChange,
  tabData,
  style
}: Props): JSX.Element => {
  const selectedTab = tabData.filter((item) => item.value === selected)[0]
  return (
    <TabContainer style={style}>
      {tabData.map((tab) => {
        return (
          <div
            style={{
              margin: '0 20px',
              display: 'inline-block'
            }}
            key={tab.value}
          >
            <TabButton
              key={tab.value}
              value={tab.value}
              onClick={() => onTabChange(tab.value)}
              selected={tab.value === selected}
            >
              {tab.label}
            </TabButton>
          </div>
        )
      })}
      <ContentContainer>{selectedTab?.child}</ContentContainer>
    </TabContainer>
  )
}

export default TabManager
