import React, { useState } from 'react'
import './QR.APIReference.css'
import { ReactComponent as CDRIcon } from '../../assets/images/corner-down-right.svg'
import { Table } from '../../common/elements/CommonElements'

const QRAPIReference = (): JSX.Element => {
  const [showOptionsOf, setShowOptionsOf] = useState('')
  const [showSubOptionsOf, setShowSubOptionsOf] = useState('')
  const [showSubSubOptionsOf, setShowSubSubOptionsOf] = useState('')

  const handleOptionClick = (
    optionType:
      | 'backgroundOptions'
      | 'qrOptions'
      | 'imageOptions'
      | 'dotsOptions'
      | 'cornersSquareOptions'
      | 'cornersDotOptions'
      | 'encryption'
  ): void =>
    showOptionsOf === optionType
      ? setShowOptionsOf('')
      : setShowOptionsOf(optionType)

  const handleSubOptionClick = (subOptionType: 'gradient'): void =>
    showSubOptionsOf === subOptionType
      ? setShowSubOptionsOf('')
      : setShowSubOptionsOf(subOptionType)

  const handleSubSubOptionClick = (subSubOptionType: 'colorStops'): void =>
    showSubSubOptionsOf === subSubOptionType
      ? setShowSubSubOptionsOf('')
      : setShowSubSubOptionsOf(subSubOptionType)

  const renderColorOptions = (
    type: 'Background' | 'Corner dot' | 'Corner square' | 'Dot'
  ): JSX.Element => {
    return (
      <>
        <tr className="subTr">
          <td>
            <code>color</code>
          </td>
          <td>{`${type} color of the QR code.`}</td>
          <td>string</td>
          <td>No</td>
        </tr>
        <tr className="subTr" onClick={() => handleSubOptionClick('gradient')} style={{ cursor: 'pointer' }}>
          <td>
            <code>gradient</code>
          </td>
          <td>{`${type} gradient of the QR code.`}</td>
          <td>object</td>
          <td>No</td>
        </tr>
        {showSubOptionsOf === 'gradient' ? (
          <>
            <tr className="subSubTr">
              <td>
                <CDRIcon className="cdrIcon" />
                <code>type</code>
              </td>
              <td>
                Type of gradient. Possible values: &apos;radial&apos;,
                &apos;linear&apos;
              </td>
              <td>string</td>
              <td>Yes</td>
            </tr>
            <tr className="subSubTr">
              <td>
                <code>rotation</code>
              </td>
              <td>Rotation of gradient.</td>
              <td>number</td>
              <td>No</td>
            </tr>
            <tr
              className="subSubTr"
              onClick={() => handleSubSubOptionClick('colorStops')}
              style={{ cursor: 'pointer' }}
            >
              <td>
                <code>colorStops</code>
              </td>
              <td>Array of color stops.</td>
              <td>{'Array<object>'}</td>
              <td>Yes</td>
            </tr>
            {showSubSubOptionsOf === 'colorStops' ? (
              <>
                <tr className="subSubSubTr">
                  <td>
                    <CDRIcon className="cdrIcon" />
                    <code>color</code>
                  </td>
                  <td>Color of stop.</td>
                  <td>string</td>
                  <td>Yes</td>
                </tr>
                <tr className="subSubSubTr">
                  <td>
                    <code>offset</code>
                  </td>
                  <td>Offset of stop.</td>
                  <td>number</td>
                  <td>Yes</td>
                </tr>
              </>
            ) : null}
          </>
        ) : null}
      </>
    )
  }

  return (
    <div className='qr-api-reference'>
      <h1>API Reference</h1>
      <p>
        Detailed information about each API endpoint, including purpose,
        required parameters, and examples, is provided in the Endpoint
        Reference section.
      </p>
      <section>

        <article>
          <h2>Generate QR Code</h2>
          <p>
            <strong>Endpoint:</strong> <code>POST /api/generate</code>
          </p>
          <p>
            <strong>Description:</strong> Generates a QR code based on the
            provided data and settings.
          </p>
          <h4>Parameters:</h4>
          <Table>
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Description</th>
                <th>Type</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <code>data</code>
                </td>
                <td>
                  The data to be encoded in the QR code. The format depends on
                  the selected <code>dataType</code>.
                </td>
                <td>string or object</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>
                  <code>dataType</code>
                </td>
                <td>
                  The type of data. Possible values: &apos;text&apos;,
                  &apos;url&apos;, &apos;email&apos;, &apos;phone&apos;,
                  &apos;SMS&apos;, &apos;geo&apos;, &apos;wifi&apos;,
                  &apos;vcard&apos;, &apos;vcalendar&apos;, &apos;mecard&apos;.
                </td>
                <td>string</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>
                  <code>returnType</code>
                </td>
                <td>
                  The type of the generated QR code. Possible values:
                  &apos;png&apos;, &apos;svg&apos;, &apos;jpeg&apos;.
                </td>
                <td>string</td>
                <td>No (default: &apos;png&apos;)</td>
              </tr>
              <tr>
                <td>
                  <code>qrType</code>
                </td>
                <td>
                  The type of QR code. Possible values: &apos;static&apos;,
                  &apos;dynamic&apos;.
                </td>
                <td>string</td>
                <td>No (default: &apos;static&apos;)</td>
              </tr>
              <tr>
                <td>
                  <code>margin</code>
                </td>
                <td>
                  The margin (in pixels) to be left on all sides of the QR code.
                </td>
                <td>integer</td>
                <td>No (default: 4)</td>
              </tr>
              <tr>
                <td>
                  <code>shape</code>
                </td>
                <td>
                  The shape of the QR code. Possible values: &apos;square&apos;,
                  &apos;circle&apos;.
                </td>
                <td>string</td>
                <td>No (default: &apos;square&apos;)</td>
              </tr>
              <tr>
                <td>
                  <code>width</code>
                </td>
                <td>The width of the QR code (in pixels).</td>
                <td>integer</td>
                <td>No (default: 256)</td>
              </tr>
              <tr>
                <td>
                  <code>height</code>
                </td>
                <td>The height of the QR code (in pixels).</td>
                <td>integer</td>
                <td>No (default: 256)</td>
              </tr>
              <tr>
                <td>
                  <code>secure</code>
                </td>
                <td>
                  An array of security options for the QR code. Possible values:
                  &apos;expire&apos;, &apos;encrypt&apos;, &apos;password&apos;.
                </td>
                <td>array</td>
                <td>No</td>
              </tr>
              <tr>
                <td>
                  <code>expiry</code>
                </td>
                <td>
                  The expiration date for the QR code (in ISO 8601 format).
                </td>
                <td>string</td>
                <td>No</td>
              </tr>
              <tr>
                <td>
                  <code>password</code>
                </td>
                <td>The password for password-protected QR codes.</td>
                <td>string</td>
                <td>No</td>
              </tr>
              <tr onClick={() => handleOptionClick('encryption')} style={{ cursor: 'pointer' }}>
                <td>
                  <code>encryption</code>
                </td>
                <td>Details about encryption for encrypted QR codes.</td>
                <td>object</td>
                <td>No</td>
              </tr>
              {showOptionsOf === 'encryption' ? (
                <>
                  <tr className="subTr">
                    <td>
                      <CDRIcon className="cdrIcon" />
                      <code>type</code>
                    </td>
                    <td>
                      Type of encryption. Possible values: &apos;AES&apos;,
                      &apos;RSA&apos;
                    </td>
                    <td>string</td>
                    <td>Yes</td>
                  </tr>
                  <tr className="subTr">
                    <td>
                      <code>key</code>
                    </td>
                    <td>
                      Encryption key - only required for `AES` type encryption
                    </td>
                    <td>string</td>
                    <td>No</td>
                  </tr>
                </>
              ) : null}
              <tr>
                <td>
                  <code>sources</code>
                </td>
                <td>An array of sources for tracking engagement (up to 5).</td>
                <td>array</td>
                <td>No</td>
              </tr>
              <tr>
                <td>
                  <code>image</code>
                </td>
                <td>
                  The URL or URI of the image to be added in the center of the
                  QR code.
                </td>
                <td>string</td>
                <td>No</td>
              </tr>
              <tr onClick={() => handleOptionClick('imageOptions')} style={{ cursor: 'pointer' }}>
                <td>
                  <code>imageOptions</code>
                </td>
                <td>
                  Options for the added image in the center of the QR code.
                </td>
                <td>object</td>
                <td>No</td>
              </tr>
              {showOptionsOf === 'imageOptions' ? (
                <>
                  <tr className="subTr">
                    <td>
                      <CDRIcon className="cdrIcon" />
                      <code>hideBackgroundDots</code>
                    </td>
                    <td>
                      Type of corner square. Possible values: &apos;dot&apos;,
                      &apos;square&apos;, &apos;extra-rounded&apos;
                    </td>
                    <td>boolean</td>
                    <td>No (default: false)</td>
                  </tr>
                  <tr className="subTr">
                    <td>
                      <code>imageSize</code>
                    </td>
                    <td>
                      Coefficient of the image size. Not recommended to use ove
                      0.5. Lower is better
                    </td>
                    <td>number</td>
                    <td>No (default: 0.4)</td>
                  </tr>
                  <tr className="subTr">
                    <td>
                      <code>margin</code>
                    </td>
                    <td>Margin of the image in px</td>
                    <td>number</td>
                    <td>No (default: 0)</td>
                  </tr>
                </>
              ) : null}
              <tr>
                <td>
                  <code>files</code>
                </td>
                <td>An array of files to be associated with the QR code.</td>
                <td>File array</td>
                <td>No</td>
              </tr>
              <tr onClick={() => handleOptionClick('dotsOptions')} style={{ cursor: 'pointer' }}>
                <td>
                  <code>dotsOptions</code>
                </td>
                <td>Options for the dots in the QR code.</td>
                <td>object</td>
                <td>No</td>
              </tr>
              {showOptionsOf === 'dotsOptions' ? (
                <>
                  <tr className="subTr">
                    <td>
                      <CDRIcon className="cdrIcon" />
                      <code>type</code>
                    </td>
                    <td>
                      Type of dots. Possible values: &apos;dots&apos;,
                      &apos;rounded&apos;, &apos;classy&apos;,
                      &apos;classy-rounded&apos;, &apos;square&apos;,
                      &apos;extra-rounded&apos;
                    </td>
                    <td>string</td>
                    <td>Yes</td>
                  </tr>
                  {renderColorOptions('Background')}
                </>
              ) : null}
              <tr onClick={() => handleOptionClick('cornersSquareOptions')} style={{ cursor: 'pointer' }}>
                <td>
                  <code>cornersSquareOptions</code>
                </td>
                <td>Options for the corner squares in the QR code.</td>
                <td>object</td>
                <td>No</td>
              </tr>
              {showOptionsOf === 'cornersSquareOptions' ? (
                <>
                  <tr className="subTr">
                    <td>
                      <CDRIcon className="cdrIcon" />
                      <code>type</code>
                    </td>
                    <td>
                      Type of corner square. Possible values: &apos;dot&apos;,
                      &apos;square&apos;, &apos;extra-rounded&apos;
                    </td>
                    <td>string</td>
                    <td>Yes</td>
                  </tr>
                  {renderColorOptions('Background')}
                </>
              ) : null}
              <tr onClick={() => handleOptionClick('cornersDotOptions')} style={{ cursor: 'pointer' }}>
                <td>
                  <code>cornersDotOptions</code>
                </td>
                <td>Options for the corner dots in the QR code.</td>
                <td>object</td>
                <td>No</td>
              </tr>
              {showOptionsOf === 'cornersDotOptions' ? (
                <>
                  <tr className="subTr">
                    <td>
                      <CDRIcon className="cdrIcon" />
                      <code>type</code>
                    </td>
                    <td>
                      Type of corner dots. Possible values: &apos;dot&apos;,
                      &apos;square&apos;
                    </td>
                    <td>string</td>
                    <td>Yes</td>
                  </tr>
                  {renderColorOptions('Corner dot')}
                </>
              ) : null}
              <tr onClick={() => handleOptionClick('backgroundOptions')} style={{ cursor: 'pointer' }}>
                <td>
                  <code>backgroundOptions</code>
                </td>
                <td>Options for the background of the QR code.</td>
                <td>object</td>
                <td>No</td>
              </tr>
              {showOptionsOf === 'backgroundOptions' ? (
                <>
                  <tr className="subTr">
                    <td>
                      <CDRIcon className="cdrIcon" />
                      <code>round</code>
                    </td>
                    <td>Roundness of the QR code.</td>
                    <td>number</td>
                    <td>No</td>
                  </tr>
                  {renderColorOptions('Background')}
                </>
              ) : null}
              <tr onClick={() => handleOptionClick('qrOptions')} style={{ cursor: 'pointer' }}>
                <td>
                  <code>qrOptions</code>
                </td>
                <td>
                  Options for the overall QR code, such as type number and error
                  correction level.
                </td>
                <td>object</td>
                <td>No</td>
              </tr>
              {showOptionsOf === 'qrOptions' ? <></> : null}
            </tbody>
          </Table>

          <h4>Data Formatting Examples:</h4>
          <ul className="dataFormattingContainer">
            <li>
              <span>Text:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  data: 'Hello, World!',
  dataType: 'text'
}`}
              </pre>
            </li>
            <li>
              <span>URL:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": "https://example.com",
  "dataType": "url"
}`}
              </pre>
            </li>
            <li>
              <span>Email:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": {
      "email": "test@example.com",
      "body": "Hello officer"
  },
  "dataType": "email"
}`}
              </pre>
            </li>
            <li>
              <span>Phone:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": "+1234567890",
  "dataType": "phone"
}`}
              </pre>
            </li>
            <li>
              <span>SMS:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": {
      "tel": "+1234567890",
      "body": "Hello officer"
  },
  "dataType": "SMS"
}`}
              </pre>
            </li>
            <li>
              <span>Geo:</span>{' '}
              <pre className="preContainer preContainerWidth">{`{
  "data": "40.7128;-74.0060",
  "dataType": "geo"
}`}</pre>
            </li>
            <li>
              <span>WiFi:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": {
      "type": "WPA",
      "name": "MyWiFi",
      "password": "MyPassword",
      "hidden": false
  },
  "dataType": "wifi"
}`}
              </pre>
            </li>
            <li>
              <span>VCard:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": {
      "name": "John Doe",
      "tel": "+1234567890",
      "email": "john@example.com"
  },
  "dataType": "vcard"
}`}
              </pre>
            </li>
            <li>
              <span>VCalendar:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": {
      "eventStart": "2024-12-01T08:00:00Z",
      "eventEnd": "2024-12-01T10:00:00Z",
      "eventSummary": "Meeting"
  },
  "dataType": "vcalendar"
}`}
              </pre>
            </li>
            <li>
              <span>MeCard:</span>{' '}
              <pre className="preContainer preContainerWidth">
                {`{
  "data": {
      "lName": "Doe",
      "fName": "John",
      "tel": "+1234567890"
  },
  "dataType": "mecard"
}`}
              </pre>
            </li>
          </ul>
          <h4>Headers:</h4>
          <ul className="dataFormattingContainer">
            <li>
              <code>api-key</code> (string, required): Your API key for
              authentication.
            </li>
            <li>
              <code>client-id</code> (string, required): Your client ID for
              authentication.
            </li>
          </ul>
          <h4>Example Request:</h4>
          <pre className="preContainer">
            <code>
              POST /api/qr/generate
              <br />
              Content-Type: multipart/form-data
              <br />
              api-key: YOUR_API_KEY
              <br />
              client-id: YOUR_CLIENT_ID
              <br />
              <br />
              {`{
  type: 'text',
  data: 'Hello, World!',
  dataType: 'text',
  returnType: 'svg',
  qrType: 'dynamic',
  margin: 15,
  shape: 'square',
  width: 300,
  height: 300,
  image:
    'https://upload.wikimedia.org/wikipedia/commons/b/bd/Taj_Mahal%2C_Agra%2C_India_edit3.jpg',
  secure: ['expire', 'encrypt', 'password'],
  expiry: '2024-12-12T12:12:12.000Z',
  password: 'pass@123',
  encryption: {
    type: 'RSA'
  },
  sources: ['mobile', 'computer'],
  imageOptions: {
    crossOrigin: 'anonymous'
  },
  dotsOptions: {
    type: 'dots',
    color: '#000'
  },
  cornersSquareOptions: {
    type: 'square',
    rotation: 90,
    gradient: {
      type: 'linear',
      colorStops: [
        {
          color: '#000',
          offset: 0
        },
        {
          color: '#FFF',
          offset: 90
        }
      ]
    }
  },
  cornersDotOptions: {
    type: 'square',
    color: '#000'
  },
  backgroundOptions: {
    color: '#FFF'
  },
  qrOptions: {
    typeNumber: 0,
    errorCorrectionLevel: 'L'
  }
}`}
            </code>
          </pre>
          <h4>Example Response:</h4>
          <pre className="preContainer">
            <code>
              HTTP/1.1 200 OK
              <br />
              Content-Type: application/json
              <br />
              <br />
              {`{
  "id": "2e5c0760974bb7bb",
  "url": "https://www.exaroutes.com/qr/redirect/4a5c2aa6b0361a25168c3953...",
  "encryptionKey": "-----BEGIN PRIVATE KEY-----\\nMIICdwIBADANBgkqhkiG9w0...",
  "QRs": {
      "mobile": "<svg xmlns=\\"http://www.w3.org/2000/svg\\" viewBox=\\"0 0 300 300\\"><defs><clipPath id=...",
      "computer": "<svg xmlns=\\"http://www.w3.org/2000/svg\\" viewBox=\\"0 0 300 300\\"><defs><clipPath id=..."
  }
}`}
            </code>
          </pre>
        </article>
        <article>
          <h2>Bulk generate QR Codes</h2>
          <p>
            <strong>Endpoint:</strong> <code>POST /api/qr/bulk-generate</code>
          </p>
          <p>
            <strong>Description:</strong> We efficiently generates QR codes in
            bulk based on specified parameters. While the parameters remain
            consistent with the generate API, please note that bulk orders do
            not currently support the use of files. Consequently, the payload is
            expected in the form of a RAW JSON object (application/json).
            <br />
            <br />
            We initiate batch processing immediately upon receiving the request.
            Users can monitor the progress of their bulk orders on the dedicated
            &apos;Bulk Orders&apos; page and conveniently download the generated
            QR codes as a file once processing is completed.
            <br />
            <br />
            <strong>Note:</strong> Please be aware that we prioritize user
            privacy, and as such, we do not store the generated QR code data. It
            is accessible for a one-time download only, so remember to secure
            your files appropriately.
          </p>
        </article>
      </section>
    </div>
  )
}

export default QRAPIReference
