import React, { useEffect, useState } from 'react'
import Input from '../elements/Input'
import { PrimaryButton } from '../elements/CommonElements'
import { api } from '../../utils/AxiosConfig'
import type { User } from '../types/Types'

interface MultiFactorAuthProps {
  clientId: string
  onSuccess: (userData: User) => void
  onFailure: (err?: any) => void
  rememberMe: boolean
}

const MultiFactorAuth = ({
  clientId,
  onFailure,
  onSuccess,
  rememberMe
}: MultiFactorAuthProps): JSX.Element => {
  const [verificationCode, setVerificationCode] = useState('')
  const verifyMFA = async (): Promise<void> => {
    try {
      const result = await api.get('/api/mfa/verify', {
        headers: {
          'mfa-token': verificationCode,
          'client-id': clientId,
          'remember-me': `${rememberMe.toString()}`
        }
      })
      if (result.data && result.data.verified === true) {
        onSuccess({
          ...result.data?.user,
          token: result.data?.token,
          refreshToken: result.data?.refreshToken
        })
      } else {
        onFailure()
      }
    } catch (err) {
      console.error('Error occurred while verifying 2FA', err)
      onFailure(err)
    }
  }

  useEffect(() => {
    if (verificationCode?.length === 6) verifyMFA()
  }, [verificationCode])

  return (
    <div
      style={{
        backgroundColor: 'white',
        maxWidth: '550px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderRadius: '6px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, calc(-50% - 125px))',
        padding: '30px'
      }}
    >
      <h4 style={{ marginTop: 0 }}>Two-factor authentication verification</h4>
      <br />
      <Input
        label="Enter 6-digit Verification Code:"
        value={verificationCode}
        onChange={(e) => {
          setVerificationCode(e.target.value)
        }}
        maxLength={6}
        required
      />
      <div
        style={{ textAlign: 'center', margin: '30px', marginBottom: '10px' }}
      >
        <PrimaryButton onClick={verifyMFA}>Continue</PrimaryButton>
      </div>
    </div>
  )
}

export default MultiFactorAuth
