import React, { useState } from 'react'
import CopyText from '../elements/CopyText'
import { IconButton } from '../elements/Buttons'
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg'
import { ReactComponent as ExtLinkIcon } from '../../assets/images/external-link.svg'
import { camelCaseToSentence } from '../../utils/CommonFunctions'
import type { DynamicQR } from '../types/QR.Types'
import { api } from '../../utils/AxiosConfig'
import styled from 'styled-components'
import { type SnackbarBaseProps } from '../elements/Snackbar'

const FilesContainer = styled.div`
  vertical-align: middle;
  display: grid;
  grid-template-columns: calc(100% - 50px) 50px;
  margin: 12px 0;
`

const QRTypeFilesMapping = {
  text: {
    type: 'text/plain',
    fileName: 'text-file.txt'
  },
  SMS: {
    type: 'text/plain',
    fileName: 'sms-file.txt'
  },
  phone: {
    type: 'text/vcard',
    fileName: 'phone-file.vcf'
  },
  email: {
    type: 'message/rfc822',
    fileName: 'email-file.eml'
  },
  mecard: {
    type: 'text/mecard',
    fileName: 'mecard-file.mecard'
  },
  vcard: {
    type: 'text/vcard',
    fileName: 'vcard-file.vcf'
  },
  vcalendar: {
    type: 'text/calendar;charset=utf-8',
    fileName: 'calendar-event.ics'
  },
  wifi: {
    type: 'text/plain',
    fileName: 'wifi-config.txt'
  },
  geo: {
    type: 'text/plain',
    fileName: 'geo-location.txt'
  }
}

const QRViewer = ({
  redirectData,
  setSnackbarData
}: {
  redirectData: DynamicQR & { secureToken?: string }
  setSnackbarData: (sbp: SnackbarBaseProps) => void
}): JSX.Element => {
  const [downloadedFiles, setDownloadedFiles] = useState<string[]>([])
  const handleOpenMap = (): void => {
    const [latitude, longitude] = redirectData.formattedRawData?.split(';')
    const url = `https://www.google.com/maps?q=${latitude as string},${
      longitude as string
    }`

    window.open(url, '_blank')
  }

  const handleOpenOption = (): void => {
    if (redirectData.dataType === 'geo') return handleOpenMap()
    let redirectUri = redirectData.data
    if (redirectData.dataType === 'vcard')
      redirectUri = `TEL:${
        (redirectData.formattedRawData as Record<string, any>)?.tel as string
      }`
    try {
      window.location.href = redirectUri
    } catch (err) {
      console.error('error', err)
      setSnackbarData({
        type: 'error',
        message: 'No app present to handle this type of request.'
      })
    }
  }

  const handleDownload = (): void => {
    const typeMapping =
      QRTypeFilesMapping[
        redirectData.dataType as keyof typeof QRTypeFilesMapping
      ]
    let fileData = redirectData.data
    if (redirectData.dataType === 'SMS') {
      fileData = `To: ${
        ((redirectData.formattedRawData as Record<string, any>)
          ?.tel as string) || ''
      }\nMessage: ${
        ((redirectData.formattedRawData as Record<string, any>)
          ?.body as string) || ''
      }`
    } else if (redirectData.dataType === 'phone') {
      fileData = `
        BEGIN:VCARD\nVERSION:3.0\nTEL:${
          (redirectData.formattedRawData as string) || ''
        }\nEND:VCARD`
    } else if (redirectData.dataType === 'wifi') {
      fileData = `Type: ${
        (redirectData.formattedRawData as Record<string, any>).type as string
      }\nSSID: ${
        (redirectData.formattedRawData as Record<string, any>).name as string
      }\nPassword: ${
        (redirectData.formattedRawData as Record<string, any>)
          .password as string
      }\nHidden: ${
        (redirectData.formattedRawData as Record<string, any>).hidden === 'true'
          ? 'True'
          : 'False'
      }`
    } else if (redirectData.dataType === 'geo') {
      const [latitude, longitude] = redirectData.formattedRawData?.split(';')
      fileData = `Longitude : ${longitude as string}\nLatitude : ${
        latitude as string
      }\nGoogle maps URL : https://www.google.com/maps?q=${
        latitude as string
      },${longitude as string}`
    }
    const blob = new Blob([fileData], {
      type: typeMapping.type
    })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = typeMapping.fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    // Cleanup the object URL to avoid memory leaks
    URL.revokeObjectURL(url)
  }

  const handleFileDownload = async (file: string): Promise<void> => {
    const response = await api.get('/api/qr/file', {
      headers: {
        'subscription-id': redirectData.subscriptionId,
        'qr-id': redirectData.id,
        'file-name': file,
        'secure-token': redirectData.secureToken
      },
      responseType: 'blob'
    })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(response.data)
    link.download = file
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    setDownloadedFiles([...downloadedFiles, file])
  }

  const generateCopyText = (): string => {
    if (typeof redirectData.formattedRawData === 'string')
      return redirectData.formattedRawData
    let copyText = ''
    Object.entries(
      redirectData.formattedRawData as Record<string, any>
    ).forEach(([rdKey, rdValue]) => {
      copyText += `${camelCaseToSentence(rdKey)} : ${rdValue as string}\n`
    })
    return copyText
  }

  switch (redirectData.dataType) {
    case 'url':
      window.location.href = redirectData.data
      break
    case 'text':
    case 'SMS':
    case 'email':
    case 'phone':
    case 'mecard':
    case 'vcard':
    case 'vcalendar':
    case 'wifi':
    case 'geo':
      return (
        <>
          <CopyText text={generateCopyText()} />
          <div style={{ marginTop: '30px' }}>
            <IconButton onClick={handleDownload} icon={<DownloadIcon />} />
            {['vcalendar', 'text'].includes(redirectData.dataType) ? null : (
              <IconButton
                buttonType="secondary"
                onClick={handleOpenOption}
                style={{ marginLeft: '30px' }}
                icon={<ExtLinkIcon />}
              />
            )}
          </div>
          {redirectData.files ? (
            <div>
              <h5>These files are linked to the QR code -</h5>
              {redirectData.files.map((file, i) => {
                return (
                  <FilesContainer key={i}>
                    <span>{file}</span>
                    <DownloadIcon
                      onClick={async () => await handleFileDownload(file)}
                      style={{
                        stroke: downloadedFiles.includes(file)
                          ? 'var(--er-infoBorder)'
                          : 'var(--er-infoDark)',
                        cursor: 'pointer'
                      }}
                    />
                  </FilesContainer>
                )
              })}
            </div>
          ) : null}
        </>
      )
    default:
      console.error('Invalid qr data type')
  }
  return <div></div>
}

export default QRViewer
