import { type Plans } from './common/types/Types'

export const PLANS: Plans = {
  qr: {
    name: 'QR code generator',
    url: '/qr',
    planDetails: [
      {
        id: 'plan1',
        heading: 'Basic',
        price: '$7.99',
        description:
          'The Basic Subscription Plan is designed for small businesses or startups looking to establish their presence in the market.',
        lines: [
          'Unlimited static QR',
          '50,000 scans',
          'QR analytic data',
          '10 Dynamic QRs',
          'Max 50,000 API calls'
        ]
      },
      {
        id: 'plan2',
        heading: 'Advance',
        price: '$14.99',
        description:
          'The Advanced Subscription Plan is tailored for growing businesses with more demanding needs in the landscape.',
        lines: [
          'Unlimited static QR',
          'Unlimited scans',
          'QR analytic data',
          '50 Dynamic QRs',
          '5 Secured QR',
          '2 Enhanced QR Analytics',
          'Max 450,000 API calls'
        ]
      },
      {
        id: 'plan3',
        heading: 'Pro',
        price: '$28.99',
        description:
          'The Professional Subscription Plan is designed for established enterprises or large-scale businesses that require comprehensive and sophisticated solutions.',
        lines: [
          'Unlimited static QR',
          'Unlimited scans',
          'QR analytic data',
          '250 Dynamic QRs',
          '50 Secured QR',
          '10 Enhanced QR Analytics',
          'Max 1,500,000 API calls'
        ]
      },
      {
        id: 'plan4',
        heading: 'Ultra',
        price: '$49.99',
        description:
          'The Ultra Subscription Plan is the pinnacle of our offerings, catering to enterprise-level businesses seeking unparalleled features and capabilities for their QR generation needs.',
        lines: [
          'Unlimited static QR',
          'Unlimited scans',
          'QR analytic data',
          '500 Dynamic QRs',
          '500 Secured QR',
          '50 Enhanced QR Analytics',
          'Max 5,000,000 API calls'
        ]
      }
    ]
  }
}

export const POLICY_EMAIL = 'policy@exaroutes.com'
