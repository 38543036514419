import { type SnackbarBaseProps } from '../common/elements/Snackbar'

export const Validate = (
  errors: any,
  formData: any,
  type: string,
  value: string
): any => {
  const temp = { ...errors }
  delete temp.opasswordTempValue // It is not mandatory that user will change their password
  switch (type) {
    // case 'name':
    //   temp.nameValue = value === ''
    //   temp.nameMessage = temp.nameValue ? 'Name is not valid.' : ''
    //   break
    case 'email':
      temp.emailValue = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      temp.emailMessage = temp.emailValue ? 'Email is not valid.' : ''
      break
    case 'opassword':
      temp.opasswordTempValue = !formData.password || !formData.confirmPassword // This will set error but won't show if `password` or `confirmPassword` is empty
      break
    case 'password':
      temp.passwordValue =
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        )
      temp.passwordMessage = temp.passwordValue
        ? 'Kindly choose a password with minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.'
        : ''
      break
    case 'confirmPassword':
      temp.confirmPasswordValue = !(formData?.password === value)
      temp.confirmPasswordMessage = temp.confirmPasswordValue
        ? 'Password did not match'
        : ''
      break
    case 'domain': {
      const domainRegex =
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/
      temp.domainValue = !domainRegex.test(value)
      temp.domainMessage = temp.domainValue
        ? 'Invalid domain. Please enter a valid domain and include the protocol (https:// or http://).'
        : ''
      break
    }
    default:
      temp[`${type}Value`] = value === ''
      temp[`${type}Message`] = temp[`${type}Value`]
        ? `${type.charAt(0).toUpperCase() + type.slice(1)} is not valid.`
        : ''
      break
  }

  delete temp.isNotValid
  temp.isNotValid = Object.keys(temp).filter((item) => temp[item]).length > 0

  return temp
}

export const ValidateMFA = (
  hasSecret: boolean,
  setSnackbarData: (sbp: SnackbarBaseProps) => void
): boolean => {
  if (hasSecret) return true
  else {
    setSnackbarData({
      type: 'log',
      message: 'You need to enable 2FA from accounts to generate API key'
    })
    return false
  }
}
