import React, { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Loader from './common/misc/loader/Loader'
import Register from './components/register/Register'
import Login from './components/login/Login'
import Navbar from './components/navbar/Navbar'
import Homepage from './components/homepage/Homepage'
import QRApp from './components/qr/QR.App'
import PaymentCompleted from './common/payment/PaymentCompleted'
import PaymentError from './common/payment/PaymentError'
import Profile from './components/settings/profile/Profile'
import Dashboard from './components/dashboard/Dashboard'
import Settings from './components/settings/Settings'
import PrivateRoute from './layout/PrivateRoute'
import Footer from './components/footer/Footer'
import ManageAPI from './components/manageAPI/ManageAPI'
import PageNotFound from './components/pageNotFound/PageNotFound'
import Analytics from './components/analytics/QRAnalytics'
import Transactions from './components/transactions/Transactions'
import Layout from './layout/Layout'
import BulkOrders from './components/bulkOrders/BulkOrders'
import ErrorBoundary from './common/ErrorBoundary'
import Support from './components/support/Support'
import Feedback from './components/feedback/Feedback'
import AboutUs from './components/aboutUs/AboutUs'
import Payment from './components/payment/Payment'
import VerifyEmail from './components/register/VerifyEmail'
import ResetPassword from './components/login/ResetPassword'
import PrivacyPolicy from './components/policies/PrivacyPolicy'
import TermsAndConditions from './components/policies/TermsAndConditions'
import CookiesPolicy from './components/policies/CookiesPolicy'

function App(): JSX.Element {
  const [navbarHeight, setNavbarHeight] = useState(100)
  const [clientHeight, setClientHeight] = useState(
    document.documentElement.clientHeight // - (navbarHeight + 10) // 10px offset
  )

  const handleResize = (): void => {
    setClientHeight(document.documentElement.clientHeight) // 10px offset
    const mainScrollableSection = document.getElementById(
      'mainScrollableSection'
    )
    if (mainScrollableSection)
      if (mainScrollableSection.clientWidth + 7 < 960) {
        // When mobile view, 7px offset
        setNavbarHeight(130)
      } else {
        handleScroll()
      }
  } // Todo - Mid - Throttle these functions to improve performance

  const handleScroll = (): void => {
    const mainScrollableSection = document.getElementById(
      'mainScrollableSection'
    )

    if (mainScrollableSection) {
      const scrollPosition = mainScrollableSection.scrollTop
      if (mainScrollableSection.clientWidth + 7 < 960) {
        // When mobile view, 7px offset
        setNavbarHeight(130)
      } else if (scrollPosition > 10) {
        setNavbarHeight(60)
      } else {
        setNavbarHeight(100)
      }
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    handleScroll()
    document
      .getElementById('mainScrollableSection')
      ?.addEventListener('scroll', handleScroll)
    return () => {
      document
        .getElementById('mainScrollableSection')
        ?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <ErrorBoundary>
      <Layout>
        <Router>
          <Navbar height={`${navbarHeight}px`} />
          <div
            className="mainScrollableSection"
            id="mainScrollableSection"
            style={{
              height: `${clientHeight - navbarHeight - 30}px`,
              marginTop: `${navbarHeight + 30}px`
            }} // 30px navbar padding offset
          >
            <React.Suspense fallback={<Loader />}>
              <div className="mainContainerHolder">
                <div className="mainContainer" id="mainContainer">
                  <Routes>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    <Route path="/cookies-policy" element={<CookiesPolicy />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/feedback" element={<Feedback />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/" element={<Homepage />} />
                    <Route
                      path="/verify-email"
                      element={
                        <PrivateRoute>
                          <VerifyEmail />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/payment"
                      element={
                        <PrivateRoute>
                          <Payment />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <PrivateRoute>
                          <Profile />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/transactions"
                      element={
                        <PrivateRoute>
                          <Transactions />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <PrivateRoute>
                          <Settings />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/manage/:product/:subId"
                      element={
                        <PrivateRoute>
                          <ManageAPI />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/analytics/:product/:subId/:itemId"
                      element={
                        <PrivateRoute>
                          <Analytics />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/bulk-orders/:product/:subId"
                      element={
                        <PrivateRoute>
                          <BulkOrders />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/qr/*" element={<QRApp />} />
                    <Route
                      path="/:variablePath/success"
                      element={
                        <PrivateRoute>
                          <PaymentCompleted />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/:variablePath/error"
                      element={
                        <PrivateRoute>
                          <PaymentError />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/payment-method/success"
                      element={
                        <PrivateRoute>
                          <PaymentCompleted mode="setup" />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/payment-method/error"
                      element={
                        <PrivateRoute>
                          <PaymentError mode="setup" />
                        </PrivateRoute>
                      }
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </div>
              </div>
            </React.Suspense>
            <Footer />
          </div>
        </Router>
      </Layout>
    </ErrorBoundary>
  )
}

export default App
