import React, { useState } from 'react'
import {
  PrimaryButton,
  SecondaryButton
} from '../../common/elements/CommonElements'
import ConfirmDialog from '../../common/elements/ConfirmDialog'
import Input, { TextArea } from '../../common/elements/Input'
import type {
  DynamicQR,
  QROptions,
  SecureType
} from '../../common/types/QR.Types'
import type { EncryptionPostData } from '../qr/QR.Redirect'
import { Checkbox } from '../../common/elements/InputControls'
import SelectInput from '../../common/elements/SelectInput'
import DatePickerInput from '../../common/elements/DatePicker'
import styled from 'styled-components'

interface ForPostingProps {
  modifiedQRData?: QROptions & DynamicQR
  handleSecureChange: (secureType: SecureType) => void
  handleOptionChange: (key: string, value: any) => void
}

let counter = 0

const FileInput = styled.div`
  margin: 20px 0;
  height: 40px;
  position: relative;

  & label {
    background: var(--er-infoBorder);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;

    & hover {
      background: var(--er-info);
    }

    & active {
      background: var(--er-infoDark);
    }
  }

  & input {
    display: none;
  }
`

const InputSecureKeysForPosting = ({
  modifiedQRData,
  handleSecureChange,
  handleOptionChange
}: ForPostingProps): JSX.Element => {
  const isEncrypted = modifiedQRData?.secure?.includes('encrypt')
  const isPasswordProtected = modifiedQRData?.secure?.includes('password')
  const isExpiryProtected = modifiedQRData?.secure?.includes('expire')

  const [inputId] = useState(() => {
    counter++
    return `file-select-${counter}`
  })

  const handleFileChange = (event: any): void => {
    handleOptionChange('newFiles', Array.from(event.target.files))
  }

  return (
    <>
      <Checkbox
        label="Secure with expiry"
        checked={isExpiryProtected}
        onChange={() => handleSecureChange('expire')}
        style={{ marginRight: '20px' }}
      />
      {isExpiryProtected ? (
        <DatePickerInput
          selectedDate={
            modifiedQRData?.expiry ? new Date(modifiedQRData?.expiry) : null
          }
          onDateChange={(nd) => handleOptionChange('expiry', nd)}
          style={{ margin: '30px 0' }}
        />
      ) : null}
      <Checkbox
        label="Secure with encryption"
        checked={isEncrypted}
        onChange={(e) => {
          handleSecureChange('encrypt')
          if (e.target.checked) {
            handleOptionChange('encryption.type', 'RSA')
          } else {
            handleOptionChange('encryption.type', undefined)
          }
        }}
        style={{ marginRight: '20px' }}
      />
      {isEncrypted ? (
        <SelectInput
          label="Encryption type"
          selectList={[
            { id: 'RSA', label: 'RSA' },
            { id: 'AES', label: 'AES' }
          ]}
          selected={modifiedQRData?.encryption?.type || 'RSA'}
          style={{ margin: '30px 0' }}
          width="250px"
          onListItemClick={(item) =>
            handleOptionChange('encryption.type', item)
          }
        />
      ) : null}
      {isEncrypted && modifiedQRData?.encryption?.type === 'AES' ? (
        <Input
          label="Encryption key"
          value={modifiedQRData?.encryption?.key}
          onChange={(e) => handleOptionChange('encryption.key', e.target.value)}
          style={{ width: '350px', margin: '30px 0' }}
        />
      ) : null}
      <Checkbox
        label="Secure with password"
        checked={isPasswordProtected}
        onChange={() => handleSecureChange('password')}
      />
      {isPasswordProtected ? (
        <Input
          label="Password"
          value={modifiedQRData?.password || ''}
          onChange={(e) => handleOptionChange('password', e.target.value)}
          style={{ width: '350px', margin: '30px 0' }}
        />
      ) : null}
      <FileInput>
        <label htmlFor={inputId}>
          Attach file/s
        </label>
        <input id={inputId} type='file' onChange={handleFileChange} multiple/>
      </FileInput>
    </>
  )
}

interface FetchingProps {
  qr: QROptions & DynamicQR
  encryptionData: EncryptionPostData
  resetQRId: string
  resetEncryptedQR: () => void
  handleEncryptionDataChange: (key: string, value: any) => void
  getDecryptedData: (qrid: string) => Promise<void>
  setResetQRId: (id: string) => void
}

const InputSecureKeysForFetching = ({
  qr,
  encryptionData,
  resetQRId,
  resetEncryptedQR,
  handleEncryptionDataChange,
  getDecryptedData,
  setResetQRId
}: FetchingProps): JSX.Element => {
  return (
    <div>
      {qr.isPasswordProtected ? (
        <Input
          label="Password"
          value={encryptionData.password || ''}
          onChange={(e) =>
            handleEncryptionDataChange('password', e.target.value)
          }
          style={{ margin: '30px 0', width: '350px' }}
        />
      ) : null}
      {qr.isEncrypted ? (
        <>
          {qr.encryptionType === 'AES' ? (
            <Input
              label="Encryption IV"
              value={encryptionData.iv || ''}
              onChange={(e) => handleEncryptionDataChange('iv', e.target.value)}
              style={{ margin: '30px 0', width: '350px' }}
            />
          ) : null}
          <TextArea
            label="Encryption key"
            value={encryptionData?.['encryption-key'] || ''}
            onChange={(e) =>
              handleEncryptionDataChange('encryption-key', e.target.value)
            }
            style={{ margin: '30px 0' }}
            resize='none'
          />
        </>
      ) : null}
      <div className="verifyPassButtonContainer">
        <SecondaryButton onClick={async () => await getDecryptedData(qr.url)}>
          Submit
        </SecondaryButton>
        <span>OR</span>
        <PrimaryButton onClick={() => setResetQRId(qr.id)}>Reset</PrimaryButton>
        <ConfirmDialog
          open={resetQRId === qr.id}
          onClose={() => setResetQRId('')}
          title="Are you sure you want to reset this QR code ?"
          text="This process will delete all the information and security measures, including passwords, from this QR code. Please be aware that this action cannot be undone."
          submitBtnName="Yes"
          onSubmit={resetEncryptedQR}
        />
      </div>
    </div>
  )
}

export { InputSecureKeysForFetching, InputSecureKeysForPosting }
