import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Profile from './profile/Profile'
import { useLocation, useNavigate } from 'react-router-dom'
import Payment from '../payment/Payment'
import UserDomains from './domains/Domains'

const LeftSidebar = styled.div<{ scrolled: boolean }>`
  display: inline-block;
  position: fixed;
  width: 225px;
  height: ${(props) =>
    props.scrolled ? 'calc(100vh - 290px)' : 'calc(100vh - 250px)'};
  border-right: 1px solid #bbb9bc;
  transition: all 0.3s ease-in-out 0s;

  & ul {
    list-style: none;
    padding: 0;
    margin-bottom: 60px;
    margin-top: 0;
  }

  & ul li {
    margin: 12px 0;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  & ul li:hover {
    outline: 1px solid var(--er-primaryLight);
    background: var(--er-primaryULight);
  }

  & h3 {
    margin-top: 0;
  }

  & > div {
    margin-top: 60%;
    width: fit-content;
  }
`

const RightSidebar = styled.div`
  vertical-align: top;
  width: calc(100% - 226px);
  margin-top: 60px;
  z-index: 0;
  position: relative;
  right: -225px;
`

type SettingTabs = 'account' | 'privacy' | 'tnc' | 'payment' | 'domains'

interface SettingTabDetails {
  name: string
  element: JSX.Element
}

const SettingItems: Record<SettingTabs, SettingTabDetails> = {
  account: {
    name: 'Account',
    element: <Profile />
  },
  domains: {
    name: 'Domains',
    element: <UserDomains />
  },
  payment: {
    name: 'Payment',
    element: <Payment />
  },
  privacy: {
    name: 'Privacy',
    element: <Profile />
  },
  tnc: {
    name: 'Terms & conditions',
    element: <Profile />
  }
}

const Settings = (): JSX.Element => {
  const [scrolled, setScrolled] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  const hash = location.hash

  const [selected, setSelected] = useState<SettingTabs>(
    (['#account', '#privacy', '#tnc', '#payment', '#domains'].includes(hash)
      ? hash.substring(1)
      : 'account') as SettingTabs
  )

  useEffect(() => {
    const handleScroll = (): void => {
      const scrollPosition = window.scrollY
      setScrolled(scrollPosition > 10)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <div>
      <LeftSidebar scrolled={scrolled}>
        <div>
          <h3>Settings</h3>
          <ul>
            {Object.entries(SettingItems).map(([k, v]) => (
              <li
                key={k}
                onClick={() => {
                  setSelected(k as SettingTabs)
                  navigate('/settings#' + k)
                }}
                style={selected === k ? { color: 'var(--er-primary)' } : {}}
              >
                {v.name}
              </li>
            ))}
          </ul>
        </div>
      </LeftSidebar>
      <RightSidebar>{SettingItems[selected].element}</RightSidebar>
    </div>
  )
}

export default Settings
