import React from 'react'
import './CommonElements.css'
import styled, { css } from 'styled-components'

const H3 = (props: React.HTMLProps<HTMLHeadingElement>): JSX.Element => (
  <h3 {...props}>{props.children}</h3>
)
const H4 = (props: React.HTMLProps<HTMLHeadingElement>): JSX.Element => (
  <h4 {...props}>{props.children}</h4>
)
const H5 = (props: React.HTMLProps<HTMLHeadingElement>): JSX.Element => (
  <h5 {...props}>{props.children}</h5>
)
const H7 = (props: React.HTMLProps<HTMLHeadingElement>): JSX.Element => (
  <span {...props}>{props.children}</span>
)
const Paragraph = (
  props: React.HTMLProps<HTMLParagraphElement>
): JSX.Element => <p {...props}>{props.children}</p>

const Hr = styled.hr<{ color?: string }>`
  height: 1px;
  background-color: ${(props) => (props.color ? props.color : '#ccc')};
  border: none;
`

const PrimaryButton = styled.button<{ color?: 'red' }>`
  padding: 10px 24px;
  border-radius: 8px;
  background: ${(props) =>
    props.color === 'red' ? 'var(--er-red)' : 'var(--er-primary)'};
  color: white;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  text-transform: uppercase;
  transition: all 0.25s ease;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.disabled &&
    css`
      background: var(--er-greyLight);
      color: var(--er-greyDark);
      &:hover {
        background: var(--er-greyLight);
        color: var(--er-greyDark);
      }
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background: ${(prop: any) =>
          prop.color === 'red'
            ? 'var(--er-redLight)'
            : 'var(--er-primaryLight)'};
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }

      &:active {
        background: ${(prop: any) =>
          prop.color === 'red' ? 'var(--er-redDark)' : 'var(--er-primaryDark)'};
        box-shadow: none;
      }
    `}
`

const SecondaryButton = styled.button`
  padding: 10px 24px;
  border-radius: 8px;
  border: 2px solid #2f2b3c;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 3px 8px;
  text-transform: uppercase;
  transition: all 0.25s ease;
  color: #2f2b3c;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.disabled &&
    css`
      background: var(--er-greyLight);
      color: white;
      border-color: var(--er-greyDark);
      &:hover {
        background: var(--er-greyLight);
        color: white;
      }
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        /* border-color: var(--er-primaryLight); */
        box-shadow: rgba(0, 0, 0, 2) 0px 3px 8px;
        /* color: var(--er-primaryLight); */
      }

      &:active {
        /* border-color: var(--er-primaryDark); */
        box-shadow: none;
        /* color: var(--er-primaryDark); */
      }
    `}
`

const MaterialButton = styled.button`
  border-radius: 5px;
  padding: 8px 12px;
  background: transparent;
  border: none;
  text-transform: uppercase;
  color: white;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const SocialButton = styled.button`
  height: 68px;
  width: 68px;
  border-radius: 20%;
  transition: 0.15s all ease;
  filter: grayscale(100%);
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 20px;

  &:hover {
    scale: 1.1;
    filter: unset;
  }

  & > img {
    width: 48px;
    height: 48px;
  }
`

const Ul = styled.ul`
  background: white;
  list-style: none;
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--box-shadow-1);
  width: max-content;
  & > li:first-child {
    padding-top: 0;
  }
  & > li:last-child {
    padding-bottom: 0;
  }
`

const Li = styled.li`
  padding: 6px 0;
  cursor: pointer;
  width: 100%;

  &:hover {
    color: var(--er-primary);
  }
`

const Table = styled.table`
  width: 100%;
  background: bisque;
  border-radius: 10px;
  margin: 60px 0;

  th {
    text-transform: uppercase;
    font-size: 14px;
  }

  tr {
    text-align: left;
    outline: 2px solid white;
  }

  thead tr {
    outline: 5px solid white;
    color: var(--er-primaryDark);
  }

  th,
  td {
    padding: 20px;
  }

  tbody tr:first-of-type td {
    padding-top: 24px;
  }
`

export {
  H3,
  H4,
  H5,
  H7,
  Hr,
  PrimaryButton,
  SecondaryButton,
  MaterialButton,
  SocialButton,
  Paragraph,
  Ul,
  Li,
  Table
}
