import React, { useState } from 'react'
import SelectInput from '../../common/elements/SelectInput'
import { TextArea } from '../../common/elements/Input'
import { PrimaryButton } from '../../common/elements/CommonElements'
import { emptySnackbarData } from '../dashboard/Dashboard'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { api } from '../../utils/AxiosConfig'
import { SUPPORT_EMAIL } from '../../Config'
import Loader from '../../common/misc/loader/Loader'

const Feedback = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [formData, setFormData] = useState({
    area: '',
    message: ''
  })

  async function submitFeedback(): Promise<void> {
    setIsLoading(true)
    try {
      await api.post('/api/support/feedback', formData)
      setFormData({
        area: '',
        message: ''
      })
      setSnackbarData({
        type: 'success',
        message:
          'Thank you for sharing your valuable feedback with us! We truly appreciate your input and will use it to improve our website.'
      })
    } catch (err) {
      setSnackbarData({
        type: 'error',
        message:
          'Something went wrong while submitting your feedback. Please write us at ' +
          SUPPORT_EMAIL
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <h3>Share your feedback</h3>

      <span>Thanks for sending us your ideas, issues or appreciations.</span>
      <br />
      <br />
      <span>
        If you have a specific question or need help resolving a problem you can
        visit our <a href="/support">support</a> page.
      </span>
      <br />
      <br />
      <br />
      <SelectInput
        label="What is your feedback about ?"
        selectList={[
          { id: 'website', label: 'Website' },
          { id: 'qr_api', label: 'QR generating API' },
          { id: 'other', label: 'Other' }
        ]}
        selected={formData.area}
        style={{ maxWidth: '400px', marginLeft: 'calc(50% - 200px)' }}
        onListItemClick={(e) => setFormData({ ...formData, area: e })}
      />
      <br />
      <br />
      <TextArea
        label="Tell Us Your Thoughts"
        value={formData.message}
        style={{ maxWidth: '800px', marginLeft: 'calc(50% - 400px)' }}
        rows={8}
        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
      />
      <br />
      <br />
      <PrimaryButton
        onClick={async () => await submitFeedback()}
        disabled={!formData.area || !formData.message}
      >
        Submit
      </PrimaryButton>
    </div>
  )
}

export default Feedback
