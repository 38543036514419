import React, { useEffect, useState } from 'react'
import {
  H3,
  PrimaryButton,
  SecondaryButton
} from '../../../common/elements/CommonElements'
import Input from '../../../common/elements/Input'
import styled from 'styled-components'
import { Validate } from '../../../utils/Validation'
import { useAuth } from '../../../common/context/Context'
import { api, updateHeaders } from '../../../utils/AxiosConfig'
import { getCookies, removeCookies, setCookies } from '../../../utils/Cookies'
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-2.svg'
import { ReactComponent as CameraIcon } from '../../../assets/images/camera.svg'
import { ReactComponent as UserIcon } from '../../../assets/images/user.svg'
import { fileToBase64 } from '../../../utils/CommonFunctions'
import Loader from '../../../common/misc/loader/Loader'
import Snackbar, {
  type SnackbarBaseProps
} from '../../../common/elements/Snackbar'
import { emptySnackbarData } from '../../dashboard/Dashboard'
import ConfirmDialog from '../../../common/elements/ConfirmDialog'
import { useNavigate } from 'react-router-dom'
import Security from '../security/Security'
import CopyText from '../../../common/elements/CopyText'
import { ReactComponent as CheckIcon } from '../../../assets/images/check-circle.svg'
import { ReactComponent as XCircleIcon } from '../../../assets/images/x-circle.svg'
import Dialog from '../../../common/elements/Dialog'
import VerifyEmail from '../../register/VerifyEmail'
import SelectInput from '../../../common/elements/SelectInput'

import countriesData from '../../../common/countries.json'
import { type CountryData } from '../../../common/types/Types'
import { Checkbox } from '../../../common/elements/InputControls'
import { CLIENT_SITE_NAME } from '../../../Config'

const MainContainer = styled.div`
  text-align: center;
`

const ImageContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin-left: calc(50% - 130px);
`

const DetailsContainer = styled.div`
  width: calc(60% - 60px);
  display: inline-block;
  vertical-align: top;
  max-width: 500px;
  background: white;
  padding: 50px 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
`

const Image = styled.img`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  padding: 30px;
`

const ButtonsContainer = styled.div`
  margin-top: 50px;
`

const DeleteButton = styled(DeleteIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--er-red);
  cursor: pointer;
`

const CameraButton = styled(CameraIcon)`
  position: absolute;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  background: var(--er-primary);
  padding: 10px;
  border-radius: 30%;
`

const StyledUserIcon = styled(UserIcon)`
  width: 200px;
  height: 200px;
  padding: 30px;
`

const DeactivationList = styled.ul`
  list-style: none;
  text-align: left;
  font-size: 12px;
  padding: 0;

  & li {
    margin: 4px 0;
  }
`

const Profile = (): JSX.Element => {
  const [changePassword, setChangePassword] = useState(false)
  const countryList = countriesData as CountryData[]
  const [formData, setFormData] = useState<Record<string, any>>({
    img: sessionStorage.getItem('img') || localStorage.getItem('img')
  })
  const [sendData, setSendData] = useState<Record<string, any>>({})
  const [errors, setErrors] = useState<Record<string, any>>({})

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [showDeactivatePopup, setShowDeactivatePopup] = useState(false)
  const [isEmailVerificationRequired, setIsEmailVerificationRequired] =
    useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  const { state, user, dispatch } = useAuth()

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const handleInputChange = (key: string, value: any): void => {
    setSendData({ ...sendData, [key]: value })
    setFormData({ ...formData, [key]: value })
    setErrors(Validate(errors, formData, key, value))
  }

  const handleUpdate = async (): Promise<void> => {
    if (Object.keys(sendData).length === 0) return
    if (
      Object.prototype.hasOwnProperty.call(sendData, 'email') &&
      sendData.email &&
      user.verified &&
      !isEmailVerified
    )
      return await handleEmailChange()
    setIsLoading(true)
    try {
      const user = await api.patch('/api/profile', sendData)
      const filteredUserData = { ...user.data }
      delete filteredUserData.token

      dispatch({ type: 'USER', payload: user.data })
      if (getCookies('user') && getCookies('erjwt')) {
        setCookies('erjwt', user.data.token)
        setCookies('refreshjwt', user.data.refreshToken)
        setCookies('user', filteredUserData)
        if (sendData.img) localStorage.setItem('img', sendData.img)
        else localStorage.removeItem('img')
      } else if (
        sessionStorage.getItem('user') &&
        sessionStorage.getItem('erjwt')
      ) {
        sessionStorage.setItem('user', JSON.stringify(filteredUserData))
        sessionStorage.setItem('erjwt', user.data.token)
        sessionStorage.setItem('refreshjwt', user.data.refreshToken)
        if (sendData.img) sessionStorage.setItem('img', sendData.img)
        else sessionStorage.removeItem('img')
      }
      updateHeaders()
      resetPasswords()
      setErrors({ ...errors, isNotValid: true })
      setSnackbarData({
        type: 'success',
        message: 'Profile updated successfully'
      })
    } catch (err: any) {
      console.error('Error occurred while updating profile', err)
      if (err?.response?.status === 409)
        return setSnackbarData({
          type: 'error',
          message: 'Another account already exists with the provided email'
        })
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while updating profile.'
      })
    } finally {
      setIsLoading(false)
      setChangePassword(false)
      setIsEmailVerified(false)
    }
  }

  const handleEmailChange = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await api.post('/api/auth/resend-email', {
        email: state.user?.email,
        newEmail: sendData?.email
      })
      setIsEmailVerificationRequired(true)
    } catch (err: any) {
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while sending verification code to email.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await api.delete('/api/auth/')
      removeCookies('erjwt')
      sessionStorage.removeItem('erjwt')
      localStorage.removeItem('erjwt')
      removeCookies('user')
      sessionStorage.removeItem('user')
      localStorage.removeItem('user')
      removeCookies('refreshjwt')
      sessionStorage.removeItem('refreshjwt')
      localStorage.removeItem('refreshjwt')
      dispatch({ type: 'SIGNOUT' })
      navigate('/')
    } catch (err: any) {
      console.error('Error occurred while deleting account', err)
      if (err?.response?.status === 405)
        return setSnackbarData({
          type: 'info',
          autoClose: false,
          message:
            "Your account closure request can't be processed as you've active subscriptions on your account"
        })
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while deleting account.'
      })
    } finally {
      setIsLoading(false)
      setShowDeactivatePopup(false)
    }
  }

  const handleImageSelection = async (e: any): Promise<void> => {
    const file = e.target.files[0]
    const base64 = await fileToBase64(file)
    handleInputChange('img', base64)
  }

  const handleImageRemoval = (): void => {
    handleInputChange('img', undefined)
  }

  const resetPasswords = (): void => {
    const tempSendData = { ...sendData }
    delete tempSendData.opassword
    delete tempSendData.password
    delete tempSendData.confirmPassword

    setSendData(tempSendData)

    const tempFormData = { ...formData }
    delete tempFormData.opassword
    delete tempFormData.password
    delete tempFormData.confirmPassword

    setFormData(tempFormData)
  }

  const handleChangePasswordClick = (): void => {
    if (changePassword) {
      resetPasswords()
      const tempErrors = { ...errors }
      delete tempErrors.opasswordValue
      delete tempErrors.opasswordMessage
      delete tempErrors.passwordValue
      delete tempErrors.passwordMessage
      delete tempErrors.confirmPasswordValue
      delete tempErrors.confirmPasswordMessage

      if (Object.keys(tempErrors).length === 1) delete tempErrors.isNotValid
      setErrors(tempErrors)
    } else {
      setErrors({ ...errors, isNotValid: true })
    }
    setChangePassword(!changePassword)
  }

  useEffect(() => {
    if (isEmailVerified) {
      setSnackbarData({
        type: 'success',
        message: 'Email verified successfully'
      })

      handleUpdate()
    }
  }, [isEmailVerified])

  useEffect(() => {
    if (state?.user)
      setFormData({
        ...formData,
        name: state.user.name,
        email: state.user.email,
        country: state.user.country,
        emailUpdatesOptIn: state?.user?.emailUpdatesOptIn
      })
  }, [state])

  return (
    <MainContainer>
      <>
        {isLoading ? <Loader /> : null}
        <Snackbar
          {...snackbarData}
          clear={() => setSnackbarData(emptySnackbarData)}
        />
        <H3 style={{ marginTop: 0 }}>Edit profile</H3>
        <DetailsContainer>
          <h5 style={{ marginTop: 0, color: 'var(--er-primary)' }}>
            Client ID:
          </h5>
          <CopyText
            style={{ background: 'var(--er-primaryULight)' }}
            text={state?.user?.clientId}
          />
          <ImageContainer>
            {formData.img || sendData.img ? (
              <>
                <Image src={formData.img || sendData.img} />
                <DeleteButton onClick={handleImageRemoval} />
              </>
            ) : (
              <StyledUserIcon />
            )}
            <div>
              <input
                id="profileInput"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageSelection}
              />
              <CameraButton
                onClick={() => document.getElementById('profileInput')?.click()}
              />
            </div>
          </ImageContainer>
          <Input
            label="Name"
            value={formData.name ?? ''}
            onChange={(e: any) => handleInputChange('name', e.target.value)}
            error={
              typeof errors.nameValue !== 'undefined' ? errors.nameMessage : ''
            }
          />
          <Input
            label="Email"
            style={{ marginTop: '40px' }}
            onChange={(e: any) => handleInputChange('email', e.target.value)}
            value={formData.email ?? ''}
            error={
              typeof errors.emailValue !== 'undefined'
                ? errors.emailMessage
                : ''
            }
          />
          <div style={{ textAlign: 'left', margin: '40px 0px' }}>
            <SelectInput
              label="Country"
              selectList={countryList.map((c) => ({
                id: c.cca2,
                label: c.name.common,
                labelPrefix: (
                  <img
                    style={{
                      width: '24px',
                      marginRight: '12px',
                      verticalAlign: 'middle',
                      borderRadius: '20%'
                    }}
                    src={c.flags.svg}
                  />
                )
              }))}
              selected={formData.country ?? ''}
              onListItemClick={(countryId) =>
                handleInputChange('country', countryId)
              }
            />
          </div>
          <div
            style={{
              textAlign: 'left',
              padding: '12px',
              borderRadius: '5px',
              margin: '30px 0',
              border: `1px solid ${
                state.user?.verified ? 'var(--er-green)' : 'var(--er-red)'
              }`
            }}
          >
            <span style={{ verticalAlign: 'middle' }}>Email verified: </span>
            {state.user?.verified ? (
              <CheckIcon
                style={{ color: 'var(--er-green)', verticalAlign: 'middle' }}
              />
            ) : (
              <>
                <XCircleIcon
                  style={{ color: 'var(--er-red)', verticalAlign: 'middle' }}
                />
                <PrimaryButton
                  style={{
                    marginLeft: 'calc(100% - 270px)',
                    verticalAlign: 'middle'
                  }}
                  onClick={() =>
                    navigate('/verify-email', {
                      state: { email: state.user.email, sendEmail: true }
                    })
                  }
                >
                  Verify Now!
                </PrimaryButton>
                <br />
              </>
            )}
          </div>
          {state?.user?.verified ? (
            <Checkbox
              label={`I would like to receive emails from ${CLIENT_SITE_NAME} about product and feature updates.`}
              checked={formData?.emailUpdatesOptIn}
              style={{ textAlign: 'left' }}
              onChange={(e) =>
                handleInputChange('emailUpdatesOptIn', e.target.checked)
              }
            />
          ) : null}
          {state?.user?.loginType === 'email' ? (
            <>
              <SecondaryButton
                style={{ marginTop: '40px' }}
                onClick={handleChangePasswordClick}
              >
                {!changePassword ? 'Change password' : 'Cancel password change'}
              </SecondaryButton>
              {changePassword ? (
                <>
                  <Input
                    label="Old password"
                    type="password"
                    onChange={(e: any) =>
                      handleInputChange('opassword', e.target.value)
                    }
                    style={{ marginTop: '40px' }}
                    value={formData.opassword ?? ''}
                    error={
                      errors.opasswordValue ? 'Please enter old password' : ''
                    }
                  />
                  <Input
                    label="New password"
                    type="password"
                    onChange={(e: any) =>
                      handleInputChange('password', e.target.value)
                    }
                    style={{ marginTop: '40px' }}
                    value={formData.password ?? ''}
                    error={
                      typeof errors.passwordValue !== 'undefined'
                        ? errors.passwordMessage
                        : ''
                    }
                  />
                  <Input
                    label="Confirm new password"
                    type="password"
                    onChange={(e: any) =>
                      handleInputChange('confirmPassword', e.target.value)
                    }
                    style={{ marginTop: '40px' }}
                    value={formData.confirmPassword ?? ''}
                    error={
                      typeof errors.confirmPasswordValue !== 'undefined'
                        ? errors.confirmPasswordMessage
                        : ''
                    }
                  />
                </>
              ) : null}
            </>
          ) : null}
          <br />
          <Security />
          <br />
        </DetailsContainer>
        <ButtonsContainer>
          <PrimaryButton
            style={{ width: '110px', marginRight: '50px' }}
            disabled={errors.isNotValid || !Object.keys(sendData).length}
            onClick={handleUpdate}
          >
            Save
          </PrimaryButton>
          <SecondaryButton style={{ width: '110px' }}>Cancel</SecondaryButton>
        </ButtonsContainer>
        <PrimaryButton
          color="red"
          style={{ margin: '40px 0' }}
          onClick={() => setShowDeactivatePopup(true)}
        >
          Deactivate account
        </PrimaryButton>
        <ConfirmDialog
          title="Deactivate account"
          text={
            <>
              Are you certain about closing your account?
              <br />
              <DeactivationList>
                <li>
                  Temporary Deactivation: Your account will be suspended for 30
                  days.
                </li>
                <li>
                  Login for Restoration: Within 30 days, log in to reactivate
                  your account.
                </li>
                <li>
                  Need Assistance? After 30 days, contact support for account
                  reactivation.
                </li>
                <li>
                  Data Erasure: All account data will be permanently deleted
                  after 90 days.
                </li>
              </DeactivationList>
            </>
          }
          submitBtnName="Deactivate"
          submitBtnColor="red"
          note="After the 90-day period, data cannot be recovered."
          open={showDeactivatePopup}
          onClose={() => setShowDeactivatePopup(false)}
          onSubmit={handleDelete}
        />
        <Dialog
          open={isEmailVerificationRequired}
          onClose={() =>
            setSnackbarData({
              type: 'warn',
              message: 'Email not verified. Account details are not updated.'
            })
          }
        >
          <VerifyEmail
            propEmail={state.user?.email}
            propNewEmail={sendData?.email}
            submit={(verified) => {
              if (verified) {
                const tempSendData = { ...sendData }
                delete tempSendData.email
                setSendData(tempSendData)
                setIsEmailVerified(true)
                setIsEmailVerificationRequired(false)
              }
            }}
          />
        </Dialog>
      </>
    </MainContainer>
  )
}

export default Profile
