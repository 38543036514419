import { useAuth } from '../context/Context'
import styles from './SubscriptionBox.module.css'
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import type { ProductName } from '../types/Types'

interface SubscriptionBoxProps {
  trialDuration?: string
  productName: ProductName
}

const SubscriptionBox = ({
  trialDuration = '14-day',
  productName
}: SubscriptionBoxProps): JSX.Element => {
  const { isUserLoggedIn } = useAuth()
  const navigate = useNavigate()
  const getStarted = (): void => {
    if (isUserLoggedIn) return navigate(`/${productName}/plans`)
    else navigate('/login')
  }
  return (
    <div className={styles.mainSubBox}>
      <h1>Get started for free!</h1>
      <span>
        Start your {trialDuration} free trial with any of our available plans!
      </span>
      <div>
        <button className={styles.getStartedBtn} onClick={getStarted}>
          <span>Get Started Now</span>
          <ArrowRight />
        </button>
      </div>
    </div>
  )
}

export default SubscriptionBox
