import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import { api } from '../../utils/AxiosConfig'
import { Table } from '../../common/elements/CommonElements'
import type { BulkOrder, BulkStatuses } from '../../common/types/Types'
import Loader from '../../common/misc/loader/Loader'
import { ReactComponent as DownloadImage } from '../../assets/images/download.svg'
import ConfirmDialog from '../../common/elements/ConfirmDialog'
import { PLANS } from '../../Constants'
import NoItemsFound from '../../common/misc/noItemsFound/NoItemsFound'

interface BulkStatusObject {
  name: string
  color: string
}

const bulkStatusMapping: Record<BulkStatuses, BulkStatusObject> = {
  completed: {
    name: 'Completed',
    color: 'var(--er-green)'
  },
  processing: {
    name: 'Processing',
    color: '#ff9a00'
  },
  resultDownloaded: {
    name: 'Results downloaded',
    color: 'var(--er-infoDark)'
  }
}

const BulkOrders = (): JSX.Element => {
  let { subId } = useParams()
  // const { product } = useParams()      // could be used in future to show manage component for different products
  const [isLoading, setIsLoading] = useState(false)

  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [bulkOrders, setBulkOrders] = useState<BulkOrder[]>()
  const [downloadResultId, setDownloadResultId] = useState('')

  let isSubIdValid = false
  try {
    if (subId) {
      subId = atob(subId)
      isSubIdValid = true
    }
  } catch (err) {
    isSubIdValid = false
    console.warn('Error while getting subscription id', err)
  }

  const fetchBulkOrders = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const allBulkOrders = await api.get('/api/bulk', {
        headers: {
          'subscription-id': subId
        }
      })
      setBulkOrders(allBulkOrders.data)
    } catch (err) {
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching bulk orders'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const downloadResult = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const bulkResult = await api.get('/api/bulk/result', {
        headers: {
          'subscription-id': subId,
          'bulk-id': downloadResultId
        }
      })

      const beautifiedJsonString = JSON.stringify(
        JSON.parse(bulkResult.data),
        null,
        2
      )
      const blob = new Blob([beautifiedJsonString], {
        type: 'application/json'
      })

      // Create a download link
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = downloadResultId + '.json'

      // Append the link to the body and trigger the download
      document.body.appendChild(a)
      a.click()

      // Clean up by removing the link
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
      await fetchBulkOrders()
    } catch (err) {
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while fetching bulk orders'
      })
    } finally {
      setIsLoading(false)
      setDownloadResultId('')
    }
  }

  useEffect(() => {
    if (isSubIdValid) fetchBulkOrders()
  }, [subId])

  return (
    <div>
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <Table>
        <thead>
          <tr>
            <th>Bulk id</th>
            <th>Product type</th>
            <th>Status</th>
            <th>Count</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {bulkOrders?.map((bulkOrder) => (
            <tr key={bulkOrder.id}>
              <td>{bulkOrder.id}</td>
              <td>{PLANS[bulkOrder.product as keyof typeof PLANS].name}</td>
              <td style={{ color: bulkStatusMapping[bulkOrder.status].color }}>
                {bulkStatusMapping[bulkOrder.status].name}
              </td>
              <td>{bulkOrder.count}</td>
              <td>
                {bulkOrder.status === 'completed' ? (
                  <DownloadImage
                    onClick={() => setDownloadResultId(bulkOrder.id)}
                  />
                ) : null}
                <ConfirmDialog
                  open={bulkOrder.id === downloadResultId}
                  onClose={() => setDownloadResultId('')}
                  title="Download bulk order result"
                  text="Warning: This file includes sensitive information, and we do not store it. Please ensure to keep a secure copy as it cannot be downloaded again."
                  submitBtnName="Download"
                  alertType="info"
                  onSubmit={async () => await downloadResult()}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {bulkOrders?.length === 0 && !isLoading ? <NoItemsFound /> : null}
    </div>
  )
}

export default BulkOrders
