import PlanCard, { type PlanCardType } from './PlanCard'
import React from 'react'

const PlanSuite = ({
  plans,
  onClick,
  headKey,
  minHeight

}: {
  plans: PlanCardType[]
  onClick: (id: string) => void
  headKey: string // Head key will be used to reference plan's price id
  minHeight?: string
}): JSX.Element => {
  return (
    <div style={{ textAlign: 'center', marginTop: '48px' }}>
      {plans.map((plan, i) => (
        <div
          key={i}
          style={{
            margin: '25px',
            display: 'inline-block',
            textAlign: 'left',
            position: 'relative',
            verticalAlign: 'top'
          }}
        >
          <PlanCard
            // heading={plan.heading}
            {...plan}
            planName={`${headKey}.plan${i + 1}`} // planName will be the key to fetch price id
            // price={plan.price}
            // cycle={plan.cycle}
            // description={plan.description}
            // lines={plan.lines}
            onClick={onClick}
            minHeight={minHeight}
          />
        </div>
      ))}
    </div>
  )
}

export default PlanSuite
