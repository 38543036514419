import React, { useEffect } from 'react'
import './PaymentError.css'
import { ReactComponent as AlertCircle } from '../../assets/images/alert-circle.svg'
import { H3 } from '../elements/CommonElements'
import { useNavigate } from 'react-router-dom'
import SecondaryLoader from '../misc/secondaryLoader/SecondaryLoader'
import { type PaymentProps } from './PaymentCompleted'

const PaymentError = ({ mode }: PaymentProps): JSX.Element => {
  const isSetup = mode === 'setup'
  const navigate = useNavigate()

  // Redirect user after 5 seconds
  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate('/')
    }, 5000)

    return () => clearTimeout(redirectTimeout)
  }, [navigate])
  return (
    <div style={{ textAlign: 'center' }}>
      <SecondaryLoader className="loader-container">
        <AlertCircle color={'red'} width={'400px'} height={'400px'} />
      </SecondaryLoader>

      <H3>{isSetup ? 'Payment update failed' : 'Payment failed'}</H3>
      <span>
        {`Oops! Payment ${
          isSetup ? 'updation unsuccessful' : 'unsuccessful'
        }. We apologize for the inconvenience. Please wait as we redirect you to the homepage, or you can click the button below to visit the homepage.`}
      </span>
      <button className="dashboard-button" onClick={() => navigate('/')}>
        <span>Go to Home</span>
      </button>
    </div>
  )
}

export default PaymentError
