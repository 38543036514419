import { useEffect } from 'react'
import { CLIENT_SITE_NAME } from '../Config'

const usePageTitle = (pageTitle?: string): void => {
  useEffect(() => {
    document.title = pageTitle
      ? `${CLIENT_SITE_NAME} | ${pageTitle}`
      : CLIENT_SITE_NAME
    return () => {
      document.title = CLIENT_SITE_NAME
    }
  }, [pageTitle])
}

export default usePageTitle
