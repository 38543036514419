import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Input from '../../common/elements/Input'
import { type RegisterErrors } from '../register/Register'
import Snackbar, {
  type SnackbarBaseProps
} from '../../common/elements/Snackbar'
import { emptySnackbarData } from '../dashboard/Dashboard'
import { Validate } from '../../utils/Validation'
import { type LoginErrors } from './Login'
import { PrimaryButton } from '../../common/elements/CommonElements'
import { api } from '../../utils/AxiosConfig'
import { wait } from '../../utils/CommonFunctions'
import Loader from '../../common/misc/loader/Loader'

const ResetPassword = (): JSX.Element => {
  // const [email, ]
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const token = searchParams.get('token')
  const [errors, setErrors] = useState<RegisterErrors & LoginErrors>({})
  //   const { isUserLoggedIn, dispatch } = useAuth()
  const navigate = useNavigate()
  //   const location = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarData, setSnackbarData] =
    useState<SnackbarBaseProps>(emptySnackbarData)

  const [formData, setFormData] = useState<{
    password: string
    confirmPassword: string
  }>({
    password: '',
    confirmPassword: ''
  })

  const checkAndSet = (key: string, value: string): void => {
    setFormData({ ...formData, [key]: value })
    setErrors(Validate(errors, formData, key, value))
  }

  const validateCredentials = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await api.post('/api/auth/reset-password/verify', {
        email,
        token,
        password: formData.password
      })
    } catch (err: any) {
      if (err?.response?.status === 401) {
        setIsLoading(false)
        setSnackbarData({
          type: 'error',
          message: `${err?.response?.data as string} Redirecting...`
        })
        await wait(5000)
        return navigate('/')
      }
      console.error('Error occurred while validating credentials.', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while updating password.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const resetPassword = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await api.patch('/api/auth/reset-password', {
        email,
        token,
        password: formData.password
      })
      setSnackbarData({
        type: 'success',
        message: 'Password updated successfully. Redirecting...'
      })
      await wait(1500)
      navigate('/login')
    } catch (err) {
      console.error('Error occurred while updating password.', err)
      setSnackbarData({
        type: 'error',
        message: 'Error occurred while updating password.'
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    validateCredentials()
  }, [])

  return (
    <div
      style={{
        width: '500px',
        marginTop: '100px',
        marginLeft: 'calc(50% - 250px)'
      }}
    >
      {isLoading ? <Loader /> : null}
      <Snackbar
        {...snackbarData}
        clear={() => setSnackbarData(emptySnackbarData)}
      />
      <h3>Reset account password</h3>
      <br />
      <span>Enter a new password for {email}</span>
      <br />
      <br />
      <Input
        label="Password"
        type="password"
        style={{ margin: '40px 0px' }}
        onChange={(e) => {
          checkAndSet('password', e.target.value)
        }}
        error={
          typeof errors.passwordValue !== 'undefined'
            ? errors.passwordMessage
            : ''
        }
      />
      <Input
        label="Confirm password"
        type="password"
        style={{ margin: '40px 0px' }}
        onChange={(e) => {
          checkAndSet('confirmPassword', e.target.value)
        }}
        error={
          typeof errors.confirmPasswordValue !== 'undefined'
            ? errors.confirmPasswordMessage
            : ''
        }
      />
      <br />
      <br />
      <br />
      <PrimaryButton
        onClick={resetPassword}
        disabled={
          (errors?.isNotValid as boolean) ||
          !formData.password ||
          !formData.confirmPassword ||
          formData.password !== formData.confirmPassword
        }
      >
        Reset Password
      </PrimaryButton>
    </div>
  )
}

export default ResetPassword
