import './Loader.css'
import React from 'react'

// const Loader = (): JSX.Element => <div className="lds-ripple"><div></div><div></div></div>
const Loader = (): JSX.Element => (
//   <div className="loading-container">
//     <div className="loading-spinner">
//       {/* <div className="loading-dot"></div>
//       <div className="loading-dot"></div>
//       <div className="loading-dot"></div> */}
//     {/* <div className="loading-line"></div>
//     <div className="loading-line"></div>
//     <div className="loading-line"></div> */}
//     <div className="loading-route"></div>
//     <div className="loading-route"></div>
//     <div className="loading-route"></div>
//     <div className="loading-route"></div>
//     </div>
//   </div>
<div className="loader">
  {/* <div className="circle"></div>
  <div className="circle"></div>
  <div className="circle"></div>
  <div className="circle"></div> */}
  <div className="bar"></div>
  <div className="bar"></div>
  <div className="bar"></div>
  <div className="bar"></div>
</div>

)

export default Loader
